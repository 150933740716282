import React from 'react';
import { useSelector } from 'react-redux';
import { useField } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import ExtraStatus from 'types/ExtraStatus';
import { AbstractMedia } from 'types/AbstractMedia';
import { Extra as ExtraType } from 'types/ExtrasForm';

import { Card } from 'components/ui';
import Popup from 'components/ui/Popup';

import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { useGetSelfServiceByIdQuery } from 'modules/selfServices/service';

import { computeTax } from 'utils/priceUtils';
import useLabelTranslation from 'hooks/useLabelTranslation';

import DefaultExtra from 'assets/images/default-extra.jpg';

export interface ExtraFormProps {
  name: string;
}

const Extra: React.FC<ExtraFormProps> = ({ name }) => {
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const { getLabelTranslation } = useLabelTranslation();

  const selfServiceId = useSelector(getSelectedSelfServiceId);

  const { data } = useGetSelfServiceByIdQuery({ id: selfServiceId });
  const { valueAddedTax: vat, dealer, checklist } = data ?? {};
  const { currencyIsoCode } = dealer;

  const {
    onChange,
    value: extra,
  } = useField<ExtraType>(name, { subscription: { value: true } }).input;

  const extras = checklist?.items?.flatMap(({ advices }) => advices) ?? [];
  const isAlreadyAnswered = extras.some(({ adviceId, status }) => adviceId === extra.adviceId && status !== ExtraStatus.PENDING);

  const handleOnChange = React.useCallback((status: ExtraStatus) => onChange({ ...extra, status }), [extra, onChange]);

  const handleDisabledClick = React.useCallback(() => {
    if (isAlreadyAnswered) {
      setIsPopupOpen(true);
    }
  }, [isAlreadyAnswered]);

  const handleClosePopup = React.useCallback(() => {
    setIsPopupOpen(false);
  }, []);

  return (
    <>
      <Card
        {...extra}
        disabled={isAlreadyAnswered}
        onStatusChange={handleOnChange}
        onDisabledClick={handleDisabledClick}
        currency={currencyIsoCode}
        price={computeTax(extra.price, vat)}
        discountedPrice={computeTax(extra.discountedPrice, vat)}
        mediaPlaceholder={
          {
            id: '1',
            type: 'IMAGE',
            path: DefaultExtra,
          } as AbstractMedia
        }
        title={getLabelTranslation(extra.adviceName)}
        className="md:last:mb-5"
      />
      {isPopupOpen && (
        <Popup
          onClose={handleClosePopup}
          title={(
            <FormattedMessage
              id="extras.alreadyAnswered"
              defaultMessage="This answer can no longer be changed."
              tagName="h2"
            />
          )}
        />
      )}
    </>
  );
};

export default Extra;
