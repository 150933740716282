import React from 'react';
import debounce from 'debounce';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

import { login } from 'modules/auth/thunk';
import PageBaseProps from 'types/PageBase';
import { useDispatch, useSelector } from 'hooks';
import { setLoginData } from 'modules/auth/actions';
import { useGetContextQuery } from 'modules/dealers/service';
import {
  getConnectionType, getPrefixByDealerId,
} from 'modules/dealers/selectors';
import { ErrorTypeEnum, isApiErrorResponse } from 'types/Error';
import { getLoginData, hasErrorLogin, isLoadingLogin } from 'modules/auth/selectors';
import { ConnectionTypeLogin } from 'modules/dealers/types/ContextResponse';

import { Input, PageHeader } from 'components/ui';
import { Footer } from 'components/ui/PageFooter';

import { PhoneNumber, SelfServiceType } from 'modules/selfServices/types/SelfService';

import { LoginData } from 'modules/auth/types/InitialState';
import LoginTerms from './LoginTerms';
import PhoneNumberInput from '../ui/PhoneNumberInput';
import BreakDownInformation from './BreakDownInformation';

const PLATE_NUMBER_VALIDATION_PATTERN = /^[a-zA-Z0-9 !?@#$&\-.+=:*,/"'\u00C0-\u024F\u1E00-\u1EFF]{1,50}$/;

const validatePlateNumber = (value: string, isBreakdown: boolean, intl: IntlShape) => {
  if (!value) {
    return intl.formatMessage({ id: 'validation.required', defaultMessage: 'This field is mandatory' });
  }

  if (isBreakdown && !PLATE_NUMBER_VALIDATION_PATTERN.test(value)) {
    return intl.formatMessage({
      id: 'validation.plateNumber',
      defaultMessage: 'The license plate should only contain alphanumeric characters and symbols',
    });
  }

  return undefined;
};

const Login: React.FC<PageBaseProps> = ({ onNext, onPrev }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search, state: pageState } = useLocation();

  const loginData = useSelector(getLoginData);
  const {
    registration = '', phoneNumber, dealerId, brandId,
  } = loginData;

  const isLoading = useSelector(isLoadingLogin);
  const hasLoginError = useSelector(hasErrorLogin);
  const connectionType = useSelector(getConnectionType);
  const defaultPrefix = useSelector((state) => getPrefixByDealerId(state, dealerId));

  const { data: contextData } = useGetContextQuery();
  const { generalTermsConditionsLink, confidentialityPolicyLink } = contextData ?? {};

  const [hasPhoneError, setHasPhoneError] = React.useState(false);
  const [areTermsAccepted, setTermsAccepted] = React.useState(false);
  const [plateNumberError, setPlateNumberError] = React.useState<string>();

  const isBreakdown = pageState?.type === SelfServiceType.BREAKDOWN;
  const isLoginToken = connectionType === ConnectionTypeLogin.TOKEN;

  const displayPhoneNumber = isBreakdown && defaultPrefix;

  const disabled = (isLoginToken && !areTermsAccepted)
  || (registration.length < 1 || Boolean(plateNumberError))
  || (isBreakdown && (hasPhoneError || !phoneNumber));

  const loginLabel = React.useMemo(() => (isBreakdown ? ({
    id: 'login.askPlateBreakdown',
    defaultMessage: 'Please enter your phone number and the license plate of the damaged vehicle.',
  }) : ({
    id: 'login.askLicensePlate',
    defaultMessage: 'Please enter the license plate of your vehicle in order to access your appointment.',
  })), [isBreakdown]);

  const handleChangeLoginData = React.useCallback((data: Partial<LoginData>) => {
    dispatch(setLoginData({ ...loginData, ...data }));
  }, [dispatch, loginData]);

  const toggleAcceptTerms = React.useCallback(() => setTermsAccepted((checked) => !checked), []);

  const handleInputPlateChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    handleChangeLoginData({ registration: value });
    setPlateNumberError(validatePlateNumber(value, isBreakdown, intl));
  }, [intl, handleChangeLoginData, isBreakdown]);

  const handlePhoneNumberChange = React.useCallback(
    (newPhoneNumber : PhoneNumber) => handleChangeLoginData({ phoneNumber: newPhoneNumber }),
    [handleChangeLoginData],
  );

  const handleOnPrev = React.useCallback(() => {
    if (isBreakdown) {
      onPrev();
    } else {
      navigate(`/${search}`);
    }
  }, [isBreakdown, navigate, onPrev, search]);

  const handleNextClick = async () => {
    try {
      await dispatch(login({
        connectionType,
        type: pageState?.type,
        registration,
        ...(isBreakdown ? {
          brandId,
          dealerId,
          phoneNumber,
        } : {}),
      })).unwrap();
      if (connectionType === ConnectionTypeLogin.MFA) {
        onNext();
      }
    } catch (error) {
      // If error is MFA_CODE_TIMEOUT (cannot resend until timeout), we go to next page anyway: BMWVID-15237
      if (isApiErrorResponse(error) && error.data.errorType === ErrorTypeEnum.MFA_CODE_TIMEOUT) {
        onNext();
      }
    }
  };

  const handleKeyDown = debounce((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !disabled && !isLoading) {
      handleNextClick();
    }
  }, 200);

  return (
    <>
      <PageHeader hideSteps onPrev={handleOnPrev} />
      <div className="main-content">
        <FormattedMessage id="login.title" defaultMessage="Login" tagName="h1" />
        <div className="content !m-auto">
          <p className="text-center">
            <FormattedMessage {...loginLabel} />
          </p>
          {displayPhoneNumber && (
            <>
              <PhoneNumberInput
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                className="mt-10 pb-10 kiosk:mt-20 kiosk:pb-20 border-b-2"
                onError={setHasPhoneError}
              />
              <h3 className="mt-7 kiosk:mt-14 text-center">
                <FormattedMessage id="login.vehicleInformation" defaultMessage="Vehicle information" />
              </h3>
            </>
          )}
          <Input
            className="mt-4"
            value={registration}
            onKeyDown={handleKeyDown}
            onChange={handleInputPlateChange}
            label={intl.formatMessage({ id: 'login.licensePlate', defaultMessage: 'License plate' })}
            autoCorrect="off"
            spellCheck="false"
            error={plateNumberError || hasLoginError}
            data-testid="plateNumber-input"
            maxLength={50}
          />
          {isLoginToken && (
            <LoginTerms
              isChecked={areTermsAccepted}
              onAccept={toggleAcceptTerms}
              generalTermsConditionsLink={generalTermsConditionsLink}
              confidentialityPolicyLink={confidentialityPolicyLink}
            />
          )}

          {isBreakdown && (
            <BreakDownInformation onChange={handleChangeLoginData} dealerId={dealerId} brandId={brandId} />
          )}
        </div>

        <Footer
          onNext={handleNextClick}
          loading={isLoading}
          disabled={disabled}
          onPrev={handleOnPrev}
          shouldDisplayBackButton
        />
      </div>
    </>
  );
};

export default Login;
