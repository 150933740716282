import { parseISO, toDate } from 'date-fns';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
export const INVALID_DATE = new Date(8640000000000001);
type FormatDate = (date: Date | string | number) => Date;
// eslint-disable-next-line import/prefer-default-export
export const formatDate: FormatDate = (date = INVALID_DATE) => (
  typeof date === 'string'
    ? parseISO(date)
    : toDate(date)
);
