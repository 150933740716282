import React from 'react';

import PageBaseProps from 'types/PageBase';
import FormKeys from 'modules/form/types/FormKeys';
import { BreakdownInfo as BreakdownInfoForm } from 'types/BreakdownInfo';

import Content from './Content';
import ConnectedForm from '../ui/ConnectedForm';
import getDecorator from '../ui/ConnectedForm/decorator';

const decorator = getDecorator<BreakdownInfoForm>(FormKeys.BREAKDOWN_INFO);

const BreakdownInfo: React.FC<PageBaseProps> = ({ onNext, onPrev }) => (
  <ConnectedForm
    onPrev={onPrev}
    onSubmit={onNext}
    component={Content}
    keepDirtyOnReinitialize
    decorators={[decorator]}
    formKey={FormKeys.BREAKDOWN_INFO}
    subscription={{ pristine: true, initialValues: true, errors: true }}
  />
);

export default BreakdownInfo;
