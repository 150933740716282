import { jwtDecode } from 'jwt-decode';
import { push } from 'redux-first-history';

import api from 'service/index';
import { getUrlParam } from 'utils/urlUtils';
import { addNotification } from 'modules/notifications/actions';
import { NotificationType } from 'modules/notifications/types/Notification';
import { SELF_SERVICE, VEHICLE_CHECK, VEHICLE_CHECK_MODEL } from 'constants/url';
import { VehicleCheckModel, VehicleCheckType } from 'modules/vehicleCheck/types/VehicleCheckModel';
import { GetContextResponse, Token } from 'modules/vehicleCheck/types/GetContextResponse';
import { FormVehicleCheck, VehicleCheckResponse } from 'modules/vehicleCheck/types/FormVehicleCheck';

import { FleetbackError } from 'types/FleetbackError';
import { VehicleCheckGetLinkResponse } from './types/VehicleCheckGetLinkResponse';

const vehicleCheckApi = api.injectEndpoints({
  endpoints: (build) => ({
    getVCContext: build.query<GetContextResponse, void>({
      query: () => ({
        method: 'GET',
        url: `${VEHICLE_CHECK}/context`,
        params: { token: getUrlParam('token') },
      }),
      transformResponse: (response: GetContextResponse) => {
        const { isCourtesyVehicleCheckAnswered, isVehicleCheckAnswered, ...context } = response;
        const token = jwtDecode<Token>(response.token);

        return {
          ...context,
          [VehicleCheckType.PERSONAL]: {
            accepted: Boolean(token.vehicle_check_model_id),
            answered: isVehicleCheckAnswered ?? false,
          },
          [VehicleCheckType.COURTESY]: {
            accepted: Boolean(token.courtesy_vehicle_check_model_id),
            answered: isCourtesyVehicleCheckAnswered ?? false,
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            addNotification({
              title: {
                id: 'vehicleCheck.getModel.error.title',
                defaultMessage: 'An error occurred while starting the vehicle check.',
              },
              description: {
                id: 'defaultError.description',
                defaultMessage: 'Please try again later or contact us.',
              },
              type: NotificationType.ERROR,
            }),
          );
          dispatch(push('/error'));
        }
      },
    }),
    getModel: build.query<VehicleCheckModel, { selfServiceId: string, type: VehicleCheckType }>({
      query: ({ selfServiceId, type }) => {
        const suffix = type !== VehicleCheckType.PERSONAL ? `/${type.toLowerCase()}` : '';
        return ({
          method: 'GET',
          url: `${SELF_SERVICE}/${selfServiceId}${VEHICLE_CHECK_MODEL}${suffix}`,
        });
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          const { data: errorData } = error?.error ?? {};

          if (errorData && 'errorCode' in errorData && (errorData as FleetbackError).errorCode === 422) {
            dispatch(
              addNotification({
                title: {
                  id: 'vehicleCheck.getModel.error.SO_VEHICLE_CHECK_ALREADY_COMPLETED.title',
                  defaultMessage: 'Cannot access vehicle check ',
                },
                description: {
                  id: 'vehicleCheck.getModel.error.SO_VEHICLE_CHECK_ALREADY_COMPLETED.description',
                  defaultMessage: 'The vehicle check has already been completed.',
                },
                type: NotificationType.ERROR,
              }),
            );
          } else {
            dispatch(
              addNotification({
                title: {
                  id: 'vehicleCheck.getModel.error.title',
                  defaultMessage: 'An error occurred while starting the vehicle check.',
                },
                description: {
                  id: 'defaultError.description',
                  defaultMessage: 'Please try again later or contact us.',
                },
                type: NotificationType.ERROR,
              }),
            );
          }
          dispatch(push('/error'));
        }
      },
    }),
    getVCLink: build.query<VehicleCheckGetLinkResponse, { selfServiceId: string }>({
      query: ({ selfServiceId }) => ({
        method: 'GET',
        url: `${SELF_SERVICE}/${selfServiceId}${VEHICLE_CHECK}/url`,
      }),
    }),
    sendVCLink: build.mutation<undefined, { selfServiceId: string }>({
      query: ({ selfServiceId }) => ({
        method: 'POST',
        url: `${SELF_SERVICE}/${selfServiceId}${VEHICLE_CHECK}/send`,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            addNotification({
              title: {
                id: 'home.checkIn',
                defaultMessage: 'Check-in',
              },
              description: {
                id: 'defaultError.description',
                defaultMessage: 'Please try again later or contact us.',
              },
              type: NotificationType.ERROR,
            }),
          );
        }
      },
    }),
    createVehicleCheck: build.mutation<VehicleCheckModel, { model: VehicleCheckResponse | FormVehicleCheck, type: VehicleCheckType }>({
      query: ({ model: { selfServiceId, ...body }, type }) => {
        const suffix = type !== VehicleCheckType.PERSONAL ? `/${type.toLowerCase()}` : '';
        return ({
          method: 'POST',
          url: `${SELF_SERVICE}/${selfServiceId}${VEHICLE_CHECK}${suffix}`,
          body,
        });
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(
            addNotification({
              title: {
                id: 'vehicleCheck.create.error.title',
                defaultMessage: 'An error occurred while sending the vehicle check.',
              },
              description: {
                id: 'defaultError.description',
                defaultMessage: 'Please try again later or contact us.',
              },
              type: NotificationType.ERROR,
            }),
          );
        }
      },
    }),
  }),
});

export const {
  useGetVCLinkQuery, useSendVCLinkMutation, useCreateVehicleCheckMutation, useGetModelQuery, useGetVCContextQuery,
} = vehicleCheckApi;

export default vehicleCheckApi;
