import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classNames, { Argument } from 'classnames';

import { DoorsStatus } from 'modules/kiosk/types/KeysSafe';
import {
  hasError as hasErrorSelector,
  isIdle as isIdleSelector,
  isClosing as isClosingSelector,
  isMoving as isMovingSelector,
} from 'modules/kiosk/selectors';

interface KeysManagementModalProps {
  loaderClassName?: Argument;
}
const KeysManagementModal: React.FC<React.PropsWithChildren<KeysManagementModalProps>> = ({
  loaderClassName, children,
}) => {
  const isIdle = useSelector(isIdleSelector);
  const isMoving = useSelector(isMovingSelector);
  const hasError = useSelector(hasErrorSelector);
  const isClosing = useSelector(isClosingSelector);

  const displayModal = !isIdle || hasError;

  return (
    displayModal && (
      <div
        className={classNames(
          'flex-grow bg-secondary z-0',
          'rounded-t-6xl flex flex-col items-center pt-32 px-32',
          'key-modal-transition--slide-up key-modal-transition--slide-up-enter',
        )}
        data-testid="key-safe-modal"
      >
        {isMoving && (
          <>
            <div className="text-6xl" id="keys-modal-loading">
              <FormattedMessage
                id={`key.${isClosing ? DoorsStatus.CLOSING : DoorsStatus.OPENING}`}
                defaultMessage={isClosing ? 'Closing' : 'Opening'}
                tagName="h1"
              />
            </div>
            <div className="mt-52">
              <span className={classNames('loader', loaderClassName)} />
            </div>
          </>
        )}
        {!isMoving && children}
      </div>
    )
  );
};

export default KeysManagementModal;
