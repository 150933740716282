import React from 'react';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, useIntl } from 'react-intl';

import PageBaseProps from 'types/PageBase';
import ExtraStatus from 'types/ExtraStatus';
import FormKeys from 'modules/form/types/FormKeys';
import type { FormValidatorWithIntl } from 'types/Form';
import type { ExtrasForm as ExtrasFormType } from 'types/ExtrasForm';

import Popup from 'components/ui/Popup';

import ConnectedForm from '../ui/ConnectedForm';
import ExtrasFormRender from './ExtrasFormRender';
import MandatoryWarning from '../MandatoryWarning';
import getDecorator from '../ui/ConnectedForm/decorator';

const decorator = getDecorator<ExtrasFormType>(FormKeys.EXTRAS);

export const validateForm: FormValidatorWithIntl<ExtrasFormType> = (intl) => (
  { extras = [] },
) => extras.reduce((acc, extra, index) => ({
  ...acc,
  ...(extra.mandatory && extra.status === ExtraStatus.PENDING && ({
    [`extras[${index}].error`]: intl.formatMessage({
      id: 'validation.required',
      defaultMessage: 'This field is mandatory',
    }),
  })),
}), {});

const ExtrasForm: React.FC<PageBaseProps> = ({ onNext, onPrev }) => {
  const intl = useIntl();

  return (
    <ConnectedForm
      onPrev={onPrev}
      onSubmit={onNext}
      decorators={[decorator]}
      formKey={FormKeys.EXTRAS}
      component={ExtrasFormRender}
      validate={validateForm(intl)}
      mutators={{ ...arrayMutators }}
      subscription={{ hasValidationErrors: true }}
      errorComponent={(
        <Popup title={<FormattedMessage id="extras.mandatoryModal.title" defaultMessage="You must first answer all mandatory extras" tagName="h2" />}>
          <MandatoryWarning />
        </Popup>
      )}
    />
  );
};

export default ExtrasForm;
