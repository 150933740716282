import React from 'react';
import * as Sentry from '@sentry/react';
import { install } from 'resize-observer';
import { createRoot } from 'react-dom/client';
import 'core-js/features/array/at';
import 'core-js/features/promise/all-settled';

import {
  matchRoutes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
} from 'react-router-dom';

import App from './App';
import './styles/index.css';
import './polyfill/google-translate';
import packageJson from '../package.json';
import { getUrlParam } from './utils/urlUtils';

const { name: appName, version: appVersion } = packageJson;

// Create application container element
const container = document.createElement('div');
container.id = 'root';
document.body.appendChild(container);

if (process.env.IS_SENTRY_ENABLED === 'true') {
  Sentry.setTag('SO_public_token', getUrlParam('token'));

  Sentry.init({
    dsn: 'https://ba8db2ccbf37470582355c3550c2c496@o326541.ingest.sentry.io/4504045178388480',
    release: `${appName}@${appVersion}`,
    normalizeDepth: 3,
    tracesSampleRate: 0.1,
    environment: 'production',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
}

// Install ResizeObserver polyfill for old version of Safari
if (!window.ResizeObserver) install();

// Run the application
const root = createRoot(container);
root.render(<App />);
