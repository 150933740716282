import React from 'react';
import { FormattedMessage } from 'react-intl';

import Popup from 'components/ui/Popup';
import { ErrorComponentProps } from 'components/ui/ConnectedForm';

const MobilityError: React.FC<ErrorComponentProps> = ({ onClose, formState }) => {
  const errorFields = Object.keys(formState?.errors ?? {});

  let error = { id: 'mobilityDocument.required.mobilityEnabled', defaultMessage: 'You must first answer the question' };
  if (errorFields.includes('documents')) {
    error = { id: 'mobilityDocument.required.documents', defaultMessage: 'Please take a picture for each document' };
  }

  return <Popup onClose={onClose} title={<FormattedMessage {...error} tagName="h2" />} />;
};

export default MobilityError;
