import React from 'react';

import { RootState } from 'App/Store';
import { useSelector } from 'hooks';

import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { getFormattedFormData, isMobilityAccepted } from 'modules/form/selectors';

import { useCreatePreviewPDFQuery, useGetSelfServiceByIdQuery } from 'modules/selfServices/service';

import { isCheckoutSelfService } from 'modules/selfServices/selectors';
import { SignatureStatus } from 'modules/selfServices/types/SelfService';

import { Document } from '../types';

const DOCUMENT_KEYS = {
  SUMMARY: 'SUMMARY',
  WORK_ORDER: 'WORK_ORDER',
  MOBILITY: 'MOBILITY',
  DELIVERY_DOCUMENT: 'DELIVERY_DOCUMENT',
};

const useDocuments = () => {
  const isCheckOut = useSelector(isCheckoutSelfService);
  const selfServiceId = useSelector(getSelectedSelfServiceId);
  const hasAcceptedMobility = useSelector(isMobilityAccepted);

  const { data: selfService } = useGetSelfServiceByIdQuery({ id: selfServiceId });

  const formattedData = useSelector((state: RootState) => getFormattedFormData(state, selfService));

  const {
    data: summaryPDF,
    isLoading,
    isSuccess,
    isError,
    refetch,
  } = useCreatePreviewPDFQuery(
    { id: selfServiceId, body: formattedData },
    { skip: isCheckOut, refetchOnMountOrArgChange: true },
  );

  const CHECK_IN_DOCUMENTS = React.useMemo(() => ({
    [DOCUMENT_KEYS.SUMMARY]: {
      id: DOCUMENT_KEYS.SUMMARY,
      path: summaryPDF,
      title: { id: 'signature.documents.SUMMARY', defaultMessage: 'Check-in summary' },
      read: false,
      status: SignatureStatus.UNSIGNED,
    },
    ...(selfService?.workOrder?.media ? {
      [DOCUMENT_KEYS.WORK_ORDER]: {
        id: DOCUMENT_KEYS.WORK_ORDER,
        path: selfService?.workOrder?.media?.path,
        title: { id: 'signature.documents.WORK_ORDER', defaultMessage: 'Work order' },
        read: selfService?.workOrder?.status === SignatureStatus.SIGNED,
        status: selfService?.workOrder?.status,
      },
    } : {}),
    ...(hasAcceptedMobility && selfService.mobility?.contract?.media ? {
      [DOCUMENT_KEYS.MOBILITY]: {
        id: DOCUMENT_KEYS.MOBILITY,
        path: selfService?.mobility?.contract?.media?.path,
        title: { id: 'mobilityContract.title', defaultMessage: 'Mobility contract' },
        read: selfService?.mobility?.contract?.status === SignatureStatus.SIGNED,
        status: selfService?.mobility?.contract?.status,
      },
    } : {}),
  }), [selfService, summaryPDF, hasAcceptedMobility]);

  const CHECK_OUT_DOCUMENTS = React.useMemo(() => ({
    ...(selfService?.mobility?.deliveryDocument?.media ? {
      [DOCUMENT_KEYS.DELIVERY_DOCUMENT]: {
        id: DOCUMENT_KEYS.DELIVERY_DOCUMENT,
        path: selfService?.mobility?.deliveryDocument?.media?.path,
        title: { id: 'signature.documents.DELIVERY_DOCUMENT', defaultMessage: 'Vehicle delivery document' },
        read: selfService?.mobility?.deliveryDocument?.status === SignatureStatus.SIGNED,
        status: selfService?.mobility?.deliveryDocument?.status,
      },
    } : {}),
  }), [selfService]);

  const [documents, setDocuments] = React.useState<Document[]>(
    isCheckOut ? Object.values(CHECK_OUT_DOCUMENTS) : Object.values(CHECK_IN_DOCUMENTS)
  );

  const [selectedKey, setSelectedKey] = React.useState<string>(documents[0]?.id);

  React.useEffect(() => {
    if (summaryPDF) {
      setDocuments((prevDocuments) => prevDocuments.map((document) => (document.id === DOCUMENT_KEYS.SUMMARY ? { ...document, path: summaryPDF } : document)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryPDF]);

  const currentIndex = React.useMemo(
    () => documents.findIndex(({ id }) => id === selectedKey),
    [documents, selectedKey],
  );

  const isPrevDisabled = currentIndex === 0;
  const isNextDisabled = currentIndex === documents.length - 1;

  const handlePrev = React.useCallback(() => {
    setSelectedKey(documents[Math.max(0, currentIndex - 1)].id);
  }, [documents, currentIndex]);

  const handleNext = React.useCallback(() => {
    setSelectedKey(documents[Math.min(documents.length - 1, currentIndex + 1)]?.id);
  }, [documents, currentIndex]);

  const handleSelect = React.useCallback((id: string) => {
    setSelectedKey(id);
  }, []);

  const handleRead = React.useCallback(
    (id: string) => {
      setDocuments((prevDocuments) => prevDocuments.map((document) => (document.id === id ? { ...document, read: true } : document)));
    },
    [setDocuments],
  );

  return React.useMemo(
    () => ({
      document: documents[currentIndex],
      documents,
      isError,
      isLoading,
      isSuccess,
      isPrevDisabled,
      isNextDisabled,
      onPrev: handlePrev,
      onNext: handleNext,
      onRead: handleRead,
      onRetry: refetch,
      onSelectDocument: handleSelect,
    }),
    [
      currentIndex,
      documents,
      handleSelect,
      handleRead,
      handlePrev,
      handleNext,
      refetch,
      isPrevDisabled,
      isNextDisabled,
      isError,
      isLoading,
      isSuccess,
    ],
  );
};

export default useDocuments;
