import React, { AnchorHTMLAttributes, ReactNode } from 'react';
import classNames, { Argument } from 'classnames';

import Language from 'types/LanguageEnum';

import { getUrlParam } from './urlUtils';

const LANGUAGE_OVERRIDES: Partial<Record<Language, Array<string>>> = {
  [Language.NO]: ['nb', 'nn'],
};

export const getDefaultLanguage = () => {
  const languageParam = getUrlParam('language');

  // Language can be RFC-5646 compliant (en-GB, fr-BE, ...)
  const navigatorLanguage = navigator.languages[0]?.split('-')?.shift();

  const userLanguage = languageParam ?? navigatorLanguage;
  const override = Object.entries(LANGUAGE_OVERRIDES).find(([_, overridenLangs]) => overridenLangs.includes(userLanguage))?.[0];

  const formattedLanguage = (override ?? userLanguage) as Language;
  return Object.values(Language).find((language) => language === formattedLanguage) ?? Language.EN;
};

export const b = (text: ReactNode, className?: Argument) => (<span className={classNames('font-bold', className)}>{text}</span>);
export const i = (text: ReactNode) => (<span className="italic">{text}</span>);
export const br = <br />;

export const getHyperlink = (props: AnchorHTMLAttributes<HTMLAnchorElement> & { 'data-testid'?: string }) => (chunks: React.ReactNode) => (
  <a {...props}>
    {chunks}
  </a>
);
