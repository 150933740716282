import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { ApiErrorResponse } from 'types/Error';
import { ContextResponse } from 'modules/dealers/types/ContextResponse';
import type {
  LoginResponse,
  MFAAttemptsDetails,
  SendMFACodeResponse,
} from 'modules/auth/types/LoginResponse';
import type { PhoneNumber } from 'modules/selfServices/types/SelfService';

export const LOCAL_STORAGE_KEY = 'cookies.setting';

export enum CookiesSettings {
  ESSENTIAL = 'essential',
  ANALYTICS = 'analytics',
}

export type MFA = ContextResponse & MFAAttemptsDetails & SendMFACodeResponse & {
  email: string;
  phoneNumber: string;
  connectionId: string;
  vehicleCheckEnabled: boolean;
  mobility : {
    courtesyVehicleCheckEnabled: boolean;
    notPrepared: boolean;
  }
};

export interface LoginData {
  registration? : string,
  phoneNumber?: PhoneNumber,
  dealerId?: string,
  brandId?: string,
}

export type LoginRequestState = {
  isLoading: boolean;
  data: LoginData;
  error: ApiErrorResponse | FetchBaseQueryError | SerializedError;
};

export enum ScheduleTypeEnum {
  LOGOUT = 'LOGOUT',
  DOORS = 'DOORS',
}

export type Schedule = Partial<Record<ScheduleTypeEnum, string>>;

export type InitialState = LoginResponse & {
  schedule?: Schedule;
  login?: LoginRequestState;
  selfServiceId?: string;
  publicToken?: string;
  dealerId?: string;
  mfa?: MFA;
  qrCodeToken?: string;
  adminToken?: string;
  adminCodeAttempt?: number;
  vehicleCheckPublicToken?: string;
  kioskRootAccess?: boolean;
  cookies?: CookiesSettings[];
};
