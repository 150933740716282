import { createReducer, EntityState } from '@reduxjs/toolkit';

import kioskApi from 'modules/kiosk/service';
import selfServicesApi from 'modules/selfServices/service';
import { slotsAdapter } from 'modules/kiosk/reducers/adapters';
import { SelfServiceOrigin } from 'modules/selfServices/types/SelfService';

import type { Slot } from 'modules/kiosk/types/Slot';

export type InitialState = EntityState<Slot, string>;

const slotReducer = createReducer<InitialState>(slotsAdapter.getInitialState(), (builder) => {
  builder
    .addMatcher(
      kioskApi.endpoints.getKioskData.matchFulfilled,
      (state, { payload }) => slotsAdapter.setAll(state, payload.slots),
    )
    .addMatcher(selfServicesApi.endpoints.getSelfServiceById.matchFulfilled, (state, { payload }) => {
      const { id, origin, slot } = payload;
      if (origin === SelfServiceOrigin.KIOSK && slot) {
        const { kiosk, ...newSlot } = slot;
        return slotsAdapter.setOne(state, {
          ...newSlot,
          used: true,
          selfServiceId: id,
        });
      }
      return state;
    })
    .addMatcher(kioskApi.endpoints.openSlot.matchFulfilled, (state, { payload }) => {
      const { kiosk, ...slot } = payload;
      return slotsAdapter.upsertOne(state, { ...slot, used: true });
    })
    .addMatcher(kioskApi.endpoints.openSlotAsAdmin.matchFulfilled, (state, { payload }) => {
      const { slotId, selfServiceId } = payload;
      return slotsAdapter.updateOne(state, { id: slotId, changes: { selfServiceId } });
    });
});

export default slotReducer;
