import React from 'react';
import { useSelector } from 'react-redux';

import { getKioskId } from 'modules/kiosk/selectors';
import { useGetKioskDataQuery } from 'modules/kiosk/service';

interface UseUsedSlotsProps {
  refetch?: boolean;
}

const useUsedSlots = ({ refetch }: UseUsedSlotsProps = {}) => {
  const kioskId = useSelector(getKioskId);

  const { data, isFetching, isSuccess } = useGetKioskDataQuery({ kioskId }, { refetchOnMountOrArgChange: refetch });
  const { slots = [] } = data ?? {};

  const usedSlots = slots.filter(({ used }) => used);

  return React.useMemo(() => ({
    isSuccess,
    isFetching,
    slots: slots.length,
    usedSlots: usedSlots.length,
    isFull: slots.length === usedSlots.length,
  }), [slots.length, usedSlots.length, isFetching, isSuccess]);
};

export default useUsedSlots;
