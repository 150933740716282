import { InitialState } from 'modules/healthCheck/reducer';
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { isKioskOrigin } from 'modules/dealers/selectors';
import type { RootState } from '../../App/Store';

const getState = (state: RootState): InitialState => state.healthCheck;

export const isOffline = createDraftSafeSelector(getState, ({ online }) => !online);

export const isKioskOffline = createDraftSafeSelector(isKioskOrigin, isOffline, (isKiosk, offline) => isKiosk && offline);
