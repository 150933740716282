import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { b } from 'utils/i18nUtils';

import Warning from 'assets/icons/warning.svg';

import { Button, Confirm } from '../../ui';
import { ButtonType } from '../../ui/Button';

interface EmergencyAlertProps {
  isActivated: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

const EmergencyAlert: React.FC<EmergencyAlertProps> = ({ isActivated, onChange, disabled }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const handleCloseModal = () => setIsModalVisible(false);

  const handleConfirm = () => {
    onChange(true);
    handleCloseModal();
  };

  const handleChangeMode = () => {
    if (!isActivated) {
      setIsModalVisible(true);
    } else {
      onChange(false);
    }
  };

  return (
    <>
      <div
        className={classNames(
          'flex justify-between items-center w-full bg-input-bg border border-tertiary-lighten-60 rounded-full p-4',
          { '!bg-error-bg !border-border-error-bg': isActivated },
        )}
      >
        <div className="flex items-center">
          <div className={classNames('relative bg-tertiary rounded-full w-20 h-20', { '!bg-error': isActivated })}>
            <Warning className="absolute inset-4 fill-white" />
          </div>
          <div className="flex flex-col justify-between ml-5 h-20">
            <h1 className={classNames('!text-4xl uppercase text-gray-90', { '!text-error': isActivated })}>
              {isActivated
                ? <FormattedMessage id="warning" defaultMessage="Warning" />
                : <FormattedMessage id="admin.emergencyAlert.title" defaultMessage="Emergency access" />}
            </h1>
            <p className={classNames('!text-xl text-gray-90 font-medium', { '!text-error': isActivated })}>
              <FormattedMessage
                id="admin.emergencyAlert.subtitle"
                defaultMessage="Only use this mode in an emergency"
              />
            </p>
          </div>
        </div>
        <Button
          disabled={disabled}
          onClick={handleChangeMode}
          testId="changeEmergencyMode"
          type={isActivated ? ButtonType.ERROR : ButtonType.TERTIARY}
          className="!text-xl !leading-10 font-semibold w-56 !p-6 uppercase transition-none"
        >
          {isActivated
            ? <FormattedMessage id="exit" defaultMessage="Exit" />
            : <FormattedMessage id="activate" defaultMessage="Activate" />}
        </Button>
      </div>
      <Confirm
        hideCloseButton
        open={isModalVisible}
        onConfirm={handleConfirm}
        onCancel={handleCloseModal}
        confirmProps={{ type: ButtonType.ERROR }}
        cancelProps={{ type: ButtonType.SECONDARY }}
        title={(
          <h2 className="flex justify-center items-center uppercase">
            <FormattedMessage id="admin.emergencyAlert.title" defaultMessage="Emergency access" />
          </h2>
        )}
        question={(
          <p className="text-center mb-10">
            <FormattedMessage
              id="admin.emergencyAlert.confirmModal.description"
              defaultMessage="Confirm that you want to <b>activate the emergency mode.</b>"
              values={{ b }}
            />
          </p>
        )}
      />
    </>
  );
};

export default EmergencyAlert;
