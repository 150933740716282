/* eslint-disable no-await-in-loop */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { mediasApi } from './service';
import { SendMetricsRequest } from './types/SendMetricsRequest';

const sendMetricsAsync = createAsyncThunk(
  'medias/sendMetrics',
  async (params: SendMetricsRequest, { dispatch }) => {
    const maxRetries = 5;

    for (let attempt = 0; attempt < maxRetries; attempt++) {
      try {
        return await dispatch(mediasApi.endpoints.sendMetrics.initiate(params)).unwrap();
      } catch (error) {
        // do nothing
      }

      // Wait 2 seconds before next retry
      await new Promise((resolve) => { setTimeout(resolve, 2000); });
    }
  },
);

export default sendMetricsAsync;
