import React from 'react';
import { Argument } from 'classnames';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'hooks';
import { isPdf } from 'utils/downloadUtils';
import { AbstractMedia, MediaType } from 'types/AbstractMedia';
import { ButtonType } from 'components/ui/Button';
import { b, getHyperlink, i } from 'utils/i18nUtils';
import { isPublicDevice } from 'modules/dealers/selectors';

import Media from 'types/Media';
import {
  Carousel, Modal, Switch, QRCodeViewer, Button,
} from '../ui';

interface LoginMedia extends Omit<Media, 'type'> {
  type: MediaType | 'LINK';
}

enum TermsType {
  GENERAL_TERMS = 'GENERAL_TERMS',
  CONFIDENTIALITY_POLICY = 'CONFIDENTIALITY_POLICY',
}

interface LoginTermsProps {
  isChecked: boolean;
  onAccept: () => void;
  generalTermsConditionsLink: string;
  confidentialityPolicyLink?: string;
  className?: Argument;
}

const LoginTerms: React.FC<LoginTermsProps> = ({
  isChecked,
  onAccept,
  generalTermsConditionsLink,
  confidentialityPolicyLink,
  className,
}) => {
  const ref = React.useRef<HTMLButtonElement>();
  const isPublic = useSelector(isPublicDevice);
  const [media, setMedia] = React.useState<Partial<LoginMedia>>();

  const handleLinkClick = React.useCallback(
    (type: string) => (event: React.MouseEvent) => {
      event.stopPropagation();
      if (isPublic) {
        event.preventDefault();
        const path = (event.target as HTMLAnchorElement).href;
        setMedia({ path, id: type, type: isPdf(path) ? MediaType.DOCUMENT : 'LINK' });
      }
    },
    [isPublic],
  );

  const handleClose = () => {
    setMedia(undefined);
  };

  return (
    <>
      <Switch
        checked={isChecked}
        onChange={onAccept}
        className={className}
        data-testid="terms-switch"
      >
        <FormattedMessage
          id="login.terms"
          // eslint-disable-next-line max-len
          defaultMessage="I accept the <terms>general terms and conditions</terms>{hasConfidentialityPolicy, select, true { and <policy>confidentiality policy</policy>} other {} }."
          values={{
            terms: getHyperlink({
              target: '_blank',
              className: 'font-bold',
              rel: 'noopener noreferrer',
              onClick: handleLinkClick(TermsType.GENERAL_TERMS),
              'data-testid': 'general-terms',
              href: generalTermsConditionsLink,
            }),
            policy: getHyperlink({
              target: '_blank',
              className: 'font-bold',
              rel: 'noopener noreferrer',
              onClick: handleLinkClick(TermsType.CONFIDENTIALITY_POLICY),
              'data-testid': 'confidentiality-policy',
              href: confidentialityPolicyLink,
            }),
            hasConfidentialityPolicy: Boolean(confidentialityPolicyLink),
          }}
        />
      </Switch>
      {media?.type === MediaType.DOCUMENT && (
        <Carousel
          medias={[media as AbstractMedia]}
          onClose={handleClose}
          fullscreen
        />
      )}
      {media?.type === 'LINK' && (
        <Modal
          open
          focusRef={ref}
          className="w-full max-w-md kiosk:max-w-4xl"
          onClose={handleClose}
          title={(
            <FormattedMessage
              id={`login.termsModal.title.${media.id}`}
              defaultMessage={media.id}
              tagName="h2"
            />
          )}
        >
          <div className="flex flex-col w-full h-full text-center items-center">
            <FormattedMessage
              id="login.termsModal.description"
              defaultMessage="You can access <b>{terms}</b> using the QR code or the URL below"
              values={{ b, terms: <FormattedMessage id={`login.termsModal.termsList.${media.id}`} defaultMessage={media.id} /> }}
              tagName="p"
            />
            <QRCodeViewer
              content={media.path}
              className="!w-1/2 !h-1/2 mt-4"
            />
            <p className="w-full break-words mt-4">
              <FormattedMessage
                id="login.termsModal"
                defaultMessage="<b>URL:</b> <i>{url}</i>"
                values={{ url: media.path, b, i }}
              />
            </p>
            <Button
              ref={ref}
              className="w-full mt-4"
              type={ButtonType.SECONDARY}
              onClick={handleClose}
            >
              <FormattedMessage id="close" defaultMessage="Close" />
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default LoginTerms;
