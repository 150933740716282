import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Field, FormRenderProps, useField,
} from 'react-final-form';

import PageBaseProps from 'types/PageBase';

import { ButtonType } from 'components/ui/Button';
import { Button, Modal, RadioBullet } from 'components/ui';

import PageFooter from 'components/ui/PageFooter';
import ContactForm from './ContactForm';

type CustomerInfoProps = {
  isLoading: boolean
} & PageBaseProps & FormRenderProps;

const Content: React.FC<CustomerInfoProps> = ({
  handleSubmit, pristine, onNext, onPrev, isLoading, errors,
}) => {
  const ref = React.useRef<HTMLButtonElement>();
  const [displayPersistModal, setDisplayPersistModal] = React.useState(false);
  const { value: permanent } = useField('permanent', { subscription: { value: true } }).input;
  const { value: phoneNumber } = useField('phoneNumber.local', { subscription: { value: true } }).input;

  const [hasError, setHasError] = React.useState(!phoneNumber);

  const handleCancel = React.useCallback(() => setDisplayPersistModal(false), []);
  const handleNext = React.useCallback(() => (pristine ? onNext() : setDisplayPersistModal(true)), [onNext, pristine]);

  const handleError = React.useCallback((isError: boolean) => {
    setHasError(isError);
  }, []);

  const hasErrors = hasError || Object.keys(errors).length > 0;

  return (
    <>
      <div className="main-content">
        <div className="flex flex-col items-center">
          <FormattedMessage
            id="page.contactInfo.title"
            defaultMessage="Contact information"
            tagName="h1"
          />
          <div className="description mt-5 text-center">
            <FormattedMessage
              id="page.contactInfo.subtitle"
              defaultMessage="Check your contact details and modify them if necessary."
              tagName="p"
            />
          </div>
        </div>
        <div className="content">
          <ContactForm onError={handleError} />
        </div>
        <PageFooter onNext={handleNext} onPrev={onPrev} disabled={hasErrors} />
      </div>
      <Modal
        focusRef={ref}
        open={displayPersistModal}
        className="w-full max-w-md kiosk:max-w-4xl"
        onClose={setDisplayPersistModal}
        title={(
          <FormattedMessage
            id="page.contactInfo.persistModal.title"
            defaultMessage="How would you like to save your information?"
            tagName="h2"
          />
        )}
      >
        <div
          className="flex flex-col items-center mt-5 kiosk:mt-10"
          data-testid="persistModal"
        >

          <Field name="permanent" type="radio" value="true" defaultValue="true">
            {({ input }) => (
              <RadioBullet
                checked={input.checked}
                title={(
                  <FormattedMessage
                    id="page.contactInfo.persistModal.content.permanently.title"
                    defaultMessage="Permanently"
                  />
                )}
                description={(
                  <FormattedMessage
                    id="page.contactInfo.persistModal.content.permanently.description"
                    defaultMessage="Make it my default contact information"
                  />
                )}
                {...input}
              />
            )}
          </Field>
          <Field name="permanent" type="radio" value="false">
            {({ input }) => (
              <RadioBullet
                checked={input.checked}
                title={(
                  <FormattedMessage
                    id="page.contactInfo.persistModal.content.temporary.title"
                    defaultMessage="Temporary"
                  />
                )}
                description={(
                  <FormattedMessage
                    id="page.contactInfo.persistModal.content.temporary.description"
                    defaultMessage="Save it for this appointment only"
                  />
                )}
                {...input}
              />
            )}
          </Field>

          <div className="flex flex-col kiosk:flex-row w-full">
            <Button
              ref={ref}
              analytics={{ category: 'Update', action: 'Update customer info', label: `${permanent ? 'Permanently' : 'Temporarily'}` }}
              loading={isLoading}
              onClick={handleSubmit}
              className="w-full mt-5 mb-3 kiosk:mr-5 kiosk:my-0"
              disabled={isLoading}
              testId="persistCustomerInfo"
            >
              <FormattedMessage id="confirm.title" defaultMessage="Confirm" />
            </Button>
            <Button onClick={handleCancel} type={ButtonType.SECONDARY} className="w-full kiosk:ml-5" testId="cancel-button">
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Content;
