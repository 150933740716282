import { createSlice } from '@reduxjs/toolkit';

import InitialState from 'modules/basket/types/InitialState';

const basketSlice = createSlice({
  name: 'basket',
  initialState: {
    modalOpen: false,
  } as InitialState,
  reducers: {
    openBasketModal: (state) => ({ ...state, modalOpen: true }),
    closeBasketModal: (state) => ({ ...state, modalOpen: false }),
  },
});

export default basketSlice;
