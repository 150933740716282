import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch } from 'hooks';
import actions from 'modules/translations/actions';
import { getLegalNotice } from 'modules/selfServices/selectors';
import Button, { ButtonSize, ButtonType } from 'components/ui/Button';
import { getAllLanguages, getSelectedLanguage, getTranslationsSelectors } from 'modules/translations/selectors';
import {
  getPaymentMethods,
  isPublicDevice,
  shouldDisplayPhoneNumbers as shouldDisplayPhoneNumbersSelector,
} from 'modules/dealers/selectors';

import LanguageEnum from 'types/LanguageEnum';

import { b } from 'utils/i18nUtils';
import { getHyperlink } from 'utils/linkUtils';
import { createDictionary } from 'utils/objectUtils';

import Modal from '../Modal';
import SelectInput from '../SelectInput';
import DealersPhone from '../DealersPhone';
import QRCodeViewer from '../QRCodeViewer';

const DSA_URL = 'https://www.fleetback.com/digital-services-act/';

enum LinkTypes {
  DSA = 'DSA',
  LEGAL_NOTICE = 'LEGAL_NOTICE',
}

const defaultPublicDescription = createDictionary({
  [LinkTypes.DSA]: 'Content not adapted? You can <b>report the problem</b> by scanning this QR code',
  [LinkTypes.LEGAL_NOTICE]: 'Access to <b>legal notice</b>',
}, '');

const HelpModal: React.FC<{ open?: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const ref = React.useRef<HTMLButtonElement>();
  const [linkType, setLinkType] = React.useState<LinkTypes>(LinkTypes.DSA);

  const isPublic = useSelector(isPublicDevice);
  const languages = useSelector(getAllLanguages);
  const locale = useSelector(getSelectedLanguage);
  const legalNotice = useSelector(getLegalNotice);
  const paymentMethods = useSelector(getPaymentMethods);
  const shouldDisplayPhoneNumbers = useSelector(shouldDisplayPhoneNumbersSelector);
  const { isLoading } = useSelector(getTranslationsSelectors);

  const hasPaymentMethods = paymentMethods?.length > 0;

  const languageData = React.useMemo(
    () => languages.reduce<Record<string, string>>(
      (acc, lang) => ({
        ...acc,
        [lang]: intl.formatMessage({ id: `language.${lang}`, defaultMessage: lang.toUpperCase() }),
      }),
      {},
    ),
    [intl, languages],
  );

  const handleChangeLanguage = React.useCallback(
    (newLocale: unknown) => {
      dispatch(actions.setSelectedLanguage(newLocale as LanguageEnum));
    },
    [dispatch],
  );

  const handleChangeLinkType = (newLinkType: LinkTypes) => () => {
    setLinkType(newLinkType);
  };

  return (
    <Modal
      open={open}
      focusRef={ref}
      className="w-full max-w-2xl max-h-[95vh] min-h-[300px] kioskPartteam:min-h-[600px] kioskSharebox:min-h-[400px] flex flex-col"
      title={<FormattedMessage id="helpLine.title" defaultMessage="Need help?" tagName="h2" />}
      onClose={onClose}
    >
      <div className="scroll-smooth flex-1 p-1">
        <div className="mb-8">
          <SelectInput
            value={locale}
            data={languageData}
            loading={isLoading}
            onChange={handleChangeLanguage}
            label={intl.formatMessage({ id: 'helpLine.changeLanguage', defaultMessage: 'Change language' })}
            className={classNames('max-h-44 kiosk:max-h-96 max-h-[160px] ', {
              'max-h-28 kiosk:max-h-52': !hasPaymentMethods && !shouldDisplayPhoneNumbers && !isPublic,
            })}
            data-testid="language-select"
          />
        </div>
        <div className="w-full h-px my-4 kioskSharebox:my-6 kiosk:my-8 bg-border-default" />
        {hasPaymentMethods && (
          <>
            <div className="flex flex-col items-center">
              <p className="text-center">
                <FormattedMessage
                  id="helpLine.paymentMethods"
                  defaultMessage="Here are the methods of payment accepted at the dealership"
                />
              </p>
              <ul className="flex justify-center items-center w-full flex-wrap gap-4 my-4">
                {paymentMethods.map(({ name }) => (
                  <li key={name} className="flex items-center justify-center px-2 bg-card-bg rounded-md text-low">
                    {name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full h-px my-4 kioskSharebox:my-6 kiosk:my-8 bg-border-default" />
          </>
        )}
        {shouldDisplayPhoneNumbers && (
          <>
            <div className="flex flex-col items-center">
              <p className="text-center">
                <FormattedMessage
                  id="helpLine.message"
                  defaultMessage="If you have any problems with the terminal, do not hesitate to contact the number below."
                />
              </p>
              <DealersPhone />
            </div>
            <div className="w-full h-px my-s bg-border-default" />
          </>
        )}
        <div className="flex flex-col items-center">
          {!isPublic && (
            <div className="text-center">
              <FormattedMessage
                id="helpLine.DSA.privateTitle"
                defaultMessage="Content not adapted? You can report the problem <a><b>here</b></a>"
                values={{ a: getHyperlink({ href: DSA_URL, target: '_blank' }), b }}
                tagName="p"
              />
              {legalNotice && (
                <>
                  <div className="w-full h-px my-4 kioskSharebox:my-6 kiosk:my-8 bg-divider-bg" />
                  <FormattedMessage
                    id="helpLine.LEGAL_NOTICE.privateTitle"
                    defaultMessage="<a><b>Legal notice</b></a>"
                    values={{
                      a: getHyperlink({ href: legalNotice, target: '_blank' }),
                      b: (node) => b(node, 'text-s'),
                    }}
                  />
                </>
              )}
            </div>
          )}
          {isPublic && (
            <>
              {legalNotice && (
                <div className="bg-secondary p-3xs w-auto flex justify-between rounded-full mb-s max-w-full">
                  <Button
                    className="truncate"
                    testId={LinkTypes.DSA}
                    size={ButtonSize.SMALL}
                    onClick={handleChangeLinkType(LinkTypes.DSA)}
                    type={linkType === LinkTypes.DSA ? ButtonType.WHITE : ButtonType.LIGHT}
                  >
                    <FormattedMessage id="helpLine.DSA.publicTitle" defaultMessage="Report a problem" />
                  </Button>
                  <Button
                    className="truncate"
                    size={ButtonSize.SMALL}
                    testId={LinkTypes.LEGAL_NOTICE}
                    onClick={handleChangeLinkType(LinkTypes.LEGAL_NOTICE)}
                    type={linkType === LinkTypes.LEGAL_NOTICE ? ButtonType.WHITE : ButtonType.LIGHT}
                  >
                    <FormattedMessage id="helpLine.DSA.publicTitle" defaultMessage="Legal notice" />
                  </Button>
                </div>
              )}
              <p className="text-center text-s">
                <FormattedMessage
                  id={`helpLine.${linkType}.publicDescription`}
                  defaultMessage={defaultPublicDescription[linkType]}
                  values={{ b }}
                />
              </p>
              <div className="!w-1/2 !h-1/2 max-w-40 mt-s flex justify-center items-center p-2xs bg-secondary rounded-3xl">
                <QRCodeViewer content={linkType === LinkTypes.DSA ? DSA_URL : legalNotice} className="!size-full" />
              </div>
              <p className="text-center mt-s">
                <span className="kiosk:text-xl font-medium">{linkType === LinkTypes.DSA ? DSA_URL : legalNotice}</span>
              </p>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default HelpModal;
