import React from 'react';
import { addSeconds } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { useDispatch, useSelector } from 'hooks';
import useInactivity from 'hooks/useInactivity';

import { isClosed, isPending } from 'modules/kiosk/selectors';
import { ScheduleTypeEnum } from 'modules/auth/types/InitialState';
import { getScheduledTime, hasScheduledTime } from 'modules/auth/selectors';

import { logout, resetSchedule, setSchedule } from 'modules/auth/actions';

import { INACTIVITY_SELF_SERVICE_FLOWS, INACTIVITY_TIME_TO_LOGOUT } from 'modules/auth/constants';

import InactivityProgressBar from 'components/Admin/InactivityModal/InactivityProgressBar';
import { Button, Modal } from 'components/ui';
import { ButtonType } from 'components/ui/Button';

const Inactivity: React.FC = () => {
  const dispatch = useDispatch();
  const doorsClosed = useSelector(isClosed);
  const doorsPending = useSelector(isPending);
  const inactivityDate = useSelector((state) => getScheduledTime(state, ScheduleTypeEnum.LOGOUT));
  const displayInactivityModal = useSelector((state) => hasScheduledTime(state, ScheduleTypeEnum.LOGOUT));

  const isPaused = !doorsClosed && !doorsPending;
  const shouldShowModal = !isPaused && displayInactivityModal;

  const handleShowInactivityModal = React.useCallback(() => {
    dispatch(
      setSchedule({
        [ScheduleTypeEnum.LOGOUT]: addSeconds(Date.now(), INACTIVITY_TIME_TO_LOGOUT).toISOString(),
      }),
    );
  }, [dispatch]);

  const { restart } = useInactivity({
    seconds: INACTIVITY_SELF_SERVICE_FLOWS,
    onInactive: handleShowInactivityModal,
    paused: isPaused,
  });

  const handleContinue = React.useCallback(() => {
    dispatch(resetSchedule());
    restart();
  }, [dispatch, restart]);

  const handleLogout = React.useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  React.useEffect(() => {
    restart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doorsClosed]);

  return (
    shouldShowModal && (
      <Modal
        open
        className="modal-large"
        title={(
          <div className="mb-16">
            <span className="text-center">
              <FormattedMessage id="inactivity.modal.title" defaultMessage="Are you still there?" tagName="h2" />
            </span>
          </div>
        )}
      >
        <div className="px-[20%]">
          <InactivityProgressBar date={inactivityDate} onEnded={handleLogout} />
        </div>
        <div className="flex items-center gap-4 mt-20">
          <Button testId="modal-continue" onClick={handleContinue} className="w-1/2">
            <FormattedMessage id="continue.yes" defaultMessage="Yes, continue" />
          </Button>
          <Button testId="modal-confirm" onClick={handleLogout} className="w-1/2" type={ButtonType.SECONDARY}>
            <FormattedMessage id="socket.updateLogoutModal.logOut" defaultMessage="Log out" values={{ countdown: 0 }} />
          </Button>
        </div>
      </Modal>
    )
  );
};

export default Inactivity;
