import { round } from 'utils/formUtils';
import { createDictionary } from 'utils/objectUtils';

import type { Extra } from 'types/ExtrasForm';
import type { RepairOrder } from 'modules/selfServices/types/SelfService';

export const computeTax = (price = 0, vat = 0, coefficient = 1) => round(price * (1 + vat / 100)) * coefficient;

const roundingForSwitzerland = (amount: number): number => (Math.round(amount * 20) / 20);

const defaultRounding = (amount: number) => amount;
export const roundAmountByCountry = createDictionary<string, typeof roundingForSwitzerland>({
  ch: roundingForSwitzerland,
}, defaultRounding);

export const computeTotalPrice = (items: Extra[] | RepairOrder[], vat: number, countryCode: string) => {
  const totalPrice = items?.reduce((acc, item) => acc + computeTax(item.discountedPrice, vat, item.coefficient), 0) ?? 0;
  return roundAmountByCountry[countryCode](totalPrice);
};
