import { createListenerMiddleware } from '@reduxjs/toolkit';

import { setCurrentStep } from 'modules/steps/actions';
import * as actions from 'modules/selfServices/actions';
import { AppStartListening } from 'App/ListenerMiddleware';
import selfServicesApi from 'modules/selfServices/service';
import { ErrorTypeEnum, isApiErrorResponse } from 'types/Error';
import { getFormattedFormData } from 'modules/form/selectors';
import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { getTotalElapsedTime } from 'modules/elapsedTime/selectors';

const listenerMiddleware = createListenerMiddleware();

const startAppListening = listenerMiddleware.startListening as AppStartListening;

startAppListening({
  actionCreator: actions.finalizeSelfService,
  effect: async ({ payload }, { dispatch, getState }) => {
    const totalElapsedTime = getTotalElapsedTime(getState());
    const selfServiceId = getSelectedSelfServiceId(getState());

    // Get the selfService
    const selfService = await dispatch(selfServicesApi.endpoints.getSelfServiceById.initiate(
      { id: selfServiceId },
    )).unwrap();

    const formattedData = getFormattedFormData(getState(), selfService);
    try {
      await dispatch(selfServicesApi.endpoints.updateSelfService.initiate({
        id: selfServiceId,
        body: {
          ...selfService,
          ...formattedData,
          elapsedTime: totalElapsedTime + payload.time,
        },
      }, { fixedCacheKey: 'UPDATE/SELF_SERVICE' })).unwrap();
      dispatch(setCurrentStep(payload.nextStep));
    } catch (error) {
      const isWrongStatus = isApiErrorResponse(error) && error.data.errorType === ErrorTypeEnum.WRONG_STATUS;
      if (isWrongStatus) {
        dispatch(setCurrentStep(payload.nextStep));
      }
    }
  },
});

export default listenerMiddleware;
