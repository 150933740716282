/* eslint-disable quote-props */
import React from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { getSelectedLanguage, getTranslations } from 'modules/translations/selectors';

const IntlWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const locale = useSelector(getSelectedLanguage);
  const messages = useSelector(getTranslations);
  const isReady = Object.keys(messages).length > 0;

  return isReady && (
    <IntlProvider messages={messages} locale={locale} defaultLocale="en">
      {children}
    </IntlProvider>
  );
};

export default IntlWrapper;
