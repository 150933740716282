import api from 'service/index';

import {
  GET_MEDIA as MEDIAS, GET_MEDIA_UPLOAD_URL, SELF_SERVICE, BASE_URL,
} from 'constants/url';

import { addNotification } from 'modules/notifications/actions';
import { NotificationType } from 'modules/notifications/types/Notification';
import UploadMediaRequest from './types/UploadMediaRequest';
import MediaUploadUrlRequest from './types/MediaUploadUrlRequest';
import MediaUploadUrlResponse from './types/MediaUploadUrlResponse';
import GetMediaRequest from './types/GetMediaRequest';
import { SendMetricsRequest, SendMetricsResponse } from './types/SendMetricsRequest';

export const mediasApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMediaUrl: build.mutation<MediaUploadUrlResponse, MediaUploadUrlRequest & { isSignature?: boolean }>({
      query: ({
        id, url, filename, contentType, isSignature,
      }) => ({
        method: 'GET',
        url: url ?? `${SELF_SERVICE}/${id}${GET_MEDIA_UPLOAD_URL}`,
        params: { filename, contentType, isSignature },
      }),
    }),
    uploadMedia: build.mutation<undefined, UploadMediaRequest>({
      query: ({ url, file }) => ({
        method: 'PUT',
        url,
        body: new File([file], ''), // We remove  the filename from here because it seems to cause issues with S3
        headers: {
          'Content-Type': file.type ?? 'application/octet-stream',
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          dispatch(addNotification({
            title: {
              id: 'error.media.uploading.title',
              defaultMessage: 'Error uploading media',
            },
            description: {
              id: 'error.media.uploading.description',
              defaultMessage: 'An error occurred while uploading the media. Please try again.',
            },
            type: NotificationType.ERROR,
          }));
        }
      },
    }),
    getMedia: build.mutation<MediaUploadUrlResponse, GetMediaRequest>({
      query: ({
        selfServiceId, id,
      }) => ({
        method: 'GET',
        url: `${SELF_SERVICE}/${selfServiceId}${MEDIAS}/${id}`,
      }),
    }),
    deleteMedia: build.mutation<MediaUploadUrlResponse, GetMediaRequest>({
      query: ({
        selfServiceId, id,
      }) => ({
        method: 'DELETE',
        url: `${SELF_SERVICE}/${selfServiceId}${MEDIAS}/${id}`,
      }),
    }),
    sendMetrics: build.mutation<SendMetricsResponse, SendMetricsRequest>({
      query: ({ url, time }) => {
        const { origin } = new URL(BASE_URL);
        return {
          method: 'POST',
          url: `${origin}${url}`,
          body: { time },
        };
      },
    }),
  }),
});

export const {
  useGetMediaMutation,
  useGetMediaUrlMutation,
  useUploadMediaMutation,
  useDeleteMediaMutation,
  useSendMetricsMutation,
} = mediasApi;

export default mediasApi;
