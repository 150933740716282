import Parking from 'components/Parking';
import FinalInvoice from 'components/FinalInvoice';
import AppointmentSummary from 'components/AppointmentSummary';
import ParkingInstruction from 'components/Parking/ParkingInstruction';

import { SOCKET_UPDATE_STEPS } from 'modules/sockets/constants';
import { shouldDisplayMobilityParking } from 'modules/selfServices/selectors';
import { SelfServiceAPISteps, SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';
import { SelfService, SelfServiceOrigin, SignatureStatus } from 'modules/selfServices/types/SelfService';

import type { SelfServiceStep } from '../types';
import { hasParking, isNotAnswered, hasHiddenSteps } from './utils';
import {
  KEY, REMOTE_KEY, KIOSK_LOCATION, FINAL_INSTRUCTIONS, SIGNATURE,
} from './common';

const SUMMARY: SelfServiceStep = {
  key: SelfServiceSteps.SUMMARY,
  apiKey: SelfServiceAPISteps.APPOINTMENT_SUMMARY,
  component: AppointmentSummary,
  title: {
    id: 'page.appointmentSummary.title',
    defaultMessage: 'Your appointment',
  },
  next: SelfServiceSteps.FINAL_INVOICE,
  data: {
    preventBrowserNavigation: true,
    isVisible: ({ status }) => isNotAnswered(status),
    socketUpdateFields: SOCKET_UPDATE_STEPS.SUMMARY,
  },
};

const FINAL_INVOICE: SelfServiceStep = {
  key: SelfServiceSteps.FINAL_INVOICE,
  apiKey: SelfServiceAPISteps.FINAL_INVOICE,
  component: FinalInvoice,
  title: {
    id: 'page.finalInvoice.title',
    defaultMessage: 'Final invoice',
  },
  previous: SUMMARY.key,
  next: SelfServiceSteps.PARKING,
  data: {
    isVisible: ({ status, finalInvoice }) => isNotAnswered(status) && Boolean(finalInvoice),
    socketUpdateFields: SOCKET_UPDATE_STEPS.FINAL_INVOICE,
  },
};

const PARKING: SelfServiceStep = {
  key: SelfServiceSteps.PARKING,
  apiKey: SelfServiceAPISteps.CUSTOMER_CAR_INFO,
  component: ParkingInstruction,
  title: {
    id: 'parking.title',
    defaultMessage: 'Parking',
  },
  previous: FINAL_INVOICE.key,
  data: {
    isVisible: ({ status, parking }) => isNotAnswered(status) && hasParking(parking),
    socketUpdateFields: SOCKET_UPDATE_STEPS.PARKING,
  },
};

const MOBILITY_PARKING: SelfServiceStep = {
  key: SelfServiceSteps.MOBILITY_PARKING,
  apiKey: SelfServiceAPISteps.COURTESY_CAR_PARKING,
  component: Parking,
  title: (selfService) => {
    const isMobility = shouldDisplayMobilityParking(selfService);
    return {
      id: isMobility ? 'parking.mobility.title' : 'parking.title',
      defaultMessage: isMobility ? 'Courtesy vehicle parking' : 'Parking',
    };
  },
  previous: PARKING.key,
  data: {
    isVisible: ({ status, mobilityEnabled, parkingMap }) => isNotAnswered(status) && mobilityEnabled && (parkingMap?.allowParkingSpot || parkingMap?.maps?.length > 0),
  },
};

const CHECKOUT_SIGNATURE: SelfServiceStep = {
  ...SIGNATURE,
  data: {
    ...SIGNATURE.data,
    isFinalizeStep: true,
    isVisible: ({ mobility, mobilityEnabled, status }) => isNotAnswered(status) && mobilityEnabled && mobility?.deliveryDocument?.status === SignatureStatus.UNSIGNED,
  },
};

const HOME_CHECK_OUT_STEPS: Record<string, SelfServiceStep> = {
  SUMMARY: {
    ...SUMMARY,
    data: { ...SUMMARY.data, isFinalizeStep: hasHiddenSteps([FINAL_INVOICE]) },
  },
  FINAL_INVOICE: {
    ...FINAL_INVOICE,
    next: FINAL_INSTRUCTIONS.key,
    data: { ...FINAL_INVOICE.data, isFinalizeStep: true },
  },
  FINAL_INSTRUCTIONS: {
    ...FINAL_INSTRUCTIONS,
    apiKey: SelfServiceAPISteps.INSTRUCTIONS,
    previous: FINAL_INVOICE.key,
  },
};

const ON_SITE_CHECK_OUT_STEPS: Record<string, SelfServiceStep> = {
  SUMMARY: {
    ...SUMMARY,
    data: {
      ...SUMMARY.data,
      isFinalizeStep: hasHiddenSteps([FINAL_INVOICE, PARKING, MOBILITY_PARKING, SIGNATURE]),
    },
  },
  FINAL_INVOICE: {
    ...FINAL_INVOICE,
    data: {
      ...FINAL_INVOICE.data,
      isFinalizeStep: hasHiddenSteps([PARKING, MOBILITY_PARKING, SIGNATURE]),
    },
  },
  PARKING: {
    ...PARKING,
    next: MOBILITY_PARKING.key,
    data: {
      ...PARKING.data,
      isFinalizeStep: hasHiddenSteps([MOBILITY_PARKING, SIGNATURE]),
    },
  },
  MOBILITY_PARKING: {
    ...MOBILITY_PARKING,
    next: SIGNATURE.key,
    data: {
      ...MOBILITY_PARKING.data,
      isFinalizeStep: hasHiddenSteps([SIGNATURE]),
    },
  },
  SIGNATURE: {
    ...CHECKOUT_SIGNATURE,
    previous: MOBILITY_PARKING.key,
    next: FINAL_INSTRUCTIONS.key,
  },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: MOBILITY_PARKING.key },
};

const KIOSK_CHECK_OUT_STEPS: Record<string, SelfServiceStep> = {
  SUMMARY: {
    ...SUMMARY,
    data: {
      ...SUMMARY.data,
      isFinalizeStep: hasHiddenSteps([FINAL_INVOICE, PARKING, MOBILITY_PARKING, SIGNATURE]),
    },
  },
  FINAL_INVOICE: {
    ...FINAL_INVOICE,
    data: {
      ...FINAL_INVOICE.data,
      isFinalizeStep: hasHiddenSteps([PARKING, MOBILITY_PARKING, SIGNATURE]),
    },
  },
  PARKING: {
    ...PARKING,
    next: MOBILITY_PARKING.key,
    data: {
      ...PARKING.data,
      isFinalizeStep: hasHiddenSteps([MOBILITY_PARKING, SIGNATURE]),
    },
  },
  MOBILITY_PARKING: {
    ...MOBILITY_PARKING,
    next: SIGNATURE.key,
    data: {
      ...MOBILITY_PARKING.data,
      isFinalizeStep: hasHiddenSteps([SIGNATURE]),
    },
  },
  SIGNATURE: {
    ...CHECKOUT_SIGNATURE,
    previous: MOBILITY_PARKING.key,
    next: KEY.key,
    data: {
      ...CHECKOUT_SIGNATURE.data,
      isFinalizeStep: true,
    },
  },
  KIOSK_LOCATION: {
    ...KIOSK_LOCATION,
    key: KEY.key,
    previous: SIGNATURE.key,
    next: FINAL_INSTRUCTIONS.key,
  },
  KEY: {
    ...KEY,
    previous: SIGNATURE.key,
    next: FINAL_INSTRUCTIONS.key,
  },
  REMOTE_KEY: {
    ...REMOTE_KEY,
    previous: SIGNATURE.key,
    next: FINAL_INSTRUCTIONS.key,
  },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: KEY.key },
};

export default {
  [SelfServiceOrigin.HOME]: HOME_CHECK_OUT_STEPS,
  [SelfServiceOrigin.ON_SITE]: ON_SITE_CHECK_OUT_STEPS,
  [SelfServiceOrigin.KIOSK]: KIOSK_CHECK_OUT_STEPS,
};
