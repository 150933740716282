import { ResponseData } from 'modules/sockets/types/SocketResponse';

export const POOL_INTERVAL = 9 * 60 * 1000;

export const SOCKET_UPDATE_STEPS: Record<string, Array<keyof ResponseData>> = {
  REPAIR_ORDER: ['updatedWorksDone'],
  EXTRAS: ['updatedExtras'],
  QUESTIONS: ['updatedQuestionForm'],
  SUMMARY: ['updatedComment', 'updatedInterventionMedias', 'updatedMedia'],
  FINAL_INVOICE: ['updatedFinalInvoice'],
  PARKING: ['updatedParking'],
  FINAL_INSTRUCTIONS: ['vehicleCheckStarted'],
};
