import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormRenderProps } from 'react-final-form';

import { useDispatch } from 'hooks';
import { closeBasketModal } from 'modules/basket/actions';

import type { ExtrasForm as ExtrasFormType } from 'types/ExtrasForm';

import Content from './Content';
import BaseModal from '../../ui/Modal';

const Modal: React.FC<FormRenderProps<ExtrasFormType>> = () => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeBasketModal());
  };

  return (
    <BaseModal
      open
      onClose={handleCloseModal}
      className="w-full max-w-modal max-h-[95vh] flex flex-col"
      title={<FormattedMessage id="basket.modal.title" defaultMessage="Order" tagName="h2" />}
    >
      <div className="overflow-auto">
        <Content />
        <p className="text-center text-xxs mt-l">
          <FormattedMessage
            id="basket.modal.footer"
            defaultMessage="Please remember that by placing your order, you have declared your consent to the GTC and the data handling policy. You have expressly agreed to the execution of the contract before the end of the withdrawal period. You have been informed that the right of withdrawal expires at the start of contract execution."
          />
        </p>
      </div>
    </BaseModal>
  );
};

export default Modal;
