export const { BASE_URL } = process.env;

export const ROOT = '/root';
export const ADMIN = '/admin';
export const LOGIN = '/token/login';
export const LOGIN_MFA = '/mfa';
export const DEALERS = '/dealers';
export const CONTEXT = '/token/context';
export const TRANSLATIONS = '/translations';
export const SELF_SERVICE = '/selfServices';
export const GET_MEDIA = '/medias';
export const GET_MEDIA_UPLOAD_URL = '/medias/url';
export const KIOSK = '/kiosks';
export const PING = '/token/ping';
export const VEHICLE_CHECK = '/vehicleCheck';
export const VEHICLE_CHECK_MODEL = '/vehicleCheckModel';

// SEE: `src/modules/medias/service.ts` line 18 (`uploadMedia: build.mutation // ...`)
export const TOKEN_EXCLUDED_ENDPOINTS = [
  'getContext',
  'loginAdmin',
  'uploadMedia',
  'getLanguages',
  'getVCContext',
  'checkQRCodeToken',
  'getTranslationsByLanguage',
  'sendMetrics',
];

export const LOGIN_TOKEN_ENDPOINTS = ['login', 'getMFAConnection', 'sendMFACode', 'loginMFA'];
export const ADMIN_TOKEN_ENDPOINTS = [
  'getKioskData',
  'openSlotAsAdmin',
  'closeSlotAsAdmin',
  'updateSlotAsAdmin',
  'unprepareSlotAsAdmin',
  'getReadySelfServices',
];
