import React from 'react';
import classNames, { Argument } from 'classnames';
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/solid';

import ArrowDirection from 'assets/icons/Arrow';
import { AbstractMedia } from 'types/AbstractMedia';

import { downloadURL } from 'utils/downloadUtils';

import Arrow from 'assets/icons/arrow.svg';
import DownloadIcon from 'assets/icons/download.svg';

import StepIndicator from '../StepIndicator';
import { DEFAULT_MEDIA_COMPONENTS } from '../File';

export interface CarouselProps {
  className?: Argument;
  medias: Array<AbstractMedia>;
  onClose?: () => void;
  fullscreen?: boolean;
  hideActions?: boolean;
  hideCursor?: boolean;
  showDownload?: boolean;
  defaultMediaIndex?: number;
  onDelete?: (media: AbstractMedia) => void;
  onMediaClick?: (media: AbstractMedia) => void;
  onPageChange?: (pageNumber: number, totalPages: number) => void;
  watermarkTitle?: string;
}

const MIN_SWIPE_DISTANCE = 75;

const getCarouselStyle = (isFullscreen: boolean) => ({
  wrapper: isFullscreen
    ? 'fixed inset-0 h-full w-screen bg-recommended-bg overflow-hidden z-50'
    : 'overflow-hidden relative w-full flex items-center',
  subWrapper: isFullscreen ? 'absolute w-full inset-y-16' : 'mx-auto w-full',
  subSubWrapper: `aspect-video relative overflow-hidden ${isFullscreen ? 'flex w-full h-full justify-center' : ''}`,
  media: `absolute ease-in duration-300 inset-0 ${isFullscreen ? 'flex items-center justify-center' : ''}`,
  arrow: `flex items-center justify-center rounded-full p-3 absolute top-1/2 z-10 -mt-5 backdrop-blur-sm overflow-hidden duration-500 cursor-pointer  ${isFullscreen ? 'bg-black/70 hover:bg-black/90 fill-white h-12 w-12 ' : 'h-10 w-10 bg-white/70 hover:bg-white/90 fill-gray-900'}`,
});

const Carousel: React.FC<CarouselProps> = ({
  medias, fullscreen, onClose, onDelete, onPageChange, hideActions, hideCursor, showDownload, defaultMediaIndex, onMediaClick, className, watermarkTitle,
}) => {
  const touchPosition = React.useRef<number>();

  const [currentIndex, setCurrentIndex] = React.useState(() => (defaultMediaIndex > -1 ? defaultMediaIndex : 0));

  const onPrev = React.useCallback(() => setCurrentIndex((index) => Math.max(0, index - 1)), []);
  const onNext = React.useCallback(() => setCurrentIndex((index) => Math.min(medias.length - 1, index + 1)), [medias.length]);

  const handleTouchStart = React.useCallback((event: React.TouchEvent<HTMLDivElement>) => {
    touchPosition.current = event.targetTouches[0].clientX;
  }, []);

  const handleTouchEnd = React.useCallback((event: React.TouchEvent<HTMLDivElement>) => {
    const x = event.changedTouches[0].clientX;
    if (Math.abs(x - touchPosition.current) > MIN_SWIPE_DISTANCE) {
      if (x < touchPosition.current) {
        onNext();
      } else if (x > touchPosition.current) {
        onPrev();
      }
    }
  }, [onNext, onPrev]);

  const handleDelete = React.useCallback(() => {
    setCurrentIndex((index) => {
      onDelete(medias[index]);
      return index === medias.length - 1 ? Math.max(0, medias.length - 2) : index;
    });
  }, [onDelete, medias]);

  const handleDownload = React.useCallback(() => {
    const { path, filename } = medias[currentIndex];
    downloadURL(path, filename);
  }, [medias, currentIndex]);

  const handleMediaClick = React.useCallback(
    () => onMediaClick?.(medias[currentIndex]),
    [onMediaClick, medias, currentIndex],
  );

  const showActions = fullscreen && !hideActions;
  const showStepIndicator = !fullscreen && medias.length > 1;

  const styles = React.useMemo(() => getCarouselStyle(fullscreen), [fullscreen]);

  const displayCursor = React.useMemo(
    () => !hideCursor && !fullscreen && onMediaClick && medias.length > 1,
    [fullscreen, hideCursor, medias.length, onMediaClick],
  );

  const media = React.useMemo(() => medias[currentIndex], [currentIndex, medias]);

  React.useEffect(() => {
    // Prevent to scroll in the page when you display a media in fullscreen
    const action = fullscreen ? 'add' : 'remove';
    document.body.classList[action]('overflow-hidden');

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [fullscreen]);

  React.useEffect(() => {
    // Fallback
    const hasBeenRemoved = currentIndex > 0 && currentIndex >= medias.length;
    if (hasBeenRemoved) {
      setCurrentIndex(medias.length - 1);
    }
  }, [setCurrentIndex, currentIndex, medias.length]);

  return (
    <div className={styles.wrapper}>
      {fullscreen && media && (
        <div className="fixed z-10 top-0 left-0 p-4 kiosk:p-12 flex justify-between h-16 bg-gray-90 w-full text-white items-center backdrop-blur-sm">
          <div className="w-8" /* placeholder */ />
          {medias.length > 0 && (
            <span data-testid="filename-carousel" className="truncate">
              {media.filename}
            </span>
          )}
          <div>
            <XMarkIcon
              onClick={onClose}
              data-testid="close-carousel"
              className="h-8 w-8 rounded-full cursor-pointer"
            />
          </div>
        </div>
      )}
      <div
        className={styles.subWrapper}
        data-testid="carousel"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <div className={styles.subSubWrapper}>
          {currentIndex > 0 && (
            <Arrow
              onClick={onPrev}
              data-testid="carousel-leftArrow"
              className={classNames(styles.arrow, 'left-3', ArrowDirection.LEFT)}
            />
          )}
          {currentIndex < medias.length - 1 && (
            <Arrow
              onClick={onNext}
              data-testid="carousel-rightArrow"
              className={classNames(styles.arrow, 'right-3')}
            />
          )}
          <div
            onClick={handleMediaClick}
            data-testid="carousel-inner"
            className={classNames(
              'relative overflow-hidden aspect-video scroll-smooth z-0 w-full flex items-center',
              { 'cursor-pointer': displayCursor },
              className,
            )}
          >
            {medias.length > 0 && media && (
              <div
                key={media.uploadId ?? media.path}
                className={styles.media}
              >
                {DEFAULT_MEDIA_COMPONENTS[media.type]?.(media, { thumbnail: !fullscreen, onPageChange, watermarkTitle })}
              </div>
            )}
          </div>
        </div>
      </div>
      {showActions && (
        <div className="fixed bottom-0 p-4 flex h-16 w-full justify-center text-white bg-gray-90 backdrop-blur-sm">
          {showDownload && (
            <DownloadIcon
              onClick={handleDownload}
              className="cursor-pointer fill-white"
              data-testid="download-media-carousel"
            />
          )}
          {onDelete && (
            <TrashIcon
              onClick={handleDelete}
              className="cursor-pointer"
              data-testid="delete-media-carousel"
            />
          )}
        </div>
      )}
      {showStepIndicator && (
        <StepIndicator
          currentIndex={currentIndex}
          length={medias.length}
          data-testid="steps-carousel"
          className="absolute bottom-0 pt-5 pb-3 bg-gradient-to-t from-slate-900/40 flex justify-center justify-items-center items-center w-full"
          stepClassName="bg-white"
        />
      )}
    </div>
  );
};

export default Carousel;
