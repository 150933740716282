import React from 'react';

interface OutsideProps {
  onClickOutside: () => void;
}

const Outside: React.FC<React.PropsWithChildren<OutsideProps>> = ({
  children,
  onClickOutside,
}) => {
  const ref = React.useRef<HTMLSpanElement>();

  const handleClickOutside = React.useCallback(
    (event: MouseEvent) => {
      if (ref && !ref.current?.contains(event.target as Node)) {
        onClickOutside();
      }
    },
    [ref, onClickOutside],
  );

  React.useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  });

  return <span ref={ref}>{children}</span>;
};

export default Outside;
