import React from 'react';
import QRCode from 'qrcode';

interface QRCodeViewerProps {
  content: string;
  options?: QRCode.QRCodeRenderersOptions;
  className?: string;
}

const QRCodeViewer: React.FC<QRCodeViewerProps> = ({ content, options, className }) => {
  const canvas = React.useRef();

  React.useLayoutEffect(() => {
    if (canvas.current) {
      QRCode.toCanvas(canvas.current, content, { margin: 0, ...options });
    }
  }, [options, canvas, content]);

  return <canvas ref={canvas} className={className} />;
};

export default QRCodeViewer;
