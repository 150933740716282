/* eslint-disable react/style-prop-object */
import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { InformationCircleIcon as WarningIcon } from '@heroicons/react/24/solid';
import { FormattedMessage, FormattedNumber, MessageDescriptor } from 'react-intl';

import { b } from 'utils/i18nUtils';
import { useProceedToPaymentMutation } from 'modules/selfServices/service';

import { closePayingModal, openPayingModal } from 'modules/onlinePayment/actions';

import { isInstructionModalDisplayed } from 'modules/sockets/selectors';
import { isKioskOrigin, isPublicDevice } from 'modules/dealers/selectors';
import { getStatus, isPayingModalOpen as isPayingModalOpenSelector } from 'modules/onlinePayment/selectors';

import { FinalInvoice, FinalInvoiceStatusesEnum } from 'modules/selfServices/types/SelfService';

import { ButtonType } from 'components/ui/Button';
import { Attachments, Button } from 'components/ui';

import CheckIcon from 'assets/icons/check.svg';
import InvoiceIcon from 'assets/icons/invoice.svg';

import PayingModal from './Modal/PayingModal';
import PendingModal from './Modal/PendingModal';
import KioskInstructionModal from './Modal/KioskInstructionModal';

export interface FinalInvoiceCardProps {
  skipable?: boolean;
  onSkip?: () => void;
  unpayable?: boolean;
  selfServiceId: string;
  currencyIsoCode: string;
  finalInvoice: FinalInvoice;
}

const FinalInvoiceCard: React.FC<FinalInvoiceCardProps> = ({
  selfServiceId,
  finalInvoice,
  currencyIsoCode,
  unpayable,
  onSkip,
  skipable,
}) => {
  const dispatch = useDispatch();

  const [proceedToPayment, { isLoading: isLoadingPayment }] = useProceedToPaymentMutation();

  const [isSkipping, setIsSkipping] = React.useState(false);
  const [isPendingOpen, setPendingOpen] = React.useState(false);
  const [isRedirecting, setIsRedirecting] = React.useState(false);

  const isLoading = isLoadingPayment || isRedirecting;
  const isDisabled = isLoading || isSkipping || isRedirecting;
  const isPaid = finalInvoice.status === FinalInvoiceStatusesEnum.PAID;

  const isKiosk = useSelector(isKioskOrigin);
  const isPublic = useSelector(isPublicDevice);
  const paymentStatus = useSelector(getStatus);
  const isPayingModalOpen = useSelector(isPayingModalOpenSelector);
  const isInstructionOpen = useSelector(isInstructionModalDisplayed);

  const canPay = !unpayable && !isPaid;

  const handlePaymentOnClick = React.useCallback(() => {
    if (finalInvoice.link) {
      setIsRedirecting(true);
      setPendingOpen(true);
      window.location.assign(finalInvoice.link);
    } else {
      proceedToPayment({ id: selfServiceId });
      setPendingOpen(false);
    }
  }, [finalInvoice.link, proceedToPayment, selfServiceId]);

  const handlePay = React.useCallback(() => {
    if (isPublic) {
      // Online payment
      if (finalInvoice.link) {
        dispatch(openPayingModal());
      } else { // Terminal payment
        proceedToPayment({ id: selfServiceId });
      }
    } else {
      // Bezahl
      // eslint-disable-next-line no-lonely-if
      if (finalInvoice.pending) {
        setPendingOpen(true);
      } else {
        handlePaymentOnClick();
      }
    }
  }, [dispatch, finalInvoice, isPublic, proceedToPayment, selfServiceId, handlePaymentOnClick]);

  const handleCancel = () => {
    setPendingOpen(false);
  };

  const handleClosePayingModal = React.useCallback(() => {
    dispatch(closePayingModal());
  }, [dispatch]);

  const handleOnSkip = React.useCallback(() => {
    if (onSkip) {
      setIsSkipping(true);
      onSkip();
    }
  }, [onSkip]);

  const paymentButtonText: MessageDescriptor = React.useMemo(() => {
    if (skipable || isKiosk) {
      return {
        id: 'page.finalInvoice.buttons.paymentNow',
        defaultMessage: 'Pay now',
      };
    }
    return {
      id: 'page.finalInvoice.buttons.payment',
      defaultMessage: 'Proceed with payment',
    };
  }, [skipable, isKiosk]);

  const amountLabel = React.useMemo(
    () => (
      unpayable
        ? { id: 'page.finalInvoice.invoiceAmount', defaultMessage: 'Invoice amount (VAT incl.)' }
        : { id: 'page.finalInvoice.amountToPaid', defaultMessage: 'Amount to be paid (VAT incl.)' }
    ),
    [unpayable],
  );

  const downloadButtonProps = React.useMemo(() => ({
    disabled: isPendingOpen || isInstructionOpen,
    loading: isLoading,
  }), [isLoading, isInstructionOpen, isPendingOpen]);

  return (
    <div
      className="rounded-lg kiosk:rounded-2xl w-full border bg-input-bg
               border-border-bg px-5 py-10 kiosk:px-10 kiosk:py-20"
    >
      <div className="flex items-center w-full flex-col">
        <span
          className={classNames('bg-white rounded-full p-5 kiosk:p-10 flex items-center justify-center', {
            '!bg-icon-success/[.20]': isPaid,
          })}
        >
          {isPaid ? (
            <div className="w-14 h-14 rounded-full kiosk:w-28 kiosk:h-28 bg-icon-success grid place-items-center">
              <CheckIcon className="check-icon h-8 w-8 kiosk:h-16 kiosk:w-16 fill-white" />
            </div>
          ) : (
            <InvoiceIcon className="invoice-icon h-12 w-12 kiosk:w-28 kiosk:h-28 fill-gray-90" />
          )}
        </span>

        <div className="mt-4 kiosk:mt-12 font-medium kiosk:font-gray-90" data-testid="status">
          {isPaid && (
            <FormattedMessage id="page.finalInvoice.amountPaid" defaultMessage="Amount paid (VAT Incl.)" tagName="p" />
          )}
          {!isPaid && (
            <FormattedMessage
              {...amountLabel}
              tagName="p"
            />
          )}
        </div>

        <div className="mt-3 font-extrabold text-4xl kiosk:mt-5 kiosk:font-normal kiosk:text-5xl" data-testid="price">
          <FormattedNumber value={finalInvoice.price} style="currency" currency={currencyIsoCode} />
        </div>
      </div>

      <div className="mt-6 kiosk:mt-12">
        {canPay && (
          <>
            <Button
              analytics={{
                category: 'Payment',
                action: 'Payment started',
              }}
              type={ButtonType.TERTIARY}
              className="w-full py-3 kiosk:!py-6 kiosk:text-xl"
              loading={isLoading}
              onClick={handlePay}
              testId="pay-button"
              disabled={isDisabled}
            >
              <FormattedMessage {...paymentButtonText} />
            </Button>
            {skipable && (
              <Button
                analytics={{
                  category: 'Payment',
                  action: 'Payment delayed',
                }}
                type={ButtonType.PRIMARY}
                className="w-full mt-3 py-3 kiosk:mt-6 kiosk:!py-6 kiosk:text-xl"
                loading={isLoading || isSkipping}
                onClick={handleOnSkip}
                testId="pay-later-button"
                disabled={isDisabled}
              >
                <FormattedMessage id="page.finalInvoice.buttons.paymentLater" defaultMessage="Pay at the dealership" />
              </Button>
            )}
            {finalInvoice.pending && (
              <div
                className="warning-alert mt-3 kiosk:mt-6 kiosk:text-xl"
                data-testid="pending-payment-warning"
              >
                <div className="mx-2 kiosk:mx-4">
                  <WarningIcon className="h-7 w-7 kiosk:h-10 kiosk:w-10 text-star-color" />
                </div>
                <span className="kiosk:!text-2xl ">
                  <FormattedMessage
                    id="page.finalInvoice.modal.pending.content"
                    defaultMessage="A payment is <b>waiting for validation</b>"
                    values={{ b: (text) => b(text, 'kiosk:text-2xl') }}
                  />
                </span>
              </div>
            )}
          </>
        )}
      </div>

      <PendingModal
        loading={isLoading}
        open={isPendingOpen}
        onCancel={handleCancel}
        onClick={handlePaymentOnClick}
        buttonText={paymentButtonText}
      />

      <PayingModal
        open={isPayingModalOpen}
        link={finalInvoice.link}
        selfServiceId={selfServiceId}
        paymentStatus={paymentStatus}
        onCancel={handleClosePayingModal}
      />

      <KioskInstructionModal open={isInstructionOpen} isPaid={isPaid} />

      <div className="border-t my-6 kiosk:my-12 border-border-bg" />

      <div className="my-6 kiosk:my-8 flex justify-between px-4 text-base text-gray-70">
        <span className="mr-4">
          <FormattedMessage id="page.finalInvoice.invoiceNumber" defaultMessage="Invoice N°" />
        </span>
        <span className="font-bold text-gray-90 break-word" data-testid="reference">
          {finalInvoice.name}
        </span>
      </div>
      <div className="kiosk:mx-4">
        <Attachments
          documents={finalInvoice.medias}
          documentClassName={classNames({ 'first:border-0 first:pt-0 last:pb-0': !isPaid })}
          singleDownloadTitle={
            <FormattedMessage id="page.finalInvoice.buttons.download" defaultMessage="Download invoice" />
          }
          buttonProps={downloadButtonProps}
        />
      </div>
    </div>
  );
};

export default FinalInvoiceCard;
