import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useField, useForm } from 'react-final-form';

import ExtraStatus from 'types/ExtraStatus';
import type { Extra } from 'types/ExtrasForm';
import { RepairOrder } from 'modules/selfServices/types/SelfService';

import { useSelector } from 'hooks';
import { formatUndefined } from 'utils/formUtils';
import { computeTax, roundAmountByCountry } from 'utils/priceUtils';
import { useGetSelfServiceByIdQuery } from 'modules/selfServices/service';

import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { getCountry, getValueAddedTax } from 'modules/selfServices/selectors';

import useRepairOrder from 'components/RepairOrder/useRepairOrder';
import TotalLine from './TotalLine';
import List from './List';

const computeTotalPrice = (items: Extra[] | RepairOrder[], vat: number, countryCode: string) => {
  const totalPrice = items?.reduce((acc, item) => acc + computeTax(item.discountedPrice, vat, item.coefficient), 0) ?? 0;
  return roundAmountByCountry[countryCode](totalPrice);
};

const Content: React.FC = () => {
  const form = useForm();

  const vat = useSelector(getValueAddedTax);
  const country = useSelector(getCountry);
  const selfServiceId = useSelector(getSelectedSelfServiceId);
  const { data: selfService } = useGetSelfServiceByIdQuery({ id: selfServiceId });

  const { value: extras = [] } = useField<Extra[]>('extras', { subscription: { value: true }, format: formatUndefined }).input;

  const { sortedRepairOrders } = useRepairOrder(selfService);

  const acceptedExtras = React.useMemo(
    () => extras.filter((extra) => extra.status === ExtraStatus.ACCEPTED),
    [extras],
  );

  const { sortedRepairOrderItems } = useRepairOrder(selfService);

  const nbExtras = acceptedExtras.length;
  const nbRepairOrder = sortedRepairOrderItems?.length ?? 0;
  const hasData = (nbRepairOrder + nbExtras) > 0;

  const totalExtras = React.useMemo(
    () => computeTotalPrice(acceptedExtras, vat, country?.code),
    [acceptedExtras, country?.code, vat],
  );
  const totalRepairOrder = React.useMemo(
    () => {
      // Only customer payer is counted in the total
      const data = sortedRepairOrderItems?.filter((repairOrder) => !repairOrder.payerId);
      return computeTotalPrice(data, vat, country?.code);
    },
    [country?.code, sortedRepairOrderItems, vat],
  );
  const total = React.useMemo(() => totalExtras + totalRepairOrder, [totalExtras, totalRepairOrder]);

  const handleRemove = React.useCallback(
    (adviceId: string) => () => {
      // When you remove the last extra and there is no repair order.
      const shouldResetConsent = extras.length === 1 && nbRepairOrder === 0;
      const extraIndex = extras.findIndex((extra) => extra.adviceId === adviceId);
      form.change(`extras.[${extraIndex}].status`, ExtraStatus.REFUSED);

      if (shouldResetConsent) {
        form.batch(() => {
          form.change('basketConsent', false);
          form.change('basketGTC', false);
        });
      }
    },
    [extras, nbRepairOrder, form],
  );

  return (
    <div className="flex flex-col">
      {!hasData && (
        <span className="text-center text-default italic text-xs" data-testid="empty-basket">
          <FormattedMessage
            id="basket.empty"
            defaultMessage="Your basket is empty"
          />
        </span>
      )}
      {hasData && (
        <div>
          {nbRepairOrder > 0 && (
            <List
              items={sortedRepairOrderItems}
              title={<FormattedMessage id="repairOrder.title" defaultMessage="Repair order" />}
            />
          )}
          {nbExtras > 0 && (
            <List
              items={acceptedExtras}
              onRemove={handleRemove}
              title={<FormattedMessage id="extras.title" defaultMessage="Extras" />}
            />
          )}
          <div className="h-px w-full bg-divider-bg mb-s" />
          {totalRepairOrder > 0 && totalExtras > 0 && (
            <>
              <TotalLine
                title={<FormattedMessage id="basket.total.workOrder" defaultMessage="Total amount of repair order" />}
                price={totalRepairOrder}
              />
              <TotalLine
                title={<FormattedMessage id="basket.total.extras" defaultMessage="Total amount of extras" />}
                price={totalExtras}
              />
            </>
          )}
          <TotalLine
            price={total}
            className="mt-s !text-default font-semibold"
            title={<FormattedMessage id="basket.total.title" defaultMessage="Total" />}
          />
        </div>
      )}
    </div>
  );
};

export default Content;
