import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'hooks';

import socketSlice from 'modules/sockets/reducer';

import { getCurrentStep } from 'modules/steps/selector';
import { getStepToRedirect, isModalDisplayed } from 'modules/sockets/selectors';

import { Button, Modal } from '../ui';

const SocketRedirectModal: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const ref = React.useRef<HTMLButtonElement>();

  const displayModal = useSelector(isModalDisplayed);
  const currentStep = useSelector(getCurrentStep);
  const stepToRedirect = useSelector(getStepToRedirect);

  const handleCloseModal = React.useCallback(() => {
    dispatch(socketSlice.actions.closeModal());

    if (stepToRedirect !== currentStep) {
      const urlParams = new URLSearchParams(location.search);
      urlParams.set('step', stepToRedirect);
      navigate({ ...location, search: urlParams.toString() }, { replace: true, state: location.state });
    }
  }, [location, currentStep, stepToRedirect, navigate, dispatch]);

  return displayModal && (
    <Modal
      open
      focusRef={ref}
      className="w-full max-w-md kiosk:max-w-4xl"
      title={(
        <h3 className="text-center">
          <FormattedMessage
            id="socket.modal.title"
            defaultMessage="We have updated your file."
          />
        </h3>
      )}
    >
      <div className="flex flex-col items-center mt-5">
        <Button
          ref={ref}
          testId="showMe"
          onClick={handleCloseModal}
          className="w-full mt-5"
        >
          <FormattedMessage id="socket.modal.confirm" defaultMessage="Continue" />
        </Button>
      </div>
    </Modal>
  );
};

export default SocketRedirectModal;
