import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Modal, Switch } from 'components/ui';
import { ModalProps } from 'components/ui/Modal';
import Button, { ButtonType } from 'components/ui/Button';
import { CookiesSettings } from 'modules/auth/types/InitialState';

import { DEFAULT_ENABLED_COOKIES, COOKIE_PANELS } from './config';

interface CookieSettingsProps extends ModalProps {
  onSubmit?: (settings: CookiesSettings[]) => void,
  defaultValue?: CookiesSettings[],
}

const CookieSettings: React.FC<CookieSettingsProps> = (
  { onSubmit, defaultValue = DEFAULT_ENABLED_COOKIES, ...modalProps },
) => {
  const acceptAllRef = React.useRef<HTMLButtonElement>();
  const [values, setValues] = React.useState(defaultValue);

  const handleOnChecked = React.useCallback((setting: CookiesSettings) => (checked: boolean) => {
    setValues((current) => {
      if (!checked) {
        return current.filter((value) => value !== setting);
      }
      return [...current, setting];
    });
  }, []);

  const handleAcceptAllOnClick = React.useCallback(() => {
    onSubmit(Object.values(CookiesSettings));
  }, [onSubmit]);

  const handleSavePreferencesOnClick = React.useCallback(() => {
    onSubmit(values);
  }, [onSubmit, values]);

  return (
    <Modal
      focusRef={acceptAllRef}
      className="w-full max-w-lg max-h-[95vh] flex flex-col"
      {...modalProps}
      title={(
        <span className="text-2xl text-default font-bold">
          <FormattedMessage id="cookies.settingsTitle" defaultMessage="Privacy preferences" />
        </span>
      )}
    >
      <div className="mt-8 max-h-1/2-screen overflow-y-auto scroll-smooth">
        {COOKIE_PANELS.map(({
          key, label, disabled, description,
        }, index) => {
          const isLast: boolean = index + 1 === COOKIE_PANELS.length;

          return (
            <div key={key} className={classNames({ 'border-b border-border-default pb-6 mb-6': !isLast })}>
              <Switch
                labelAfter
                size="small"
                disabled={disabled}
                data-testid={`switch-${key}`}
                checked={values.includes(key)}
                onChange={handleOnChecked(key)}
                className="justify-between mb-4 "
                labelClassName="!ml-0 text-grey-800 font-medium"
              >
                {label}
              </Switch>
              <p className="text-justify text-s">
                {description}
              </p>
            </div>
          );
        })}
      </div>

      <div className="mt-8">
        <Button ref={acceptAllRef} className="w-full" testId="accept-all" onClick={handleAcceptAllOnClick}>
          <FormattedMessage id="cookies.acceptAll" defaultMessage="Accept all" />
        </Button>
        <Button
          className="w-full mt-3"
          testId="save-preferences"
          type={ButtonType.SECONDARY}
          onClick={handleSavePreferencesOnClick}
        >
          <FormattedMessage id="cookies.savePreferences" defaultMessage="Save preferences" />
        </Button>
      </div>
    </Modal>
  );
};

export default CookieSettings;
