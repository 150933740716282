import React from 'react';
import classNames, { Argument } from 'classnames';
import { FormattedNumber } from 'react-intl';

import { useSelector } from 'hooks';
import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { useGetSelfServiceByIdQuery } from 'modules/selfServices/service';

const TotalLine: React.FC<{ title: React.ReactNode, price: number, className?: Argument }> = (
  { title, price, className },
) => {
  const selfServiceId = useSelector(getSelectedSelfServiceId);
  const { data: selfService } = useGetSelfServiceByIdQuery({ id: selfServiceId });

  return (
    <div className={classNames('flex justify-between items-center text-lowest text-xs font-medium', className)}>
      <div>{title}</div>
      <div><FormattedNumber value={price} style="currency" currency={selfService.dealer.currencyIsoCode} /></div>
    </div>
  );
};

export default TotalLine;
