import React from 'react';
import { useSelector } from 'react-redux';

import { shouldDisplayPhoneNumbers as shouldDisplayPhoneNumbersSelector } from 'modules/dealers/selectors';

import LanguageSelect from './LanguageSelect';
import HelpLineButton from './HelpLineButton';
import StepsHeader, { StepsHeaderProps } from './StepsHeader';

const PageHeader: React.FC<StepsHeaderProps & { hideHelpLine?: boolean }> = ({
  currentIndex,
  length,
  onPrev,
  hideSteps,
  hideHelpLine,
  hidePrevButton,
}) => {
  const shouldDisplayPhoneNumbers = useSelector(shouldDisplayPhoneNumbersSelector);
  const shouldDisplayHelpLine = !hideHelpLine && shouldDisplayPhoneNumbers;
  return (
    <div className="flex w-full justify-between z-30 px-7 kiosk:px-12 fixed bg-white/90 backdrop-blur-sm">
      <StepsHeader
        currentIndex={currentIndex}
        length={length}
        onPrev={onPrev}
        hideSteps={hideSteps}
        hidePrevButton={hidePrevButton}
      />
      <div className="w-14 kiosk:w-64 flex justify-end items-center gap-2">
        {shouldDisplayHelpLine && <HelpLineButton />}
        <LanguageSelect />
      </div>
    </div>
  );
};

export default PageHeader;
