import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { InboxIcon } from '@heroicons/react/24/solid';

const NoData: React.FC<{ label?: ReactNode }> = ({ label }) => (
  <div className="flex flex-col items-center justify-center">
    <InboxIcon className="h-16 w-16 text-input-bg" />
    {label ?? <FormattedMessage id="noData" defaultMessage="No data" tagName="p" />}
  </div>
);

export default NoData;
