import { createAction } from '@reduxjs/toolkit';

import { KioskOperationUpdate, KioskPaymentUpdate, SocketResponseUpdate } from 'modules/sockets/types/SocketResponse';

export const initialized = createAction('@SOCKET/INITIALIZED');

export const creationFailure = createAction<string>('@SOCKET/INIT/FAILURE');

export const selfServiceUpdated = createAction<SocketResponseUpdate>('@SOCKET/RECEIVE_MESSAGE/SELF_SERVICE_UPDATED');

export const kioskPaymentUpdated = createAction<KioskPaymentUpdate>('@SOCKET/RECEIVE_MESSAGE/KIOSK_PAYMENT_UPDATED');

export const kioskOperationUpdated = createAction<KioskOperationUpdate>(
  '@SOCKET/RECEIVE_MESSAGE/KIOSK_OPERATION_UPDATED',
);

export const socketError = createAction<string | Event | undefined>('@SOCKET/ERROR');

export const sendMessage = createAction<string | ArrayBufferLike | Blob | ArrayBufferView>('@SOCKET/SEND_MESSAGE');
