import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'hooks';
import ExtraStatus from 'types/ExtraStatus';
import FormKeys from 'modules/form/types/FormKeys';
import { isModalOpen } from 'modules/basket/selectors';
import { getSelectedSelfService } from 'modules/selfServices/selectors';

import type { FormValidator } from 'types/Form';
import type PageBaseProps from 'types/PageBase';
import type { ExtrasForm as ExtrasFormType } from 'types/ExtrasForm';

import Popup from '../ui/Popup';
import Step from './components/Step';
import Modal from './components/Modal';
import ConnectedForm from '../ui/ConnectedForm';

const Basket: React.FC<PageBaseProps> = ({
  onNext,
  onPrev,
  shouldDisplayBackButton,
}) => {
  const isBasketModalOpen = useSelector(isModalOpen);
  const selfService = useSelector(getSelectedSelfService);

  const [key, setKey] = React.useState(uuidv4());

  const validateForm: FormValidator<ExtrasFormType> = React.useCallback(({ basketConsent, basketGTC, extras = [] }) => {
    const hasAcceptedExtras = extras.some(({ status }) => status === ExtraStatus.ACCEPTED);
    const hasRepairOrder = selfService.repairOrder?.length > 0;
    const hasError = (hasAcceptedExtras || hasRepairOrder) && (!basketConsent || !basketGTC);
    return ({
      [FORM_ERROR]: hasError ? 'required' : undefined,
    });
  }, [selfService.repairOrder?.length]);

  const Component = React.useMemo(
    () => (typeof onNext === 'function' ? Step : Modal),
    [onNext],
  );

  // Use to refresh the form when it has been updated in the basket modal.
  React.useEffect(() => {
    if (!isBasketModalOpen) {
      setKey(uuidv4());
    }
  }, [isBasketModalOpen]);

  return (
    <React.Fragment key={key}>
      <ConnectedForm
        onPrev={onPrev}
        onSubmit={onNext}
        component={Component}
        validate={validateForm}
        formKey={FormKeys.EXTRAS}
        mutators={{ ...arrayMutators }}
        subscription={{ hasValidationErrors: true }}
        shouldDisplayBackButton={shouldDisplayBackButton}
        errorComponent={(
          <Popup
            title={(
              <FormattedMessage
                id="basket.mandatoryModal.title"
                defaultMessage="You must first consent and accept the general terms and conditions"
                tagName="h2"
              />
            )}
          />
        )}
      />
    </React.Fragment>
  );
};

export default Basket;
