import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, useIntl } from 'react-intl';

import { useSelector } from 'hooks';
import Popup from 'components/ui/Popup';
import PageBaseProps from 'types/PageBase';
import ExtraStatus from 'types/ExtraStatus';
import FormKeys from 'modules/form/types/FormKeys';
import { isModalOpen } from 'modules/basket/selectors';
import type { FormValidatorWithIntl } from 'types/Form';
import type { ExtrasForm as ExtrasFormType } from 'types/ExtrasForm';

import ConnectedForm from '../ui/ConnectedForm';
import ExtrasFormRender from './ExtrasFormRender';
import MandatoryWarning from '../MandatoryWarning';

export const validateForm: FormValidatorWithIntl<ExtrasFormType> = (intl) => (
  { extras = [] },
) => extras.reduce((acc, extra, index) => ({
  ...acc,
  ...(extra.mandatory && extra.status === ExtraStatus.PENDING && ({
    [`extras[${index}].error`]: intl.formatMessage({
      id: 'validation.required',
      defaultMessage: 'This field is mandatory',
    }),
  })),
}), {});

const ExtrasForm: React.FC<PageBaseProps> = ({ onNext, onPrev, shouldDisplayBackButton }) => {
  const intl = useIntl();
  const isBasketModalOpen = useSelector(isModalOpen);
  const [key, setKey] = React.useState(uuidv4());

  // Use to refresh the form when it has been updated in the basket modal.
  React.useEffect(() => {
    if (!isBasketModalOpen) {
      setKey(uuidv4());
    }
  }, [isBasketModalOpen]);

  return (
    <React.Fragment key={key}>
      <ConnectedForm
        onPrev={onPrev}
        onSubmit={onNext}
        formKey={FormKeys.EXTRAS}
        component={ExtrasFormRender}
        validate={validateForm(intl)}
        mutators={{ ...arrayMutators }}
        subscription={{ hasValidationErrors: true }}
        shouldDisplayBackButton={shouldDisplayBackButton}
        errorComponent={(
          <Popup title={<FormattedMessage id="extras.mandatoryModal.title" defaultMessage="You must first answer all mandatory extras" tagName="h2" />}>
            <MandatoryWarning />
          </Popup>
        )}
      />
    </React.Fragment>
  );
};

export default ExtrasForm;
