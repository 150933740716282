import React, { useImperativeHandle } from 'react';
import { Action } from 'history';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import PageBaseProps from 'types/PageBase';

import { logout } from 'modules/auth/actions';
import { setCurrentStep } from 'modules/steps/actions';

import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { shouldDisplayBasketModal } from 'modules/basket/selectors';
import { getSelectedSelfService } from 'modules/selfServices/selectors';

import useSteps, { Step, UseStepsData } from 'hooks/useSteps';
import { usePatchDropOffPointMutation } from 'modules/steps/service';

import PageHeader from '../PageHeader';
import { useDispatch, useSelector } from '../../../hooks';
import usePrompt, { ShouldPromptType } from '../../../hooks/usePrompt';

import 'flag-icons/css/flag-icons.min.css';

interface UIStepData {
  hideHeader?: boolean;
  hidePrevButton?: boolean;
  hideStepIndicator?: boolean;
  preventBrowserNavigation?: boolean;
}

export type UIStep<T = object> = Step<UIStepData & T, PageBaseProps>;

export interface StepsProps {
  steps: UIStep[];
  step?: UIStep | string;
  onChange?: (step: UIStep) => void;
  promptMessage?: string;
  ref?: React.Ref<Pick<UseStepsData<UIStep[], PageBaseProps>, 'setStep'>>;
}

const Steps: React.FC<StepsProps> = React.forwardRef(({
  step: initialStep, steps, onChange, promptMessage,
}, ref) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const selfServiceId = useSelector(getSelectedSelfServiceId);
  const selfService = useSelector(getSelectedSelfService);
  const displayBasketModal = useSelector(shouldDisplayBasketModal);

  const [patchDropOffPoint] = usePatchDropOffPointMutation();

  const {
    currentStep, next, previous, setStep,
  } = useSteps({
    initialStep, steps, onChange,
  });

  useImperativeHandle(ref, () => ({ setStep }));

  const visibleSteps = steps.filter(({ data }) => !data?.hideHeader);
  const currentIndex = visibleSteps.findIndex(({ key }) => key === currentStep.key);
  const shouldBlock = Boolean(currentStep.data?.preventBrowserNavigation);

  const isPenultimate = React.useMemo(() => {
    const penultimateStepKey = steps[steps.length - 2]?.key;
    return currentStep.key === penultimateStepKey;
  }, [currentStep?.key, steps]);

  const Component = React.useMemo(() => (
    'render' in currentStep ? currentStep.render : currentStep.component
    // @ts-expect-error 2339
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [currentStep.render, currentStep.component]);

  // Prompt when:
  // - The pathname of the following location is different ex: from checkin to login
  // - The current step prevent back and the following location step is the previous step ex:
  //   from step final instruction to key
  // - The current step prevent back and the flowing step doesn't exist:
  //   from step checkin/?step=customerInfo to login/?step=verify
  const shouldPrompt = React.useCallback<ShouldPromptType>((location, action) => {
    if (location.pathname === pathname) {
      const urlParams = new URLSearchParams(location.search);
      const nextLocationStep = steps.find(({ key }) => key === urlParams.get('step'));
      return action === Action.Pop
          && currentStep.data?.preventBrowserNavigation
          && (!nextLocationStep || nextLocationStep.next === currentStep.key);
    }

    return action === Action.Pop;
  }, [currentStep, pathname, steps]);
  const callback = React.useCallback(() => dispatch(logout()), [dispatch]);

  usePrompt(promptMessage, shouldPrompt, shouldBlock, callback);

  React.useEffect(() => {
    window.scrollTo(0, 1);

    if (currentStep.apiKey) {
      patchDropOffPoint({ step: currentStep.apiKey, selfServiceId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep.key]);

  React.useEffect(() => {
    // Initialise Step
    dispatch(setCurrentStep(currentStep.key));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const titleMessage = React.useMemo(() => {
    if (currentStep.title) {
      return typeof currentStep.title === 'function' ? currentStep.title(selfService) : currentStep.title;
    }
    return undefined;
  }, [currentStep, selfService]);

  return (
    <>
      {!currentStep.data?.hideHeader && (
        <PageHeader
          onPrev={previous}
          currentIndex={currentIndex}
          length={visibleSteps.length}
          displayBasket={displayBasketModal}
          hideSteps={currentStep.data?.hideStepIndicator}
          hidePrevButton={currentStep.data?.hidePrevButton}
          title={titleMessage ? <FormattedMessage {...titleMessage} /> : undefined}
        />
      )}
      <Component
        onNext={next}
        onPrev={previous}
        isPenultimate={isPenultimate}
        shouldDisplayBackButton={currentIndex > 0}
      />
    </>
  );
});

export default Steps;
