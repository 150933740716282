import React from 'react';

import Link, { LinkProps } from '../components/ui/Link';

export const getHyperlink = (props: LinkProps) => (
  (chunks: React.ReactNode) => (
    <Link {...props}>
      {chunks}
    </Link>
  )
);
