import { createDraftSafeSelector } from '@reduxjs/toolkit';

import ParkingForm from 'types/ParkingForm';
import { YesNoChoice } from 'types/Question';
import { ExtrasForm } from 'types/ExtrasForm';
import { QuestionsForm } from 'types/QuestionsForm';
import { RepairOrderForm } from 'types/RepairOrderForm';
import { VehicleCheckForm } from 'types/VehicleCheckForm';
import { MobilityFormType } from 'types/MobilityDocumentFormType';
import { SelfService, SelfServiceType } from 'modules/selfServices/types/SelfService';

import formAdapter from 'modules/form/adapter';

import { getMFAData } from 'modules/auth/selectors';

import { VehicleCheckType } from 'modules/vehicleCheck/types/VehicleCheckModel';

import type { RootState } from '../../App/Store';

import { FormState } from './types/FormState';
import { BreakdownInfo } from '../../types/BreakdownInfo';
import { SignatureForm } from '../../types/SignatureForm';

const getProps = <T>(_: RootState, props: T) => props;

export const getState = (state: RootState) => state.form;

export const adapterSelector = formAdapter.getSelectors<RootState>(getState);

export const getFormValuesByKey = createDraftSafeSelector(adapterSelector.selectById, (form) => form?.values);

type SavedFormType = {
  repairOrder: FormState<RepairOrderForm>;
  questions: FormState<QuestionsForm>;
  extras: FormState<ExtrasForm>;
  parking: FormState<ParkingForm>;
  signature: FormState<SignatureForm>;
  vehicleCheck: FormState<VehicleCheckForm>;
  mobilityParking: FormState<ParkingForm>;
  mobility: FormState<MobilityFormType>;
  mobilityContract: FormState<string>;
  breakdownInfo: FormState<BreakdownInfo>;
};

export const getFormattedFormData = createDraftSafeSelector(
  adapterSelector.selectEntities,
  getProps,
  (forms, selfService: SelfService): Partial<SelfService> => {
    const { type } = selfService;
    if (type === SelfServiceType.CHECK_IN || type === SelfServiceType.BREAKDOWN) {
      // Formatters
      const {
        repairOrder, questions, extras, parking, signature, vehicleCheck, mobility, mobilityContract, breakdownInfo,
      } = forms as Partial<SavedFormType>;

      const hasParkingData = parking?.values?.spotNumber || parking?.values?.x >= 0;

      return {
        ...repairOrder?.values,
        ...(questions?.values && { questionForm: { questions: questions.values.questions } }),
        ...(selfService.checklist?.items?.[0] && {
          checklist: {
            ...selfService.checklist,
            items: [{
              ...selfService.checklist.items[0],
              advices: extras?.values?.extras,
            }],
          },
        }),
        ...(hasParkingData && { parking: parking.values }),
        ...(mobility?.values && {
          mobility: {
            ...mobility.values,
            documents: mobility.values.documents?.map(({ media, ...document }) => ({
              ...document,
              media: mobility.values.customerAccepted ? media : undefined,
            })),
            contract: {
              ...mobility.values.contract,
              media: {
                ...mobility.values.contract?.media,
                uploadId: mobilityContract?.values,
              },
            },
          },
        }),
        signature: { uploadId: signature?.values?.uploadId },
        breakdownServiceEmail: signature?.values?.breakdownServiceEmail,
        vehicleCheckAccepted: vehicleCheck?.values?.vehicleCheck === YesNoChoice.YES,
        courtesyVehicleCheckAccepted: vehicleCheck?.values?.courtesyVehicleCheck === YesNoChoice.YES,
        status: selfService.status,
        type: selfService.type,
        ...(breakdownInfo?.values && breakdownInfo.values),
      };
    }
    if (type === SelfServiceType.CHECK_OUT) {
      const { mobilityParking } = forms as Partial<SavedFormType>;
      const hasParkingData = mobilityParking?.values?.spotNumber || mobilityParking?.values?.x >= 0;

      return hasParkingData ? { mobility: { vehicle: { parkingReturn: mobilityParking.values } } } : {};
    }
    return {};
  },
);

const getVehicleCheckForm = createDraftSafeSelector(
  adapterSelector.selectEntities,
  ({ vehicleCheck }) => vehicleCheck,
);
const getVehicleCheckFormValues = createDraftSafeSelector(
  getVehicleCheckForm,
  (vehicleCheckForm) => vehicleCheckForm?.values,
);

export const isVehicleCheckAccepted = createDraftSafeSelector(
  getVehicleCheckFormValues,
  (vehicleCheckFormValues) => vehicleCheckFormValues?.vehicleCheck === YesNoChoice.YES,
);

export const isCourtesyVehicleCheckAccepted = createDraftSafeSelector(
  getVehicleCheckFormValues,
  (vehicleCheckFormValues) => vehicleCheckFormValues?.courtesyVehicleCheck === YesNoChoice.YES,
);

export const isCourtesyVehicleCheckRefused = createDraftSafeSelector(
  getVehicleCheckFormValues,
  (vehicleCheckFormValues) => vehicleCheckFormValues?.courtesyVehicleCheck === YesNoChoice.NO,
);

export const getAcceptedVehicleChecks = createDraftSafeSelector(
  isVehicleCheckAccepted,
  isCourtesyVehicleCheckAccepted,
  (vehicleCheckAccepted, courtesyVehicleCheckAccepted) => [
    ...(vehicleCheckAccepted ? [VehicleCheckType.PERSONAL] : []),
    ...(courtesyVehicleCheckAccepted ? [VehicleCheckType.COURTESY] : []),
  ],
);

const getVehicleCheckConfig = createDraftSafeSelector(
  getMFAData,
  isVehicleCheckAccepted,
  isCourtesyVehicleCheckAccepted,
  (mfaData, vehicleCheckAccepted, courtesyVehicleCheckAccepted) => ({
    vehicleCheckEnabled: mfaData?.vehicleCheckEnabled ?? false,
    vehicleCheckAccepted,
    courtesyVehicleCheckEnabled: mfaData?.mobility?.courtesyVehicleCheckEnabled ?? false,
    courtesyVehicleCheckAccepted,
  }),
);

const getMobilityForm = createDraftSafeSelector(
  adapterSelector.selectEntities,
  ({ mobility }) => mobility,
);

const getMobilityFormValues = createDraftSafeSelector(
  getMobilityForm,
  (mobilityForm) => mobilityForm?.values,
);

export const isMobilityAccepted = createDraftSafeSelector(
  getMobilityFormValues,
  (mobility) => mobility?.customerAccepted,
);

export const getStepsConfig = createDraftSafeSelector(
  getVehicleCheckConfig,
  isMobilityAccepted,
  (config, hasAcceptedMobility) => ({ ...config, hasAcceptedMobility }),
);
