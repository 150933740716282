import api from 'service/index';
import { TRANSLATIONS } from 'constants/url';
import type { I18nCollection } from 'modules/translations/types/InitialState';
import type { TranslationRequestParams } from 'modules/translations/types/TranslationsRequest';

export const translationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLanguages: builder.query<Array<string>, void>({
      query: () => `${new URL(process.env.BASE_URL).origin}/refdata/languages`,
    }),
    getTranslationsByLanguage: builder.mutation<
    I18nCollection,
    TranslationRequestParams
    >({
      query: (params) => ({
        method: 'GET',
        url: TRANSLATIONS,
        params,
      }),
    }),
  }),
});

export const { useGetTranslationsByLanguageMutation, useGetLanguagesQuery } = translationsApi;

export default translationsApi;
