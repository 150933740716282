import Label from './Label';

export enum YesNoChoice {
  YES = 'YES',
  NO = 'NO',
}

export enum QuestionType {
  YES_NO = 'YES_NO',
}

interface GenericQuestion {
  id: string;
  description: Label;
  mandatory: boolean;
  title: Label;
  type: QuestionType;
  response?: unknown;
  comment?: string;
}

export interface YesNoQuestion extends GenericQuestion {
  type: QuestionType.YES_NO;
  response: YesNoChoice;
}

export default GenericQuestion;
