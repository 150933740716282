import { PhoneNumber } from 'modules/selfServices/types/SelfService';

export enum RequestedWorks {
  BRAKES = 'BRAKES',
  ENGINE = 'ENGINE',
  EXHAUST_SYSTEM = 'EXHAUST_SYSTEM',
  FUEL = 'FUEL',
  IGNITION = 'IGNITION',
  INSPECTION = 'INSPECTION',
  MAINTENANCE_SERVICE = 'MAINTENANCE_SERVICE',
  OIL = 'OIL',
  OTHER = 'OTHER',
}

export enum Reason {
  ACCIDENT = 'ACCIDENT',
  BREAKDOWN = 'BREAKDOWN',
  TIRE_WHEEL_ISSUE = 'TIRE_WHEEL_ISSUE',
  WILL_NOT_START = 'WILL_NOT_START',
}

export interface BreakdownInfo {
  breakdownServiceComment?: string;
  breakdownServiceReason?: Reason,
  breakdownServiceRequestedWorks?: RequestedWorks[],
  customerFirstName?: string,
  customerLastName?: string,
  customerPhoneNumber?: PhoneNumber,
}
