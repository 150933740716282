import React from 'react';
import debounce from 'debounce';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

import { b } from 'utils/i18nUtils';
import { login } from 'modules/auth/thunk';
import PageBaseProps from 'types/PageBase';
import { useDispatch, useSelector } from 'hooks';
import { setLoginData, setNotFound } from 'modules/auth/actions';
import { useGetContextQuery } from 'modules/dealers/service';
import { ErrorTypeEnum, isApiErrorResponse } from 'types/Error';
import { ConnectionTypeLogin } from 'modules/dealers/types/ContextResponse';
import {
  getLoginData,
  isLoadingLogin,
  hasLocationIdError as hasLocationIdErrorSelector,
  hasRegistrationError as hasRegistrationErrorSelector,
  getNotFoundSelfService,
} from 'modules/auth/selectors';
import { getConnectionType, isBreakdownAvailable } from 'modules/dealers/selectors';
import { isFlowTypeModalVisible, isSelfServiceLoading as isSelfServiceLoadingSelector } from 'modules/selfServices/selectors';

import {
  FlowTypeModal, Input, PageHeader, Switch,
} from 'components/ui';
import Footer from 'components/ui/PageFooter';

import { LoginData } from 'modules/auth/types/InitialState';
import { PhoneNumber, SelfServiceType } from 'modules/selfServices/types/SelfService';

import LoginTerms from './LoginTerms';
import Warning from '../../assets/icons/warning.svg';
import PhoneNumberInput from '../ui/PhoneNumberInput';
import BreakDownInformation from './BreakDownInformation';
import BreakdownRedirectionModal from './BreakdownRedirectionModal';

const PLATE_NUMBER_VALIDATION_PATTERN = /^[a-zA-Z0-9 !?@#$&\-.+=:*,/"'\u00C0-\u024F\u1E00-\u1EFF]{1,50}$/;

const validatePlateNumber = (value: string, isBreakdown: boolean, intl: IntlShape) => {
  if (!value) {
    return intl.formatMessage({ id: 'validation.required', defaultMessage: 'This field is mandatory' });
  }

  if (isBreakdown && !PLATE_NUMBER_VALIDATION_PATTERN.test(value)) {
    return intl.formatMessage({
      id: 'validation.plateNumber',
      defaultMessage: 'The license plate should only contain alphanumeric characters and symbols',
    });
  }

  return undefined;
};

const Login: React.FC<PageBaseProps> = ({ onNext, onPrev }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: contextData } = useGetContextQuery();
  const { search, state: pageState } = useLocation();

  const loginData = useSelector(getLoginData);
  const isLoading = useSelector(isLoadingLogin);
  const connectionType = useSelector(getConnectionType);
  const breakdownAvailable = useSelector(isBreakdownAvailable);
  const isFlowTypeModalOpen = useSelector(isFlowTypeModalVisible);
  const isNotFoundSelfService = useSelector(getNotFoundSelfService);
  const hasLocationIdError = useSelector(hasLocationIdErrorSelector);
  const hasRegistrationError = useSelector(hasRegistrationErrorSelector);
  const isSelfServiceLoading = useSelector(isSelfServiceLoadingSelector);

  const {
    registration = '', phoneNumber, dealerId, brandId, locationId, isDriver,
  } = loginData;
  const { confidentialityPolicyLink } = contextData ?? {};

  const [hasPhoneError, setHasPhoneError] = React.useState(false);
  const [areTermsAccepted, setTermsAccepted] = React.useState(false);
  const [plateNumberError, setPlateNumberError] = React.useState<string>();
  const [inputLocationId, setInputLocationId] = React.useState<number>(locationId);

  const isAtDealership = pageState?.atDealership;
  const displayLocationIdInput = isAtDealership && !locationId;
  const isCheckin = pageState?.type === SelfServiceType.CHECK_IN;
  const isBreakdown = pageState?.type === SelfServiceType.BREAKDOWN;
  const hasExistingLocationId = isAtDealership && Boolean(locationId);
  const showRedirectionModal = isCheckin && breakdownAvailable && isNotFoundSelfService;
  const showPolicies = connectionType === ConnectionTypeLogin.TOKEN && Boolean(confidentialityPolicyLink);
  const hasNoPolicyOrAgreedToPolicies = !confidentialityPolicyLink || (showPolicies && areTermsAccepted);

  const disabled = !hasNoPolicyOrAgreedToPolicies
    || (registration.length < 1 || Boolean(plateNumberError))
    || (isBreakdown && (hasPhoneError || !phoneNumber?.local))
    || (isAtDealership && (!inputLocationId && !locationId));

  const loginLabel = React.useMemo(() => (isBreakdown ? ({
    id: 'login.askPlateBreakdown',
    defaultMessage: 'Please enter your phone number and the license plate of the vehicle.',
  }) : ({
    id: 'login.askLicensePlate',
    defaultMessage: 'Please enter the license plate of your vehicle in order to access your appointment.',
  })), [isBreakdown]);

  const handleChangeLoginData = React.useCallback((data: Partial<LoginData>) => {
    dispatch(setLoginData({ ...loginData, ...data }));
  }, [dispatch, loginData]);

  const toggleAcceptTerms = React.useCallback(() => setTermsAccepted((checked) => !checked), []);

  const handleInputPlateChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    handleChangeLoginData({ registration: value.toUpperCase() });
    setPlateNumberError(validatePlateNumber(value, isBreakdown, intl));
  }, [intl, handleChangeLoginData, isBreakdown]);

  const handleLocationIdChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputLocationId(parseInt(value, 10));
  }, []);

  const handlePhoneNumberChange = React.useCallback(
    (newPhoneNumber: PhoneNumber) => handleChangeLoginData({ phoneNumber: newPhoneNumber }),
    [handleChangeLoginData],
  );

  const setIsDriver = React.useCallback(
    (value: boolean) => handleChangeLoginData({ isDriver: value }),
    [handleChangeLoginData],
  );

  const handleOnPrev = React.useCallback(() => {
    if (isBreakdown) {
      onPrev();
    } else {
      navigate(`/${search}`);
    }
  }, [isBreakdown, navigate, onPrev, search]);

  const handleNextClick = async () => {
    try {
      await dispatch(login({
        connectionType,
        type: pageState?.type,
        registration,
        locationId: isAtDealership ? inputLocationId : undefined,
        ...(isBreakdown ? {
          brandId,
          dealerId,
          phoneNumber,
          isDriver,
        } : {}),
      })).unwrap();

      if (connectionType === ConnectionTypeLogin.MFA) {
        onNext();
      }
    } catch (error) {
      if (isApiErrorResponse(error)) {
        // If error is MFA_CODE_TIMEOUT (cannot resend until timeout), we go to next page anyway: BMWVID-15237
        // If error is SO_WRONG_KIOSK, we go to next page to show instructions
        if (error.data.errorType === ErrorTypeEnum.MFA_CODE_TIMEOUT) {
          onNext();
        } else if (error.data.errorType === ErrorTypeEnum.SO_WRONG_KIOSK) {
          navigate(`/wrongKiosk${search}`);
        }
      }
    }
  };

  const handleKeyDown = debounce((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !disabled && !isLoading) {
      handleNextClick();
    }
  }, 200);

  const handleHideRedirectionModal = React.useCallback(() => dispatch(setNotFound(false)), [dispatch]);

  return (
    <>
      <PageHeader
        hideSteps
        onPrev={handleOnPrev}
        title={<FormattedMessage id="login.title" defaultMessage="Login" />}
      />
      <div className="main-content">
        <div className="content !m-auto">
          {hasExistingLocationId && (
            <div className="w-full p-4 bg-card-bg rounded-full flex items-center gap-x-4 border border-brand-high">
              <div>
                <div className="flex items-center justify-center size-10 bg-brand-high rounded-full">
                  <Warning className="fill-white size-6" />
                </div>
              </div>
              <p className="text-s">
                <FormattedMessage
                  values={{
                    b,
                    locationId,
                  }}
                  id="login.existingLocationId"
                  // eslint-disable-next-line max-len
                  defaultMessage="Your keys are in the kiosk {locationId}. Please present yourself there before proceeding."
                />
              </p>
            </div>
          )}
          <p className="text-center">
            <FormattedMessage {...loginLabel} />
          </p>
          {isBreakdown && (
            <>
              <PhoneNumberInput
                required
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                className="mt-10 pb-5 kiosk:mt-20 kiosk:pb-10 kioskSharebox:mt-10 kioskSharebox:pb-5 !mb-0"
                onError={setHasPhoneError}
              />
              <Switch
                size="small"
                checked={isDriver}
                onChange={setIsDriver}
                data-testid="switch-isBreakdown"
                className="border-b-2 pb-10 kioskSharebox:pb-10 kiosk:pb-20"
              >
                <FormattedMessage id="login.askBreakdownService" defaultMessage="I am the driver" />
              </Switch>
              <h3 className="mt-7 kiosk:mt-14 kioskSharebox:mt-7 text-center">
                <FormattedMessage id="login.vehicleInformation" defaultMessage="Vehicle information" />
              </h3>
            </>
          )}
          <Input
            className="mt-4"
            value={registration}
            center={!isBreakdown}
            onKeyDown={handleKeyDown}
            labelClassName={!isBreakdown && 'text-center'}
            onChange={handleInputPlateChange}
            label={intl.formatMessage({
              id: 'login.licensePlate',
              defaultMessage: 'License plate',
            })}
            autoCorrect="off"
            spellCheck="false"
            error={plateNumberError || hasRegistrationError}
            data-testid="plateNumber-input"
            maxLength={50}
          />
          {displayLocationIdInput && (
            <div>
              <Input
                center
                value={inputLocationId}
                onKeyDown={handleKeyDown}
                error={hasLocationIdError}
                labelClassName="text-center"
                onChange={handleLocationIdChange}
                label={intl.formatMessage({
                  id: 'login.locationId',
                  defaultMessage: 'Location ID',
                })}
                autoCorrect="off"
                spellCheck="false"
                type="number"
                inputMode="numeric"
                data-testid="locationId-input"
              />
              <p className="text-center italic text-sm text-default mt-3">
                <FormattedMessage
                  id="login.locationIdHint"
                  defaultMessage="Location ID is found on the sticker on the kiosk, 3 or 4 digits."
                />
              </p>
            </div>
          )}
          {showPolicies && (
            <LoginTerms
              isChecked={areTermsAccepted}
              onAccept={toggleAcceptTerms}
              confidentialityPolicyLink={confidentialityPolicyLink}
            />
          )}

          {isBreakdown && (
            <BreakDownInformation onChange={handleChangeLoginData} dealerId={dealerId} brandId={brandId} />
          )}
        </div>

        <Footer
          disabled={disabled}
          onPrev={handleOnPrev}
          shouldDisplayBackButton
          onNext={handleNextClick}
          loading={isLoading || isSelfServiceLoading}
        />
      </div>
      {isFlowTypeModalOpen && <FlowTypeModal />}
      {showRedirectionModal && (
        <BreakdownRedirectionModal plateNumber={registration} onCancel={handleHideRedirectionModal} />
      )}
    </>
  );
};

export default Login;
