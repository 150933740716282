import React from 'react';
import { FormattedMessage } from 'react-intl';

import { b, br } from 'utils/i18nUtils';

import { Modal } from 'components/ui';
import { VehicleCheckType } from 'modules/vehicleCheck/types/VehicleCheckModel';

const SendingModal: React.FC<{ open: boolean, type: VehicleCheckType }> = ({ open, type }) => (
  <Modal
    open={open}
    className="w-full max-w-lg"
    title={(
      <span className="flex justify-center items-center">
        <FormattedMessage
          id="page.vehicleCheck.modal.sending.title"
          defaultMessage="Sending"
          tagName="h2"
        />
      </span>
    )}
  >
    <div className="flex flex-col items-center text-center mt-5">
      <FormattedMessage
        id={`page.vehicleCheck.modal.sending.description_${type}`}
        defaultMessage={`Your ${type.toLowerCase()} vehicle check is <b>being sent.</b>{linebreak}You will then <b>be redirected to the final instructions.</b>`}
        tagName="p"
        values={{ b, linebreak: br }}
      />
      <span className="loader" />
    </div>
  </Modal>
);

export default SendingModal;
