import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { Brand } from 'types/Brand';
import { RootState } from 'App/Store';
import { PoliciesEnum } from 'modules/auth/types/Policies';
import { SelfServiceType, SelfServiceOrigin } from 'modules/selfServices/types/SelfService';

import { HelpLinePhoneNumber } from './types/HelpLinePhoneNumber';
import { ContextResponse, Dealers } from './types/ContextResponse';

const getState = (state: RootState) => state.dealers;

const getProps = (_: RootState, props: string) => props;

export const getContext = createDraftSafeSelector(
  getState,
  ({ context }) => context,
);
export const getOrigin = createDraftSafeSelector(
  getContext,
  (context) => context?.origin,
);
export const getDealerCustomerLanguage = createDraftSafeSelector(
  getContext,
  (context) => context?.dealerCustomerLanguage,
);

export const getSelfServiceType = createDraftSafeSelector(
  getContext,
  (context) => context?.type,
);

export const getLoginToken = createDraftSafeSelector(
  getContext,
  (context) => context?.token,
);

export const getConnectionType = createDraftSafeSelector(
  getContext,
  (context) => context?.connectionType,
);

export const isCheckoutSelfService = createDraftSafeSelector(
  getSelfServiceType,
  (type) => type === SelfServiceType.CHECK_OUT,
);

export const isCheckinSelfService = createDraftSafeSelector(
  getSelfServiceType,
  (type) => type === SelfServiceType.CHECK_IN,
);

export const isBreakdownSelfService = createDraftSafeSelector(
  getSelfServiceType,
  (type) => type === SelfServiceType.BREAKDOWN,
);

export const isPublicDevice = createDraftSafeSelector(
  getContext,
  (context) => context?.isPublic || context?.origin === SelfServiceOrigin.KIOSK,
);

export const isKioskOrigin = createDraftSafeSelector(
  getContext,
  (context) => context?.origin === SelfServiceOrigin.KIOSK,
);

export const hasClickableInstruction = createDraftSafeSelector(
  getOrigin,
  isPublicDevice,
  isCheckinSelfService,
  isCheckoutSelfService,
  (origin, isDealerDevice, isCheckin, isCheckout) => (isCheckin || isCheckout)
    && (origin === SelfServiceOrigin.HOME || (origin === SelfServiceOrigin.ON_SITE && !isDealerDevice)),
);

export const getSelfServicePhoneNumber = createDraftSafeSelector(
  getContext,
  (context) => context?.selfServicePhoneNumber,
);

export const getKioskDealers = createDraftSafeSelector(getContext, (context) => context?.kioskDealers ?? []);

export const getKioskDealersWithBreakdown = createDraftSafeSelector(
  getKioskDealers,
  (dealers) => dealers.filter(
    (dealer: Dealers) => Boolean(dealer.selfServiceAvailableTypes[SelfServiceType.BREAKDOWN]),
  ),
);

export const isBreakdownAvailable = createDraftSafeSelector(
  getContext,
  getKioskDealersWithBreakdown,
  (context, dealers) => (context?.kioskId
    ? dealers.length > 0
    : Boolean(context?.selfServiceAvailableTypes?.[SelfServiceType.BREAKDOWN])),
);

export const getBrands = createDraftSafeSelector(getContext, (context) => context?.brands);

export const getDealerId = createDraftSafeSelector(
  getContext,
  getKioskDealersWithBreakdown,
  (context, kioskDealers) => context?.id ?? kioskDealers?.[0]?.id,
);

export const getBrandsByDealerId = createDraftSafeSelector(
  getKioskDealersWithBreakdown,
  getBrands,
  getProps,
  (kioskDealers, brands, dealerId) => kioskDealers?.find(({ id }: Dealers) => id === dealerId)?.brands ?? brands,
);

export const getDefaultBrandId = createDraftSafeSelector(
  getBrandsByDealerId,
  (brands) => brands?.[0]?.id,
);

export const getPrefix = createDraftSafeSelector(
  getContext,
  (context) => context?.dealerCountry?.prefix,
);

export const getPrefixByDealerId = createDraftSafeSelector(
  getKioskDealersWithBreakdown,
  getPrefix,
  getProps,
  (kioskDealers, prefix, dealerId) => prefix ?? kioskDealers?.find(
    ({ id }: Dealers) => id === dealerId,
  )?.dealerCountry?.prefix,
);

export const getFormattedBrands = createDraftSafeSelector(
  getBrandsByDealerId,
  (brands: Array<Brand>) => brands?.reduce<Record<string, string>>(
    (acc, { name, id }) => ({ ...acc, [id]: name }),
    {},
  ),
);

export const getFormattedDealers = createDraftSafeSelector(
  getKioskDealersWithBreakdown,
  (dealers: Array<Dealers>) => dealers?.reduce<Record<string, string>>(
    (acc, { dealerName, id }) => ({ ...acc, [id]: dealerName }),
    {},
  ),
);

export const getHelpLinePhoneNumbers = createDraftSafeSelector(getContext, (context: ContextResponse) => {
  if (context?.selfServicePhoneNumber) {
    return [{ selfServicePhoneNumber: context.selfServicePhoneNumber }] as HelpLinePhoneNumber[];
  }

  if (!context?.dealerName && context?.kioskDealers) {
    return Object.values(
      context.kioskDealers.reduce((acc, dealer) => {
        if (dealer?.selfServicePhoneNumber) {
          acc[dealer.id] = { dealerName: dealer.dealerName, selfServicePhoneNumber: dealer.selfServicePhoneNumber };
        }
        return acc;
      }, {} as Record<string, HelpLinePhoneNumber>),
    );
  }

  return [];
});

export const shouldDisplayPhoneNumbers = createDraftSafeSelector(
  getContext,
  getHelpLinePhoneNumbers,
  (context, phoneNumbers) => SelfServiceOrigin.KIOSK === context?.origin && phoneNumbers.length > 0,
);

export const isKioskFull = createDraftSafeSelector(
  getContext,
  (context) => context?.origin === SelfServiceOrigin.KIOSK && !context.isAvailable,
);

export const getPolicies = createDraftSafeSelector(
  getContext,
  ({ generalTermsConditionsLink, confidentialityPolicyLink }) => [
    ...(generalTermsConditionsLink
      ? [{ link: generalTermsConditionsLink, type: PoliciesEnum.GENERAL_TERMS_CONDITION }]
      : []
    ),
    ...(confidentialityPolicyLink
      ? [{ link: confidentialityPolicyLink, type: PoliciesEnum.CONFIDENTIALITY_POLICY }]
      : []
    ),
  ],
);
