import Flow from 'types/Flow';
import { FlowType, SelfService } from 'modules/selfServices/types/SelfService';
import { SelfServiceAPISteps, SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import VehicleCheckStep from 'components/VehicleCheckStep';
import Instructions from 'components/VehicleCheckStep/Instructions';

import {
  CommonCheckInSteps, BASKET as HOME_BASKET, CUSTOMER_INFO, EXTRAS, FINAL_INSTRUCTIONS, KIOSK_USAGE, QUESTIONS, REPAIR_ORDER,
} from './home';
import { PARKING, SIGNATURE } from '../common';
import type { SelfServiceStep } from '../../types';
import { isNotAnswered, isClassicCheckIn, isVisibleStep } from '../utils';
import CourtesyVehicleStep from '../../../CourtesyVehicle/CourtesyVehicleStep';

const COURTESY_VEHICLE: SelfServiceStep = {
  key: SelfServiceSteps.COURTESY_VEHICLE,
  apiKey: SelfServiceAPISteps.COURTESY_CAR_INFO,
  component: CourtesyVehicleStep,
  title: {
    id: 'courtesyVehicle.title',
    defaultMessage: 'Courtesy vehicle',
  },
  previous: KIOSK_USAGE.key,
  next: PARKING.key,
  data: {
    isVisible: (
      { status, mobility, mobilityEnabled },
      { hasAcceptedMobility },
    ) => (mobilityEnabled || hasAcceptedMobility) && isNotAnswered(status) && Boolean(mobility?.vehicle),
  },
};

export const VEHICLE_CHECK: SelfServiceStep = {
  key: SelfServiceSteps.VEHICLE_CHECK,
  apiKey: SelfServiceAPISteps.VEHICLE_CHECK_QUESTION,
  component: VehicleCheckStep,
  title: {
    id: 'vehicleCheck.selfService.title',
    defaultMessage: 'Vehicle check',
  },
  data: {
    isVisible: (
      { status },
      { vehicleCheckEnabled, courtesyVehicleCheckEnabled },
    ) => isNotAnswered(status) && (vehicleCheckEnabled || courtesyVehicleCheckEnabled),
  },
};

export const VEHICLE_CHECK_QR_CODE: SelfServiceStep = {
  key: SelfServiceSteps.VEHICLE_CHECK_QR_CODE,
  apiKey: SelfServiceAPISteps.GUIDED_VEHICLE_CHECK,
  component: Instructions,
  title: {
    id: 'page.vehicleCheck.qrCodeStep.title',
    defaultMessage: 'Guided vehicle check',
  },
  data: {
    hideStepIndicator: true,
    hidePrevButton: true,
    isVisible: ({
      vehicleCheckAccepted,
      vehicleCheck,
      courtesyVehicleCheckAccepted,
      courtesyVehicleCheck,
    }, {
      flowType,
      vehicleCheckEnabled,
      vehicleCheckAccepted: configVehicleCheckAccepted,
      courtesyVehicleCheckEnabled,
      courtesyVehicleCheckAccepted: configCourtesyVehicleCheckAccepted,
    }) => {
      let isVisible = false;

      if (vehicleCheckEnabled) {
        isVisible = (vehicleCheckAccepted || configVehicleCheckAccepted) && !vehicleCheck;
      }

      if (!isVisible && courtesyVehicleCheckEnabled) {
        isVisible = (courtesyVehicleCheckAccepted || configCourtesyVehicleCheckAccepted) && !courtesyVehicleCheck;
      }

      return flowType !== FlowType.QUICK && isVisible;
    },
  },
};

export const BASKET: SelfServiceStep = {
  ...HOME_BASKET,
  data: {
    ...HOME_BASKET.data,
    isVisible: (selfService, config) => isClassicCheckIn(selfService, config) && isVisibleStep(HOME_BASKET, selfService, config),
  },
};

export type CommonOnSiteKioskCheckInSteps =
  SelfServiceSteps.CUSTOMER_INFO
  | SelfServiceSteps.REPAIR_ORDER
  | SelfServiceSteps.EXTRAS
  | SelfServiceSteps.QUESTIONS
  | SelfServiceSteps.KIOSK_USAGE
  | SelfServiceSteps.COURTESY_VEHICLE
  | SelfServiceSteps.PARKING
  | SelfServiceSteps.BASKET
  | SelfServiceSteps.VEHICLE_CHECK;

export const COMMON_ON_SITE_KIOSK_CHECKIN_STEPS: Flow<CommonOnSiteKioskCheckInSteps> = {
  CUSTOMER_INFO: {
    ...CUSTOMER_INFO,
    data: {
      ...CUSTOMER_INFO.data,
      isVisible: (selfService, config) => isClassicCheckIn(selfService, config) && isVisibleStep(CUSTOMER_INFO, selfService, config),
    },
  },
  REPAIR_ORDER: {
    ...REPAIR_ORDER,
    data: {
      ...REPAIR_ORDER.data,
      isVisible: (selfService, config) => isClassicCheckIn(selfService, config) && isVisibleStep(REPAIR_ORDER, selfService, config),
    },
  },
  EXTRAS: {
    ...EXTRAS,
    data: {
      ...EXTRAS.data,
      isVisible: (selfService, config) => isClassicCheckIn(selfService, config) && isVisibleStep(EXTRAS, selfService, config),
    },
  },
  QUESTIONS: {
    ...QUESTIONS,
    previous: EXTRAS.key,
    next: KIOSK_USAGE.key,
    data: {
      ...QUESTIONS.data,
      isVisible: (selfService, config) => isClassicCheckIn(selfService, config) && isVisibleStep(QUESTIONS, selfService, config),
    },
  },
  KIOSK_USAGE: {
    ...KIOSK_USAGE,
    previous: QUESTIONS.key,
    next: COURTESY_VEHICLE.key,
    data: {
      ...KIOSK_USAGE.data,
      isVisible: (selfService, config) => isClassicCheckIn(selfService, config) && isVisibleStep(KIOSK_USAGE, selfService, config),
    },
  },
  COURTESY_VEHICLE: {
    ...COURTESY_VEHICLE,
    data: {
      ...COURTESY_VEHICLE.data,
      isVisible: (selfService, config) => isClassicCheckIn(selfService, config) && isVisibleStep(COURTESY_VEHICLE, selfService, config),
    },
  },
  PARKING: {
    ...PARKING,
    previous: COURTESY_VEHICLE.key,
    next: VEHICLE_CHECK.key,
    data: {
      ...PARKING.data,
      isVisible: (selfService, config) => isClassicCheckIn(selfService, config) && isVisibleStep(PARKING, selfService, config),
    },
  },
  VEHICLE_CHECK: {
    ...VEHICLE_CHECK,
    previous: PARKING.key,
    next: BASKET.key,
    data: {
      ...VEHICLE_CHECK.data,
      isVisible: (selfService, config) => isClassicCheckIn(selfService, config) && isVisibleStep(VEHICLE_CHECK, selfService, config),
    },
  },
  BASKET: { ...BASKET, previous: VEHICLE_CHECK.key },
};

type OnSiteCheckInSteps =
  CommonCheckInSteps
  | CommonOnSiteKioskCheckInSteps
  | SelfServiceSteps.SIGNATURE
  | SelfServiceSteps.VEHICLE_CHECK_QR_CODE
  | SelfServiceSteps.FINAL_INSTRUCTIONS;

const ON_SITE_CHECK_IN_STEPS: Flow<OnSiteCheckInSteps> = {
  ...COMMON_ON_SITE_KIOSK_CHECKIN_STEPS,
  SIGNATURE: {
    ...SIGNATURE,
    previous: BASKET.key,
    next: VEHICLE_CHECK_QR_CODE.key,
    data: {
      ...SIGNATURE.data,
      isVisible: ({ status }: SelfService) => isNotAnswered(status),
      isFinalizeStep: true,
    },
  },
  VEHICLE_CHECK_QR_CODE: { ...VEHICLE_CHECK_QR_CODE, previous: SIGNATURE.key, next: FINAL_INSTRUCTIONS.key },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: VEHICLE_CHECK_QR_CODE.key },
};

export default ON_SITE_CHECK_IN_STEPS;
