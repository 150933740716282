import { createSlice } from '@reduxjs/toolkit';

import vehicleCheckApi from 'modules/vehicleCheck/service';
import { GetContextResponse } from 'modules/vehicleCheck/types/GetContextResponse';
import { VehicleCheckModel } from 'modules/vehicleCheck/types/VehicleCheckModel';
import { logout } from 'modules/auth/actions';
import dealersApi from 'modules/dealers/service';

export interface InitialState {
  context: GetContextResponse;
  vehicleCheckModel: VehicleCheckModel;
}

const vehicleCheckSlice = createSlice({
  name: 'vehicleCheck',
  initialState: {
    context: null,
    vehicleCheckModel: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => ({
      ...state,
      context: {
        ...state.context,
        token: null,
      },
    }));
    builder.addMatcher(vehicleCheckApi.endpoints.getVCContext.matchFulfilled, (state, action) => ({
      ...state,
      context: action.payload,
    }));
    builder.addMatcher(vehicleCheckApi.endpoints.getModel.matchFulfilled, (state, action) => ({
      ...state,
      vehicleCheckModel: action.payload,
    }));
    builder.addMatcher(dealersApi.endpoints.getContext.matchFulfilled, (state) => ({
      ...state,
      context: {
        ...state.context,
        token: null,
      },
    }));
  },
});

export default vehicleCheckSlice;
