import React from 'react';
import classNames, { Argument } from 'classnames';
import { FormattedNumber, FormattedMessage } from 'react-intl';

import Label from 'types/Label';
import { AbstractMedia, MediaType } from 'types/AbstractMedia';

import useLabelTranslation from 'hooks/useLabelTranslation';

import Carousel from '../Carousel';

interface CardProps {
  title: React.ReactNode;
  description?: Label;
  mediaPlaceholder?: AbstractMedia;
  medias?: AbstractMedia[];
  mandatory?: boolean;
  highlighted?: boolean;
  className?: Argument;
  centerText?: boolean;
}

type CardPriceProps = | {
  /** @type {number} You should provide a `currency` as well */
  price: number;
  /** @type {string} Is mandatory when `price` is present */
  currency: string;
  discountedPrice?: number;
} | {
  price?: never;
  currency?: never;
  discountedPrice?: never;
};

const Card: React.FC<React.PropsWithChildren<CardProps & CardPriceProps>> = ({
  centerText,
  children,
  className,
  currency,
  description,
  discountedPrice,
  highlighted,
  mandatory,
  mediaPlaceholder,
  medias = [],
  price,
  title,
}) => {
  const { getLabelTranslation } = useLabelTranslation();

  const hasPrice = typeof price === 'number' && price >= 0;
  const hasMedias = medias?.length > 0;

  const hasDescription = Boolean(description);
  const hasDiscount = hasPrice && discountedPrice >= 0 && price !== discountedPrice;

  const [videoIndexPreview, setVideoIndexPreview] = React.useState<number>();

  const videos = React.useMemo(() => medias.filter(({ type }) => type === MediaType.VIDEO), [medias]);

  const handleOpenVideoPreview = React.useCallback(
    (media: AbstractMedia) => setVideoIndexPreview(media.type === MediaType.VIDEO ? videos.findIndex((video) => video.id === media.id) : undefined),
    [videos],
  );
  const handleCloseVideoPreview = React.useCallback(() => setVideoIndexPreview(undefined), []);

  return (
    <div className={classNames('rounded-card bg-card-bg overflow-hidden flex flex-col mb-5', className)}>
      {hasMedias && <Carousel hideCursor medias={medias} onMediaClick={handleOpenVideoPreview} />}
      {!hasMedias && mediaPlaceholder && <Carousel medias={[mediaPlaceholder]} />}
      {videoIndexPreview >= 0 && (
        <Carousel
          fullscreen
          medias={videos}
          onClose={handleCloseVideoPreview}
          defaultMediaIndex={videoIndexPreview}
        />
      )}

      <div className="p-3 kiosk:p-6 flex flex-col justify-between grow">
        <div>
          {highlighted && (
            <p data-testid="highlight" className="flex items-center w-fit mb-3 bg-default rounded-3xl text-white px-5 xs:px-4 py-1 !text-sm kiosk:!text-base font-semibold">
              <span className="!text-xs mr-2 text-warning-default">★</span>
              <FormattedMessage
                id="extras.recommended"
                defaultMessage="Recommended for you"
              />
            </p>
          )}
          <div className="font-bold flex mb-3">
            <h2 data-testid="title" className="title_card overflow-hidden flex flex-col flex-1">
              <span className={classNames('break-word flex', { 'm-auto text-center': centerText })}>
                {mandatory && <span className="text-danger-high !text-xl mr-2 kiosk:!text-3xl !leading-none">*</span>}
                {title}
              </span>
            </h2>
            {hasPrice && (
              <div data-testid="price" className="flex flex-col kiosk:text-2xl kioskSharebox:text-xl ml-4 items-end">
                <span data-testid="full-price" className={classNames('kiosk:!text-xl leading-6 kiosk:!leading-11', { 'line-through text-default font-normal text-s kiosk:!text-lg': hasDiscount })}>
                  {/* eslint-disable-next-line react/style-prop-object */}
                  <FormattedNumber value={price} style="currency" currency={currency} />
                </span>
                {hasDiscount && (
                  <span className="!text-base kiosk:!text-2xl ml-0 sm:ml-2 kiosk:!leading-11" data-testid="discounted-price">
                    {/* eslint-disable-next-line react/style-prop-object */}
                    <FormattedNumber value={discountedPrice} style="currency" currency={currency} />
                  </span>
                )}
              </div>
            )}
          </div>
          {hasDescription && (
            <p
              data-testid="description"
              className={classNames('whitespace-pre-wrap font-normal text-sm text-low kiosk:text-xl leading-4 mb-3', { 'text-center': centerText })}
            >
              {getLabelTranslation(description)}
            </p>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default Card;
