import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Slot } from 'modules/kiosk/types/Slot';
import Button, { ButtonType } from 'components/ui/Button';

import KeyBoxIcon from 'assets/icons/keyBox.svg';

const EmergencyKeysManagementModalContent: React.FC<Slot & { onClose: () => void }> = ({
  referenceId,
  position,
  registration,
  displayName,
  onClose,
}) => {
  const information = [referenceId, displayName].filter(Boolean).join(' - ');

  return (
    <>
      <div className="bg-white p-14 rounded-full">
        <KeyBoxIcon className="w-36 h-36" />
      </div>
      <h1 className="mt-16 text-center">
        <FormattedMessage
          id="admin.manage.key.slotLabel"
          defaultMessage="Slot N°{number}"
          values={{ number: position }}
        />
      </h1>
      <h1 className="mt-2">{registration}</h1>
      <div className="text-3xl">{information}</div>
      <h1 className="mt-16 max-w-3xl text-6xl text-center !leading-normal" data-testid="key-question">
        <FormattedMessage id="admin.manage.key.question.emptySlot" defaultMessage="Empty the slot before confirming" />
      </h1>
      <div className="my-16 bg-white rounded-5xl p-10">
        <Button className="min-w-18" type={ButtonType.ERROR} onClick={onClose} testId="key-confirm-btn">
          <FormattedMessage id="confirm.title" defaultMessage="Confirm" />
        </Button>
      </div>
    </>
  );
};

export default EmergencyKeysManagementModalContent;
