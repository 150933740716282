import debounce from 'debounce';
import { Decorator, FormApi } from 'final-form';

import actions from 'modules/form/actions';
import FormKeys from 'modules/form/types/FormKeys';

import { store } from '../../../App/Store';

export const updateFormDebounce = debounce<<T>(key: FormKeys, values: T) => void>((key, values) => {
  store.dispatch(actions.upsertForm({ key, values }));
}, 1000);

function getDecorator<T>(key:FormKeys): Decorator<T> {
  return (form: FormApi<T>) => form.subscribe(({ values, submitting }) => {
    if (submitting) {
      // Do not debounce when submitting
      store.dispatch(actions.upsertForm({ key, values }));
      // We don't use `pristine` here because we have to record when the user goes back to the original value
      // Otherwise there is a merge issue between the non pristine value and the original, reverted, one.
    } else if (form.getState().touched && values) {
      updateFormDebounce(key, values);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, { values: true, submitting: true });
}

export default getDecorator;
