enum PaymentStatus {
  // Payment not initiated, waiting for user to interact
  WAITING_FOR_USER = 'waiting_for_user',
  // Payment initiated, waiting for user to complete the payment
  PENDING = 'pending',
  // Payment completed successfully
  SUCCESS = 'success',
  // Payment failed
  ERROR = 'error',
}

export default PaymentStatus;
