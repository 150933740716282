import React from 'react';
import classNames from 'classnames';
import { TransformComponent, TransformWrapper, useTransformEffect } from 'react-zoom-pan-pinch';

import { Maps } from 'modules/selfServices/types/SelfService';

import Spinner from '../ui/Spinner';
import useImage from './hooks/useImage';
import { Position } from '../../types/Position';

interface ParkingMapProps {
  map: Maps;
  disabled?: boolean;
  value?: Position;
  onChange?: (pos: Position) => void;
}

export const Marker: React.FC<{ position: { top: number; left: number }; hidden: boolean }> = ({
  position,
  hidden,
}) => {
  const [scale, setScale] = React.useState(1);

  useTransformEffect(({ state }) => setScale(state.scale));

  const style = React.useMemo(
    () => ({ ...position, transform: `scale(${1 / scale}) translate(-50%, -50%)` }),
    [position, scale],
  );

  return (
    <div
      className={classNames(
        'absolute border-tertiary border-2 w-12 h-12 rounded-full origin-top-left pointer-events-none',
        { hidden },
      )}
      style={style}
    >
      <div
        className="relative bg-gradient-radial-white backdrop-blur-sm w-full h-full overflow-hidden rounded-full
                    after:content-[''] after:absolute after:m-auto after:inset-0 after:bg-tertiary after:rounded-full after:z-20 after:w-3.5 after:h-3.5
                    before:content-[''] before:absolute before:m-auto before:inset-0 before:bg-white before:rounded-full before:z-10 before:w-6 before:h-6"
      />
    </div>
  );
};

const ParkingMap: React.FC<ParkingMapProps> = ({
  map, disabled, onChange, value = {},
}) => {
  const {
    isLoading, isReady, position, onImageLoaded, onClick, setImageRef, transformComponentRef,
  } = useImage({
    disabled,
    onChange,
    initialPosition: value,
  });

  const hideMarker = !isReady || !value?.y || !value?.x;

  return (
    <TransformWrapper
      minScale={1.01}
      initialScale={1.01}
      ref={transformComponentRef}
      panning={{ velocityDisabled: true }}
    >
      <TransformComponent
        contentClass="bg-secondary"
        wrapperClass="aspect-video w-full bg-secondary border-secondary border-2 rounded-lg md:border-4"
      >
        {isLoading && <Spinner className="absolute top-1/2 left-1/2" />}
        <div onClick={onClick} data-testid={`map-${map.id}`}>
          <img
            alt={map.name}
            ref={setImageRef}
            src={map.media.path}
            data-testid="map-img"
            onLoad={onImageLoaded}
            onError={onImageLoaded}
            className="object-contain h-full w-full"
          />
          <Marker position={position} hidden={hideMarker} />
        </div>
      </TransformComponent>
    </TransformWrapper>
  );
};

export default ParkingMap;
