import React from 'react';
import { useSelector } from 'react-redux';

import BasketIcon from 'assets/icons/basket.svg';

import { useDispatch } from 'hooks';
import { isModalOpen } from 'modules/basket/selectors';
import { openBasketModal } from 'modules/basket/actions';

import { getNewAcceptedExtra } from 'modules/form/selectors';
import Basket from './index';
import Button, { ButtonType } from '../ui/Button';

const BasketButton: React.FC = () => {
  const dispatch = useDispatch();

  const displayModal = useSelector(isModalOpen);
  const newAcceptedExtra = useSelector(getNewAcceptedExtra);

  const hasMoreThan9Extras = newAcceptedExtra.length > 9;
  const nbNewAcceptedExtra = Math.min(newAcceptedExtra.length, 9);

  const handleClick = React.useCallback(() => {
    dispatch(openBasketModal());
  }, [dispatch]);

  return (
    <>
      <Button
        onClick={handleClick}
        testId="basket-button"
        type={ButtonType.SECONDARY}
        className="size-10 kiosk:size-20 !p-2 mr-2 relative"
      >
        <BasketIcon className="m-auto size-6 kiosk:size-8" />
        {nbNewAcceptedExtra > 0 && (
          <div className="absolute right-0 top-0 rounded-full border border-input-bg size-s bg-tertiary text-white text-xxxs flex justify-center items-center">
            {nbNewAcceptedExtra}
            {hasMoreThan9Extras && '+'}
          </div>
        )}
      </Button>
      {displayModal && <Basket />}
    </>
  );
};

export default BasketButton;
