import React from 'react';
import classNames from 'classnames';

import useAnalytics, { Analytics } from 'hooks/useAnalytics';
import Spinner from './Spinner';

export enum ButtonType {
  /* eslint-disable max-len */
  PRIMARY = 'bg-primary text-gray-90 hover:ring-2 hover:ring-border-bg active:bg-secondary disabled:bg-primary/50 disabledStyle:bg-primary/50',
  SECONDARY = 'bg-secondary text-gray-90 hover:ring-2 hover:ring-border-bg  active:bg-secondary-darken-15 disabled:bg-secondary-lighten-60 disabledStyle:bg-secondary-lighten-60',
  TERTIARY = 'bg-tertiary text-primary hover:bg-tertiary-darken-15 active:bg-tertiary-darken-30 disabled:bg-tertiary-lighten-60 disabledStyle:bg-tertiary-lighten-60',
  WHITE = 'bg-white text-gray-90 hover:border-border-bg active:bg-tertiary-darken-15 active:text-white focus:shadow-focus focus:hover:shadow-none disabled:bg-white/50 disabled:hover:text-gray-90 outline-tertiary/10 disabledStyle:bg-white/50 disabledStyle:hover:text-gray-90 outline-tertiary/10',
  DENY = 'bg-gray-90 text-white hover:gray-90/90 focus:bg-gray-90/80 focus:shadow-focus focus:hover:shadow-none',
  LIGHT = 'bg-transparent text-gray-90 hover:underline hover:underline-offset-1 focus:underline focus:outline-0 active:outline-0',
  ERROR = 'bg-error text-white hover:bg-error-darken-15 active:bg-error-darken-30 disabled:bg-error-lighten-60 disabledStyle:bg-error-lighten-60 focus:ring-error-lighten-60',
  SUCCESS = 'bg-success text-white hover:bg-success-darken-15 active:bg-success-darken-30 disabled:bg-success-lighten-60 disabledStyle:bg-success-lighten-60 focus:ring-error-lighten-60',
  /* eslint-enable max-len */
}

export interface ButtonProps {
  type?: ButtonType;
  submit?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  testId?: string;
  disabledStyle?: boolean;
  analytics?: Analytics;
}

const Button = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<ButtonProps>>((
  {
    type = ButtonType.TERTIARY,
    submit,
    disabled: disabledProp,
    loading,
    onClick: onClickDefault,
    className,
    children,
    testId,
    disabledStyle,
    analytics,
  },
  ref,
) => {
  const { onClick } = useAnalytics();

  const disabled = disabledProp || loading;

  return (
    <button
      ref={ref}
      onClick={onClick(analytics, onClickDefault)}
      data-testid={testId}
      disabled={disabled}
      type={submit ? 'submit' : 'button'}
      className={classNames(
        { 'cursor-not-allowed': disabled },
        // eslint-disable-next-line max-len
        'button transform transition duration-500 focus:ring-4 focus:ring-border-bg focus:hover:ring-2 focus:outline-none p-4 kiosk:p-9 text-base kiosk:text-2xl drop-shadow-none',
        type,
        className,
      )}
      data-fake-disabled={disabledStyle}
    >
      {loading && (<Spinner className={classNames({ '!text-white/30': type === ButtonType.TERTIARY })} />)}
      {!loading && children}
    </button>
  );
});

export default Button;
