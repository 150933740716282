import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import socketSlice from 'modules/sockets/reducer';

import { InitialState } from 'modules/steps/types/InitialState';

const initialState = { currentStep: null } as InitialState;

const stepsSlice = createSlice({
  name: 'step',
  initialState,
  reducers: {
    resetStep: () => initialState,
    setCurrentStep: (_, { payload: currentStep }:PayloadAction<string>) => ({ currentStep }),
  },
});

export default stepsSlice;
