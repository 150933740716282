import React from 'react';

import { useSelector } from 'hooks';
import FormKeys from 'modules/form/types/FormKeys';
import { getFormValuesByKey } from 'modules/form/selectors';

type UseInitialValue = <T>(formKey: FormKeys) => T;

const useInitialValue: UseInitialValue = <T>(formKey: FormKeys) => {
  const initialValue = useSelector((state) => getFormValuesByKey(state, formKey));

  // Force to have an immutable "readonly" value
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => initialValue, []) as T;
};

export default useInitialValue;
