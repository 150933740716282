import React from 'react';
import classNames, { Argument } from 'classnames';

export interface TextareaProps {
  label: string;
  value: string;
  rows?: number;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  className?: Argument;
  'data-testid'?: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea: React.FC<TextareaProps> = ({
  label, onChange, value, rows = 5, className, required, disabled, error, 'data-testid': dataTestid,
}) => {
  const id = React.useId();

  return (
    <>
      <div className={classNames('relative z-0 group bg-input-bg rounded-lg kiosk:rounded-2xl flex', className)}>
        <textarea
          id={id}
          value={value}
          rows={rows}
          placeholder=" "
          onChange={onChange}
          required={required}
          disabled={disabled}
          className="textarea peer"
          data-testid={dataTestid}
        />
        <label className="textarea-label" htmlFor={id}>{label}</label>
      </div>
      {error && (<span className="text-error px-1 text-xs" data-testid="error-text">{error}</span>)}
    </>
  );
};

export default Textarea;
