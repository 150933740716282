import React from 'react';
import { useField } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import ExtraStatus from 'types/ExtraStatus';
import type { Advice } from 'types/Checklist';

import Popup from '../ui/Popup';
import Button, { ButtonSize, ButtonType } from '../ui/Button';

const ExtraCardActions: React.FC<{ name: string, storedExtras: Advice[] }> = ({ name, storedExtras }) => {
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);

  const { value: extraId } = useField<string>(`${name}.adviceId`, { subscription: { value: true } }).input;
  const { value: extraStatus, onChange: handleChangeStatus } = useField<ExtraStatus>(`${name}.status`, { subscription: { value: true } }).input;

  const isAlreadyAnswered = storedExtras.some(({ adviceId, status }) => adviceId === extraId && status !== ExtraStatus.PENDING);

  const handleOnChange = React.useCallback((newStatus: ExtraStatus) => () => {
    handleChangeStatus(newStatus);
  }, [handleChangeStatus]);

  const handleDisabledClick = React.useCallback(() => {
    if (isAlreadyAnswered) {
      setIsPopupOpen(true);
    }
  }, [isAlreadyAnswered]);

  const handleClosePopup = React.useCallback(() => {
    setIsPopupOpen(false);
  }, []);

  return (
    <>
      <div className="relative flex justify-between">
        {isAlreadyAnswered && (
          <div
            className="absolute top-0 left-0 size-full z-10 cursor-not-allowed"
            onClick={handleDisabledClick}
            data-testid="disabledExtraOverlay"
          />
        )}
        <Button
          testId="refuse"
          size={ButtonSize.SMALL}
          disabled={isAlreadyAnswered}
          className="mr-1.5 min-w-24 max-w-48"
          onClick={handleOnChange(ExtraStatus.REFUSED)}
          type={extraStatus === ExtraStatus.REFUSED ? ButtonType.TERTIARY : ButtonType.PRIMARY}
        >
          <FormattedMessage id="confirm.no" defaultMessage="No" />
        </Button>
        <Button
          testId="accept"
          size={ButtonSize.SMALL}
          className="ml-1.5 w-full"
          disabled={isAlreadyAnswered}
          onClick={handleOnChange(ExtraStatus.ACCEPTED)}
          type={extraStatus === ExtraStatus.ACCEPTED ? ButtonType.TERTIARY : ButtonType.PRIMARY}
        >
          {extraStatus !== ExtraStatus.ACCEPTED && (<FormattedMessage id="basket.add" defaultMessage="Add to basket" />)}
          {extraStatus === ExtraStatus.ACCEPTED && (<FormattedMessage id="basket.added" defaultMessage="Added" />)}
        </Button>
      </div>
      {isPopupOpen && (
        <Popup
          onClose={handleClosePopup}
          title={(
            <FormattedMessage
              id="extras.alreadyAnswered"
              defaultMessage="This answer can no longer be changed."
              tagName="h2"
            />
          )}
        />
      )}
    </>
  );
};
export default ExtraCardActions;
