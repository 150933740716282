import { createReducer, isAnyOf, isRejected } from '@reduxjs/toolkit';

import kioskApi from 'modules/kiosk/service';
import { logout } from 'modules/auth/actions';
import { DoorsStatus } from 'modules/kiosk/types/KeysSafe';
import selfServicesApi from 'modules/selfServices/service';
import { openKeysSafe, setDoorError, setDoorStatus } from 'modules/kiosk/actions';

export interface InitialState {
  status: DoorsStatus;
  hasError: boolean;
}

export const initialState: InitialState = {
  hasError: false,
  status: DoorsStatus.PENDING,
};

const doorsReducer = createReducer<InitialState>(initialState, (builder) => {
  builder
    .addCase(logout, () => initialState)
    .addCase(setDoorStatus, (state, { payload }) => ({ ...state, status: payload }))
    .addCase(setDoorError, (state, { payload }) => ({ ...state, hasError: payload }))
    .addMatcher(selfServicesApi.endpoints.getSelfServiceById.matchFulfilled, () => initialState)
    .addMatcher(openKeysSafe.match, () => ({ hasError: false, status: DoorsStatus.OPENING }))
    .addMatcher(
      isAnyOf(kioskApi.endpoints.openSlot.matchRejected, kioskApi.endpoints.openSlotAsAdmin.matchRejected),
      () => ({ status: DoorsStatus.PENDING, hasError: true }),
    )
    .addMatcher(
      isAnyOf(
        kioskApi.endpoints.closeSlotAsAdmin.matchPending,
        kioskApi.endpoints.closeSlotAsAdmin.matchRejected,
        kioskApi.endpoints.closeAllSlotsAsAdmin.matchPending,
        kioskApi.endpoints.closeAllSlotsAsAdmin.matchRejected,
        kioskApi.endpoints.updateSlot.matchPending,
        kioskApi.endpoints.updateSlot.matchRejected,
        kioskApi.endpoints.updateSlotAsAdmin.matchPending,
        kioskApi.endpoints.updateSlotAsAdmin.matchRejected,
        kioskApi.endpoints.unprepareSlotAsAdmin.matchPending,
        kioskApi.endpoints.unprepareSlotAsAdmin.matchRejected,
      ),
      (state, action) => {
        const hasError = isRejected(action);
        return {
          status: hasError ? DoorsStatus.OPENED : DoorsStatus.CLOSING,
          hasError,
        };
      },
    );
});

export default doorsReducer;
