import React from 'react';
import debounce from 'debounce';
import { addSeconds as addSecondsBase } from 'date-fns';

import useCountdown from './useCountdown';

const LISTENERS = ['mousemove', 'keydown', 'touchstart', 'scroll', 'click'];

interface UseInactivityProps {
  seconds: number;
  onInactive?: () => void;
  // If paused is true it will stop the inactivity subscription.
  paused?: boolean;
}

type UseInactivity = (props: UseInactivityProps) => {
  isExpired: boolean;
  restart: () => void;
};

const addSeconds = (seconds: number) => addSecondsBase(Date.now(), seconds);

const useInactivity: UseInactivity = ({ seconds, onInactive, paused }) => {
  const [date, setDate] = React.useState(addSeconds(seconds));

  const { countdown } = useCountdown({ date });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResetTimer = debounce(
    () => {
      setDate(addSeconds(seconds));
    },
    200,
    { immediate: true },
  );

  const removeListeners = React.useCallback(() => {
    LISTENERS.forEach((listener) => {
      document.removeEventListener(listener, handleResetTimer);
    });
  }, [handleResetTimer]);

  const addListeners = React.useCallback(() => {
    LISTENERS.forEach((listener) => {
      document.addEventListener(listener, handleResetTimer);
    });
  }, [handleResetTimer]);

  React.useEffect(() => {
    addListeners();
    return removeListeners;
  }, [removeListeners, addListeners]);

  React.useEffect(() => {
    if (countdown === 0) {
      if (!paused) {
        onInactive?.();
      } else {
        handleResetTimer.clear();
      }
      removeListeners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  const restart = () => {
    handleResetTimer();
    addListeners();
  };

  return {
    isExpired: countdown === 0,
    restart,
  };
};

export default useInactivity;
