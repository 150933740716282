import { createDraftSafeSelector } from '@reduxjs/toolkit';

import type { RootState } from 'App/Store';
import ExtraStatus from 'types/ExtraStatus';
import ParkingForm from 'types/ParkingForm';
import { YesNoChoice } from 'types/Question';
import { ExtrasForm } from 'types/ExtrasForm';
import { BreakdownInfo } from 'types/BreakdownInfo';
import { SignatureForm } from 'types/SignatureForm';
import { RepairOrderForm } from 'types/RepairOrderForm';
import { VehicleCheckForm } from 'types/VehicleCheckForm';
import { PoliciesEnum } from 'modules/auth/types/Policies';
import { MobilityFormType } from 'types/MobilityDocumentFormType';
import { SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';
import { KioskPreferencesForm, QuestionsForm } from 'types/QuestionsForm';
import { VehicleCheckType } from 'modules/vehicleCheck/types/VehicleCheckModel';
import { SelfService, SelfServiceType } from 'modules/selfServices/types/SelfService';
import { SelfServiceStepConfig } from 'modules/selfServices/types/SelfServiceStepConfig';

import { KioskPreferencesQuestionsIds } from 'modules/selfServices/constants';

import {
  getContext,
  getPolicies,
} from 'modules/dealers/selectors';
import { getMFAData } from 'modules/auth/selectors';
import { getFlowType, getSelectedSelfService, isQuickFlow } from 'modules/selfServices/selectors';

import formsAdapter from './adapter';
import { FormState } from './types/FormState';
import { getExtras } from 'utils/formUtils';

const getProps = <T>(_: RootState, props: T) => props;

const getStepState = (state: RootState) => state.steps;
export const getState = (state: RootState) => state.form;

const adapterSelector = formsAdapter.getSelectors<RootState>(getState);

export const getFormValuesByKey = createDraftSafeSelector(adapterSelector.selectById, (form) => form?.values);

type SavedFormType = {
  repairOrder: FormState<RepairOrderForm>;
  questions: FormState<QuestionsForm>;
  extras: FormState<ExtrasForm>;
  parking: FormState<ParkingForm>;
  signature: FormState<SignatureForm>;
  vehicleCheck: FormState<VehicleCheckForm>;
  mobilityParking: FormState<ParkingForm>;
  mobility: FormState<MobilityFormType>;
  breakdownInfo: FormState<BreakdownInfo>;
  kioskPreferencesQuestions?: FormState<KioskPreferencesForm>;
};

export const getFormattedFormData = createDraftSafeSelector(
  adapterSelector.selectEntities,
  getPolicies,
  getProps,
  isQuickFlow,
  (forms, policies, selfService: SelfService, isQuick: boolean): Partial<SelfService> => {
    const { type } = selfService;
    if (type === SelfServiceType.CHECK_IN || type === SelfServiceType.BREAKDOWN) {
      // Formatters
      const {
        repairOrder,
        questions,
        extras: extrasForm,
        parking,
        signature,
        vehicleCheck,
        mobility,
        breakdownInfo,
        kioskPreferencesQuestions,
      } = forms as Partial<SavedFormType>;

      const { extras, ...basketData } = extrasForm?.values ?? {};
      const hasParkingData = parking?.values?.spotNumber || parking?.values?.x >= 0;
      const checkInKioskPreferences = kioskPreferencesQuestions?.values?.kioskPreferencesQuestions?.find(
        (question) => question.id === KioskPreferencesQuestionsIds.CHECK_IN,
      );
      const checkOutKioskPreferences = kioskPreferencesQuestions?.values?.kioskPreferencesQuestions?.find(
        (question) => question.id === KioskPreferencesQuestionsIds.CHECK_OUT,
      );

      return {
        ...repairOrder?.values,
        ...(questions?.values && { questionForm: { questions: questions.values.questions } }),
        checklists: selfService.checklists?.map((checklist) => ({
          ...checklist,
          items: checklist.items?.map((item) => ({
            ...item,
            advices: extras?.filter(
              (extra) => item.advices.some((advice) => advice.adviceId === extra.adviceId),
            ),
          })),
        })),
        ...(hasParkingData && { parking: parking.values }),
        ...(mobility?.values && {
          mobility: {
            ...mobility.values,
            documents: mobility.values.documents?.map(({ media, ...document }) => ({
              ...document,
              media: mobility.values.customerAccepted ? media : undefined,
            })),
          },
        }),
        kioskPreference: {
          ...selfService.kioskPreference,
          ...(checkInKioskPreferences ? {
            checkInComment: checkInKioskPreferences.comment,
            checkIn: checkInKioskPreferences.response === YesNoChoice.YES,
          } : {}),
          ...(checkOutKioskPreferences ? {
            checkOutComment: checkOutKioskPreferences.comment,
            checkOut: checkOutKioskPreferences.response === YesNoChoice.YES,
          } : {}),
        },
        signature: { uploadId: signature?.values?.uploadId },
        breakdownServiceEmail: signature?.values?.breakdownServiceEmail,
        vehicleCheckAccepted: vehicleCheck?.values?.vehicleCheck === YesNoChoice.YES,
        courtesyVehicleCheckAccepted: vehicleCheck?.values?.courtesyVehicleCheck === YesNoChoice.YES,
        status: selfService.status,
        type: selfService.type,
        isQuick,
        ...(breakdownInfo?.values && breakdownInfo.values),
        ...basketData,
        policies: 'basketGTC' in basketData && basketData.basketGTC ? [{
          link: policies.find((policy) => policy.type === PoliciesEnum.GENERAL_TERMS_CONDITION).link,
          type: PoliciesEnum.GENERAL_TERMS_CONDITION,
        }] : undefined,
      };
    }
    if (type === SelfServiceType.CHECK_OUT) {
      const { mobilityParking } = forms as Partial<SavedFormType>;
      const { mobility, signature } = forms as Partial<SavedFormType>;
      const hasParkingData = mobilityParking?.values?.spotNumber || mobilityParking?.values?.x >= 0;

      return hasParkingData ? {
        mobility: {
          ...mobility?.values,
          parkingReturn: mobilityParking.values,
        },
        signature: {
          uploadId: signature?.values?.uploadId,
        },
      } : {};
    }
    return {};
  },
);

const getVehicleCheckForm = createDraftSafeSelector(
  adapterSelector.selectEntities,
  ({ vehicleCheck }) => vehicleCheck,
);
const getVehicleCheckFormValues = createDraftSafeSelector(
  getVehicleCheckForm,
  (vehicleCheckForm) => vehicleCheckForm?.values,
);

export const isVehicleCheckAccepted = createDraftSafeSelector(
  getVehicleCheckFormValues,
  (vehicleCheckFormValues) => vehicleCheckFormValues?.vehicleCheck === YesNoChoice.YES,
);

export const isCourtesyVehicleCheckAccepted = createDraftSafeSelector(
  getVehicleCheckFormValues,
  (vehicleCheckFormValues) => vehicleCheckFormValues?.courtesyVehicleCheck === YesNoChoice.YES,
);

export const isCourtesyVehicleCheckRefused = createDraftSafeSelector(
  getVehicleCheckFormValues,
  (vehicleCheckFormValues) => vehicleCheckFormValues?.courtesyVehicleCheck === YesNoChoice.NO,
);

export const getAcceptedVehicleChecks = createDraftSafeSelector(
  isVehicleCheckAccepted,
  isCourtesyVehicleCheckAccepted,
  (vehicleCheckAccepted, courtesyVehicleCheckAccepted) => [
    ...(vehicleCheckAccepted ? [VehicleCheckType.PERSONAL] : []),
    ...(courtesyVehicleCheckAccepted ? [VehicleCheckType.COURTESY] : []),
  ],
);

const getVehicleCheckConfig = createDraftSafeSelector(
  getMFAData,
  getContext,
  isVehicleCheckAccepted,
  isCourtesyVehicleCheckAccepted,
  (mfaData, context, vehicleCheckAccepted, courtesyVehicleCheckAccepted) => ({
    vehicleCheckEnabled: mfaData?.vehicleCheckEnabled ?? context?.vehicleCheckEnabled ?? false,
    vehicleCheckAccepted,
    courtesyVehicleCheckEnabled: mfaData?.mobility?.courtesyVehicleCheckEnabled
                                 ?? context?.mobility?.courtesyVehicleCheckEnabled
                                 ?? false,
    courtesyVehicleCheckAccepted,
  }),
);

const getMobilityForm = createDraftSafeSelector(
  adapterSelector.selectEntities,
  ({ mobility }) => mobility,
);

const getMobilityFormValues = createDraftSafeSelector(
  getMobilityForm,
  (mobilityForm) => mobilityForm?.values,
);

export const isMobilityAccepted = createDraftSafeSelector(
  getMobilityFormValues,
  (mobility) => mobility?.customerAccepted,
);

const getExtrasForm = createDraftSafeSelector(
  adapterSelector.selectEntities,
  ({ extras }) => extras?.values,
);

export const getNewAcceptedExtra = createDraftSafeSelector(
  getExtrasForm,
  getSelectedSelfService,
  (form: ExtrasForm, selfService) =>
    // Check if some extra has been accepted during the self-service flow.
    getExtras(selfService)?.filter(({ adviceId, status }) => {
      // Do not take into account extra that has been accepted in a previous check-in flow
      if (!status || status === ExtraStatus.PENDING) {
        const advice = form?.extras?.find((extra) => extra.adviceId === adviceId);
        return advice?.status === ExtraStatus.ACCEPTED;
      }
      return false;
    }) ?? [],
  );

export const shouldDisplayBasketStep = createDraftSafeSelector(
  getStepState,
  getNewAcceptedExtra,
  getSelectedSelfService,
  (steps, newAcceptedExtra, selfService) => {
    const isCurrentStep = steps.currentStep === SelfServiceSteps.BASKET;
    return isCurrentStep || newAcceptedExtra.length > 0 || selfService?.repairOrder?.length > 0;
  },
);

export const getStepsConfig = createDraftSafeSelector(
  getVehicleCheckConfig,
  isMobilityAccepted,
  getFlowType,
  shouldDisplayBasketStep,
  getContext,
  (vehicleCheckConfig, hasAcceptedMobility, flowType, displayBasket, context) => ({
    ...vehicleCheckConfig,
    flowType,
    displayBasket,
    hasAcceptedMobility,
    kioskType: context?.kioskType,
    kioskModel: context?.kioskModel,
    connectionType: context?.connectionType,
    selfServiceAvailableTypes: context?.selfServiceAvailableTypes,
  } as SelfServiceStepConfig),
);
