import React from 'react';
import { useIntl } from 'react-intl';

import {
  getFormattedBrands,
  getFormattedDealers,
  getKioskDealersWithBreakdown,
} from 'modules/dealers/selectors';

import { useSelector } from 'hooks';

import { LoginData } from 'modules/auth/types/InitialState';
import { Dealers } from 'modules/dealers/types/ContextResponse';

import SelectInput from '../ui/SelectInput';

interface BreakDownInformationProps {
  brandId: string;
  dealerId: string;
  onChange: (data: Partial<LoginData>) => void;
}
const BreakDownInformation: React.FC<BreakDownInformationProps> = ({
  brandId, dealerId, onChange,
}) => {
  const intl = useIntl();

  const dealers = useSelector(getFormattedDealers);
  const kioskDealers = useSelector(getKioskDealersWithBreakdown);
  const brands = useSelector((state) => getFormattedBrands(state, dealerId));

  const displayDealers = kioskDealers?.length > 1;
  const displayBrands = Object.keys(brands ?? {}).length > 1;

  const handleBrandChange = React.useCallback((newBrandId : string) => onChange({ brandId: newBrandId }), [onChange]);
  const handleDealerChange = React.useCallback((newDealerId: string) => {
    onChange({
      dealerId: newDealerId,
      brandId: kioskDealers?.find(({ id }: Dealers) => id === newDealerId)?.brands?.[0]?.id,
    });
  }, [kioskDealers, onChange]);

  return (
    <>
      {displayDealers && (
        <SelectInput
          data={dealers}
          value={dealerId}
          data-testid="dealer-select"
          onChange={handleDealerChange}
          label={intl.formatMessage({ id: 'dealership', defaultMessage: 'Dealership' })}
        />
      )}
      {displayBrands && (
        <SelectInput
          data={brands}
          value={brandId}
          data-testid="brand-select"
          onChange={handleBrandChange}
          label={intl.formatMessage({ id: 'brand', defaultMessage: 'Brand' })}
        />
      )}
    </>
  );
};

export default BreakDownInformation;
