import React from 'react';

import { useSelector } from 'hooks';
import PageBaseProps from 'types/PageBase';
import FormKeys from 'modules/form/types/FormKeys';
import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { useUpdateCustomerInfoMutation } from 'modules/selfServices/service';
import { CustomerInfo as CustomerInfoType } from 'modules/selfServices/types/CustomerInfo';

import Content from './Content';
import ConnectedForm from '../ui/ConnectedForm';

const CustomerInfo: React.FC<PageBaseProps> = ({ onNext, onPrev }) => {
  const selfServiceId = useSelector(getSelectedSelfServiceId);

  const [updateCustomer, { isLoading }] = useUpdateCustomerInfoMutation();

  const handleSubmit = React.useCallback(async (values: CustomerInfoType & { permanent: string }) => {
    const { permanent, ...formData } = values;
    try {
      await updateCustomer({ id: selfServiceId, params: { permanent: permanent === 'true' }, body: formData }).unwrap();
      onNext();
    } catch (e) {}
  }, [onNext, selfServiceId, updateCustomer]);

  return (
    <ConnectedForm
      onNext={onNext}
      onPrev={onPrev}
      component={Content}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      formKey={FormKeys.CUSTOMER_INFO}
      subscription={{ pristine: true, errors: true }}
    />
  );
};

export default CustomerInfo;
