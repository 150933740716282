import ExtraStatus from 'types/ExtraStatus';
import { YesNoChoice } from 'types/Question';

export const format = (response: YesNoChoice) => {
  switch (response) {
    case YesNoChoice.YES:
      return ExtraStatus.ACCEPTED;
    case YesNoChoice.NO:
      return ExtraStatus.REFUSED;
    default:
      return undefined;
  }
};

export const parse = (status: ExtraStatus) => {
  switch (status) {
    case ExtraStatus.ACCEPTED:
      return YesNoChoice.YES;
    case ExtraStatus.REFUSED:
      return YesNoChoice.NO;
    default:
      return undefined;
  }
};

export const booleanFormat = (response?: boolean) => {
  if (typeof response === 'boolean') {
    return response ? ExtraStatus.ACCEPTED : ExtraStatus.REFUSED;
  }

  return undefined;
};

export const booleanParse = (status: ExtraStatus) => {
  switch (status) {
    case ExtraStatus.ACCEPTED:
      return true;
    case ExtraStatus.REFUSED:
      return false;
    default:
      return undefined;
  }
};
