import React from 'react';
import { useFieldArray } from 'react-final-form-arrays';

import { Extra as ExtraType } from 'types/ExtrasForm';

import Extra from './Extra';

const Extras: React.FC = () => {
  const { fields: extras } = useFieldArray<ExtraType>('extras', { subscription: { } });

  return (
    <>
      {extras.map((name: string) => (<Extra key={name} name={name} />))}
    </>
  );
};

export default Extras;
