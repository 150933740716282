import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Modal } from 'components/ui';
import socketSlice from 'modules/sockets/reducer';

import { b } from 'utils/i18nUtils';

import CheckIcon from 'assets/icons/check.svg';
import terminalImage from 'assets/images/terminal.jpg';

interface Props {
  open: boolean;
  isPaid: boolean;
}
const KioskInstructionModal: React.FC<Props> = ({ open = false, isPaid }) => {
  const dispatch = useDispatch();
  const ref = React.useRef(); // Empty reference for the focus

  const timeoutRef = React.useRef<NodeJS.Timeout>();
  React.useLayoutEffect(() => {
    if (isPaid) {
      timeoutRef.current = setTimeout(() => {
        dispatch(socketSlice.actions.closeInstructionModal());
      }, 3500);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaid]);

  return (
    <Modal focusRef={ref} open={open} className="w-full max-w-md kiosk:max-w-4xl">
      <div
        data-testid="pending-payment-warning-modal"
        className="flex flex-col items-center mt-5 kiosk:my-36 text-center"
      >
        {!isPaid && (
          <>
            <FormattedMessage
              id="page.finalInvoice.modal.kioskInstruction.proceedTitle"
              defaultMessage="Proceed to payment"
              tagName="h2"
            />
            <div className="kiosk:min-h-[620px] grid place-items-center kiosk:my-14">
              <img src={terminalImage} alt="Payment terminal" />
            </div>
            <FormattedMessage
              id="page.finalInvoice.modal.kioskInstruction.proceedContent"
              // eslint-disable-next-line max-len
              defaultMessage="Please <b>follow the payment instructions</b> displayed on the <b>terminal</b> to the right of this screen."
              tagName="p"
              values={{ b }}
            />
          </>
        )}

        {isPaid && (
          <>
            <FormattedMessage
              id="page.finalInvoice.modal.kioskInstruction.paidTitle"
              defaultMessage="Payment complete"
              tagName="h2"
            />
            <div
              className="w-[586px] h-[586px] grid place-items-center
              bg-icon-success/[.20] rounded-full kiosk:my-20"
            >
              <CheckIcon className="check-icon rounded-full h-52 w-52 bg-icon-success ring-[36px] ring-icon-success fill-white" />
            </div>
            <FormattedMessage
              id="page.finalInvoice.modal.kioskInstruction.paidContent"
              defaultMessage="Thank you for <b>your payment!</b>"
              tagName="p"
              values={{ b }}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default KioskInstructionModal;
