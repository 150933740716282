import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ButtonType } from 'components/ui/Button';
import {
  Button, Input, Modal,
} from 'components/ui';

export interface PromptProps {
  value: string;
  placeholder?: string;
  title?: React.ReactNode;
  onClose: (isOpen: boolean) => void;
  onSave:(value: string) => void;
}

const Prompt: React.FC<PromptProps> = ({
  value: valueProp, placeholder, title, onClose, onSave,
}) => {
  const [value, setValue] = React.useState(valueProp);

  const handleClose = React.useCallback(() => {
    onClose(false);
  }, [onClose]);

  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  const handleSave = React.useCallback(() => {
    onSave(value.trim());
  }, [onSave, value]);

  return (
    <Modal
      open
      onClose={onClose}
      className="w-full max-w-lg kiosk:max-w-4xl"
      title={title}
    >
      <div
        className="flex flex-col items-center"
      >
        <Input
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          className="w-full"
          inputClassName="!p-4 w-full"
          data-testid="input-title"
        />
        <div className="flex mt-3 kiosk:mt-10 w-full">
          <Button
            type={ButtonType.SECONDARY}
            onClick={handleClose}
            className="w-full h-14 mr-2 kiosk:mt-0"
            testId="cancel"
          >
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            onClick={handleSave}
            className="w-full h-14 kiosk:mt-0"
            testId="save-title"
            disabled={!value}
          >
            <FormattedMessage id="save" defaultMessage="Save" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Prompt;
