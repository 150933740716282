import { AbstractMedia, MediaType } from './AbstractMedia';

export enum UploadStatus {
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
  ERROR = 'ERROR',
  DELETING = 'DELETING',
}

export interface MediaInterface extends AbstractMedia {
  status: UploadStatus;
  isLoading: boolean;
  isLoaded: boolean;
  isDeleting: boolean;
}

export default class Media implements MediaInterface {
  public id: string;

  public uploadId: string;

  public type: MediaType;

  public filename: string;

  public path: string;

  public thumbnailPath: string;

  public status: UploadStatus;

  constructor({
    id, uploadId, type, path, filename, status = UploadStatus.UPLOADED, thumbnailPath,
  }: Partial<MediaInterface>) {
    this.id = id;
    this.type = type;
    this.path = path;
    this.status = status;
    this.uploadId = uploadId;
    this.filename = filename;
    this.thumbnailPath = thumbnailPath;
  }

  get isLoading() {
    return this.status === UploadStatus.UPLOADING || this.status === UploadStatus.DELETING;
  }

  get isLoaded() {
    return Boolean(this.status === UploadStatus.UPLOADED && this.path);
  }

  get isDeleting() {
    return this.status === UploadStatus.DELETING;
  }
}
