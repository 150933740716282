import Label from 'types/Label';
import Checklist from 'types/Checklist';
import QuestionForm from 'types/QuestionForm';
import Media, { MediaInterface } from 'types/Media';
import { AbstractMedia } from 'types/AbstractMedia';

import { Dealer } from 'modules/dealers/types/Dealer';
import { KeyStatus } from 'modules/kiosk/types/Kiosk';
import { OpenKeysSafeResponse } from 'modules/kiosk/types/KeysSafe';
import { CustomerInfo } from 'modules/selfServices/types/CustomerInfo';

import { Position } from 'types/Position';
import { Item } from 'modules/vehicleCheck/types/VehicleCheckModel';
import SelfServiceStatus from './SelfServiceStatus';

export enum SelfServiceType {
  CHECK_IN = 'CHECK_IN',
  CHECK_OUT = 'CHECK_OUT',
  BREAKDOWN = 'BREAKDOWN',
}

export enum SelfServiceOrigin {
  HOME = 'HOME',
  KIOSK = 'KIOSK',
  ON_SITE = 'ON_SITE',
}

export enum FinalInvoiceStatusesEnum {
  CREATED = 'CREATED',
  PAID = 'PAID',
  SENT = 'SENT',
}

export enum FuelLevel {
  FULL = 'FULL',
  THREE_QUARTERS = 'THREE_QUARTERS',
  HALF = 'HALF',
  ONE_QUARTER = 'ONE_QUARTER',
  RESERVE = 'RESERVE',
}

export enum DocumentStatus {
  ACCEPTED = 'ACCEPTED',
  REQUESTED = 'REQUESTED',
  TO_VALIDATE = 'TO_VALIDATE',
  REFUSED = 'REFUSED',
}

export enum SignatureStatus {
  SIGNED = 'SIGNED',
  UNSIGNED = 'UNSIGNED',
}

export const AllSelfServiceTypes = Object.values(SelfServiceType) as SelfServiceType[];

export type FinalInvoice = {
  id: string;
  payable: boolean;
  authorId: string;
  createdOn: string;
  enabled: boolean;
  name: string;
  link?: string;
  price: number;
  status: FinalInvoiceStatusesEnum;
  medias?: AbstractMedia[];
  pending: boolean;
  // The statuses of the last PAYMENT_REQUEST
  failed: boolean;
  aborted: boolean;
};

interface RepairOrder {
  adviceName: Label;
  price: number;
  discountedPrice: number;
}

export interface Maps {
  id: string;
  media: AbstractMedia;
  name: string;
  status: string;
}

interface ParkingMap {
  allowParkingSpot: boolean;
  maps?: Maps[];
  selectedMaps?: Record<string, string>;
}

export interface Parking {
  mapId?: string;
  x?: number;
  y?: number;
  spotNumber?: string;
}

export interface Vehicle {
  color?: string;
  description?: string;
  fuelLevel?: FuelLevel;
  medias?: AbstractMedia[];
  mileage?: number;
  parking?: Parking;
  parkingReturn?: Parking;
  registration?: string;
}

export interface MobilityDocument {
  id: string;
  accepted: boolean;
  name: Label;
  media?: Media;
  comment?: string;
  status?: DocumentStatus;
}

export interface ContractSignature {
  page: number;
  topLeft: Position;
  bottomRight: Position;
}

export interface MobilityContract {
  signatureBox?: ContractSignature;
  media?: AbstractMedia;
  status?: SignatureStatus;
}

export interface Mobility {
  id?: string;
  selfServiceId?: string;
  vehicle?: Vehicle
  contract?: MobilityContract;
  documents?: MobilityDocument[];
  customerAccepted?: boolean;
}

export interface VehicleCheck {
  id: string;
  name: string;
  items: Item[];
}

export interface SignatureBox {
  bottomRight: Position;
  page: number;
  topLeft: Position;
}

export interface WorkOrder {
  media: Media;
  signatureBox: SignatureBox;
  status: SignatureStatus;
}

export interface SelfService {
  id: string;
  customerComment?: string;
  repairOrder?: RepairOrder[];
  checklist?: Checklist;
  customerInfo?: CustomerInfo;
  dealer: Dealer;
  keyStatus?: KeyStatus;
  questionForm?: QuestionForm;
  medias?: MediaInterface[];
  signature?: Pick<MediaInterface, 'uploadId'>;
  valueAddedTax: number;
  finalInvoice?: FinalInvoice;
  interventionMedias?: AbstractMedia[];
  comment?: string;
  parkingMap?: ParkingMap;
  parking?: Parking;
  type: SelfServiceType;
  elapsedTime?: number;
  status: SelfServiceStatus;
  origin: SelfServiceOrigin;
  originContext?: {
    [origin in SelfServiceOrigin]?: {
      elapsedTime?: number;
      status: SelfServiceStatus;
    };
  };
  invoicePaymentMandatory?: boolean;
  slot?: OpenKeysSafeResponse;
  vehicleCheckAccepted?: boolean;
  courtesyVehicleCheckAccepted?: boolean;
  mobilityEnabled?: boolean;
  mobility?: Mobility
  breakdownServiceEmail?: string;
  vehicleCheck?: VehicleCheck;
  courtesyVehicleCheck?: VehicleCheck;
  workOrder?: WorkOrder;
}

export interface PhoneNumber {
  local: string;
  prefix: string;
}

export const isEqualPhoneNumber = (a: PhoneNumber, b: PhoneNumber) => a?.prefix === b?.prefix && a?.local === b?.local;
