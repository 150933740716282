import { createListenerMiddleware } from '@reduxjs/toolkit';
import { AppStartListening } from 'App/ListenerMiddleware';

import { SelfServiceType } from 'modules/selfServices/types/SelfService';

import { KeyType } from 'modules/kiosk/types/GetReadySelfServices';
import kioskApi from './service';
import * as actions from './actions';
import {
  getFreeSlotId,
  getKioskId,
  getReadySelfServiceSearchText,
  getSelfServiceById,
  getSlotIdBySelfServiceId,
  isKioskReady,
} from './selectors';

import { KeyStatus } from './types/Kiosk';
import {
  CloseKeysSafeRequest,
  CloseSafeAction,
  DoorsStatus,
  KeysSafeAction,
  OpenKeysSafeRequest,
} from './types/KeysSafe';

const listenerMiddleware = createListenerMiddleware();
const startAppListening = listenerMiddleware.startListening as AppStartListening;

startAppListening({
  actionCreator: actions.openKeysSafe,
  effect: async ({ payload }, { dispatch, getState, take }) => {
    const isReady = isKioskReady(getState());
    if (!isReady) {
      await take(actions.setIsKioskReady.match);
    }

    const state = getState();
    const kioskId = getKioskId(state);

    const {
      slotId, selfServiceId, selfServiceType, asAdmin, emergency, keyStatus,
    } = payload as KeysSafeAction;

    const isCheckout = selfServiceType === SelfServiceType.CHECK_OUT;

    const endpointPayload: OpenKeysSafeRequest = {
      emergency,
      kioskId,
      slotId,
      selfServiceId,
    };

    if (emergency) {
      await dispatch(kioskApi.endpoints.openSlotAsAdmin.initiate(endpointPayload, { forceRefetch: true }));
    } else if (asAdmin) {
      const newSlotId = getSlotIdBySelfServiceId(state, selfServiceId);
      const isMobilityCheckIn = selfServiceType === SelfServiceType.CHECK_IN && keyStatus === KeyStatus.NONE;
      endpointPayload.slotId = newSlotId;
      if ((isCheckout || isMobilityCheckIn) && !newSlotId) {
        endpointPayload.slotId = getFreeSlotId(state);
      }

      if (endpointPayload.slotId) {
        await dispatch(kioskApi.endpoints.openSlotAsAdmin.initiate(endpointPayload, { forceRefetch: true }));
      } else {
        await dispatch(actions.setDoorStatus(DoorsStatus.CLOSED));
      }
    } else {
      await dispatch(kioskApi.endpoints.openSlot.initiate(endpointPayload, { forceRefetch: true }));
    }
  },
});

startAppListening({
  actionCreator: actions.closeKeysSafe,
  effect: async ({ payload }, { dispatch, getState, take }) => {
    const isReady = isKioskReady(getState());
    if (!isReady) {
      await take(actions.setIsKioskReady.match);
    }

    const state = getState();
    const kioskId = getKioskId(state);

    const {
      slotId, selfServiceId, selfServiceType, asAdmin, hasAccepted, emergency, keyStatus, keyType,
    } = payload as CloseSafeAction;

    const isCheckout = selfServiceType === SelfServiceType.CHECK_OUT;
    const isMobilityCheckIn = selfServiceType === SelfServiceType.CHECK_IN && (keyStatus === KeyStatus.NONE || keyType === KeyType.MOBILITY);
    const canUnprepared = (isCheckout && keyType === KeyType.CUSTOMER && keyStatus === KeyStatus.IN_SLOT) || isMobilityCheckIn;

    const endpointPayload: CloseKeysSafeRequest = {
      kioskId,
      emergency,
      selfServiceId,
      slotId: getSlotIdBySelfServiceId(state, selfServiceId),
      status: isCheckout ? KeyStatus.RETRIEVED : KeyStatus.IN_SLOT,
    };

    try {
      if (emergency) {
        endpointPayload.slotId = slotId;
        await dispatch(kioskApi.endpoints.closeSlotAsAdmin.initiate(endpointPayload, { forceRefetch: true })).unwrap();
      } else if (asAdmin) {
        if (hasAccepted) {
          const { slotId: originalSlotId } = getSelfServiceById(state, selfServiceId);

          if (canUnprepared && originalSlotId) {
            await dispatch(kioskApi.endpoints.unprepareSlotAsAdmin.initiate(endpointPayload)).unwrap();
          } else {
            // Admin logic is reversed
            const isInSlotCheckout = (isCheckout && keyType !== KeyType.MOBILITY);
            const status = (isInSlotCheckout || isMobilityCheckIn) ? KeyStatus.IN_SLOT : KeyStatus.RETRIEVED;
            await dispatch(kioskApi.endpoints.updateSlotAsAdmin.initiate({ ...endpointPayload, status })).unwrap();
          }
        } else {
          await dispatch(kioskApi.endpoints.closeSlotAsAdmin.initiate(endpointPayload, {
            forceRefetch: true,
          })).unwrap();
        }
      } else {
        await dispatch(kioskApi.endpoints.updateSlot.initiate(endpointPayload));
      }

      await take((action) => actions.setDoorStatus.match(action) && action.payload === DoorsStatus.CLOSED);

      if (asAdmin) {
        if (!emergency) {
          const text = getReadySelfServiceSearchText(getState());
          await dispatch(kioskApi.endpoints.getReadySelfServices.initiate({ kioskId, type: selfServiceType, text }, { forceRefetch: true }));
        }
        await dispatch(kioskApi.endpoints.getKioskData.initiate({ kioskId }, { forceRefetch: true }));
      }
    } catch (e) {
      console.error(e);
    }
  },
});

export default listenerMiddleware;
