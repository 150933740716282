import React from 'react';
import { useSelector } from 'react-redux';
import { useField } from 'react-final-form';

import { AbstractMedia } from 'types/AbstractMedia';
import { Extra as ExtraType } from 'types/ExtrasForm';

import Card from 'components/ui/Card';

import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { useGetSelfServiceByIdQuery } from 'modules/selfServices/service';

import { computeTax } from 'utils/priceUtils';
import useLabelTranslation from 'hooks/useLabelTranslation';

import DefaultExtra from 'assets/images/default-extra.jpg';
import ExtraCardActions from './ExtraCardActions';

interface ExtraFormProps {
  name: string;
}

const Extra: React.FC<ExtraFormProps> = ({ name }) => {
  const { getLabelTranslation } = useLabelTranslation();

  const selfServiceId = useSelector(getSelectedSelfServiceId);

  const { data } = useGetSelfServiceByIdQuery({ id: selfServiceId });
  const { valueAddedTax: vat, dealer, checklists } = data ?? {};
  const { currencyIsoCode } = dealer;

  const { value: extra } = useField<ExtraType>(name, { subscription: { value: true } }).input;

  const extras = checklists?.flatMap(({ items }) => items?.flatMap(({ advices }) => advices)) ?? [];

  return (
    <Card
      {...extra}
      currency={currencyIsoCode}
      price={computeTax(extra.price, vat)}
      discountedPrice={computeTax(extra.discountedPrice, vat)}
      mediaPlaceholder={
          {
            id: '1',
            type: 'IMAGE',
            path: DefaultExtra,
          } as AbstractMedia
      }
      title={getLabelTranslation(extra.adviceName)}
      className="md:last:mb-5 kiosk:h-full"
    >
      <ExtraCardActions name={name} storedExtras={extras} />
    </Card>
  );
};

export default Extra;
