import React from 'react';

import { useDispatch } from 'hooks';
import sendMetricsAsync from 'modules/medias/thunk';
import { AbstractMedia } from 'types/AbstractMedia';

const TIMER: number = 5000;

interface UseVideoTimerResponse {
  onPlay: () => void;
  onPause: () => void;
  onEnded: () => void;
}

const useMediaMetrics = ({ mediaMetricUrl: url }: AbstractMedia): UseVideoTimerResponse => {
  const dispatch = useDispatch();
  const date = React.useRef(null);
  const timeoutId = React.useRef(null);

  const stopTimer = React.useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  }, []);

  const send = React.useCallback(async () => {
    if (url) {
      const time = Math.round((Date.now() - date.current) / 1000);
      await dispatch(sendMetricsAsync({ url, time })).unwrap();
      stopTimer();
    }
  }, [dispatch, url, stopTimer]);

  const runTimer = React.useCallback(() => {
    date.current = Date.now();
    stopTimer();
    timeoutId.current = setTimeout(() => {
      send();
      runTimer();
    }, TIMER);
  }, [send, stopTimer]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => stopTimer, []); // Short writing of "() => { return () => { // unmount }}""

  return React.useMemo(
    () => ({
      onEnded: stopTimer,
      onPause: send,
      onPlay: runTimer,
    }),
    [runTimer, send, stopTimer],
  );
};

export default useMediaMetrics;
