import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Notification from './types/Notification';

const initialState: Notification[] = [];

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => [
      ...state,
      {
        ...action.payload,
        id: action.payload.id ?? Date.now(),
      },
    ],
    removeNotification: (state) => {
      const [, ...newState] = state;
      return newState;
    },
  },
});

export default notificationsSlice;
