import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Note from 'assets/icons/note.svg';
import CheckmarkCircle from 'assets/icons/checkmarkCircle.svg';

import { SignatureStatus } from 'modules/selfServices/types/SelfService';
import { Document as DocumentType } from './types';

interface DocumentProps extends DocumentType {
  onClick?: (event: React.MouseEvent) => void;
  selected?: boolean;
}

const Document: React.FC<DocumentProps> = ({
  id, read, selected, onClick, title, status,
}) => (
  <div
    className={classNames('p-3 kiosk:p-6 rounded-lg items-center inline-flex cursor-pointer', {
      'bg-input-bg': selected,
    })}
    onClick={onClick}
    data-testid="document-wrapper"
  >
    {read && (
      <CheckmarkCircle
        className="fill-success-light w-8 h-8 kiosk:w-16 kiosk:h-16 mr-3 kiosk:mr-6"
        data-testid="read-success"
      />
    )}
    {!read && (
      <Note
        className="fill-gray-90 w-8 h-8 kiosk:w-16 kiosk:h-16 mr-3 kiosk:mr-6"
        data-testid="read-not-done"
      />
    )}
    <div data-testid={`document-${id}`}>
      <p className="text-gray-90 font-medium">
        <FormattedMessage {...title} />
      </p>
      <p className="text-gray-70">
        {status === SignatureStatus.UNSIGNED && <FormattedMessage id="signature.documents.mandatory" defaultMessage="Mandatory consultation" />}
        {status === SignatureStatus.SIGNED && <FormattedMessage id="signature.documents.alreadySigned" defaultMessage="Already signed" />}
      </p>
    </div>
  </div>
);

export default Document;
