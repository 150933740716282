import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import classNames, { Argument } from 'classnames';
import { isValidPhoneNumber } from 'libphonenumber-js/max';

import { useSelector } from 'hooks';
import { RootState } from 'App/Store';

import { getDealerId, getPrefixByDealerId } from 'modules/dealers/selectors';

import type { PhoneNumber } from 'modules/selfServices/types/SelfService';

import { Input } from './index';

interface PhoneNumberInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange' | 'onError' | 'className'> {
  className?: Argument;
  onChange: (phoneNumber: PhoneNumber) => void;
  onError?: (hasError: boolean) => void;
  value: PhoneNumber;
}

const validatePhoneNumber = (phoneNumber: string, defaultPrefix: string, intl: IntlShape) => {
  let isValid = false;

  try {
    isValid = isValidPhoneNumber(`+${phoneNumber}`, { defaultCallingCode: defaultPrefix });
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return (isValid ? undefined : intl.formatMessage({
    id: 'error.types.SO_PHONE_NUMBER_NOT_VALID',
    defaultMessage: 'Invalid phone number',
  }));
};

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  value, onChange, className, disabled, onError, required, ...rest
}) => {
  const intl = useIntl();

  const dealerId = useSelector(getDealerId);
  const defaultPrefix = useSelector((state: RootState) => getPrefixByDealerId(state, dealerId));

  const local = value?.local ?? '';
  const prefix = value?.prefix ?? defaultPrefix.toString();

  const [error, setError] = React.useState(() => {
    if (value?.local?.length > 0) {
      return validatePhoneNumber(`${prefix}${local}`, prefix, intl);
    }

    return undefined;
  });

  const handleChange = ({ prefix: prefixParam, local: localParam } : Partial<PhoneNumber>) => {
    const newLocal = localParam ?? local;
    const newPrefix = prefixParam ?? prefix;
    const newPhoneNumber = `${newPrefix}${newLocal}`;

    let newError = validatePhoneNumber(newPhoneNumber, newPrefix, intl);
    if (!newLocal) {
      newError = intl.formatMessage({ id: 'validation.required', defaultMessage: 'This field is mandatory' });
    }
    if (!newPrefix) {
      newError = intl.formatMessage({ id: 'validation.prefix', defaultMessage: 'The prefix is wrong' });
    }

    onChange?.({ prefix: newPrefix, local: newLocal });
    setError(newError);
    onError?.(Boolean(newError));
  };

  const handlePrefixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPrefix = event.target.value.replace('+', '');
    if (/^[0-9]{0,4}$/.test(newPrefix)) {
      handleChange({ prefix: newPrefix });
    }
  };

  const handleLocalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLocal = event.target.value;
    if (/^[0-9]{0,15}$/.test(newLocal)) {
      handleChange({ local: newLocal });
    }
  };

  const handleBlur = () => {
    onChange?.({
      prefix: prefix.replace(/^\+?(00)?/, ''),
      local: local.replace(/^0/, ''),
    });
  };

  React.useEffect(() => {
    if (error) {
      // Trigger onError when the component is mounted if the phone number is not valid
      onError?.(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames(className, 'flex')}>
      <div className="flex flex-col">
        <Input
          maxLength={4}
          error={Boolean(error)}
          required={required}
          value={`+${prefix}`}
          autoCorrect="off"
          spellCheck="false"
          data-testid="prefix-input"
          onChange={handlePrefixChange}
          disabled={disabled}
          className="w-24 kiosk:w-48 mr-5 kiosk:mr-10 flex-shrink-0"
          label={intl.formatMessage({ id: 'customerInfo.prefix', defaultMessage: 'Prefix' })}
        />
      </div>
      <div className="flex flex-col w-full">
        <Input
          type="tel"
          error={error}
          value={local}
          maxLength={15}
          className="w-full"
          pattern="^[0-9]{3,15}$"
          data-testid="phoneNumber-input"
          autoComplete="tel"
          disabled={disabled}
          label={intl.formatMessage({ id: 'customerInfo.phoneNumber', defaultMessage: 'Phone number' })}
          {...rest}
          onChange={handleLocalChange}
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
};

export default PhoneNumberInput;
