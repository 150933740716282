import Flow from 'types/Flow';
import { isVisibleStep } from 'components/SelfServices/Steps/utils';
import { SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';
import { ConnectionTypeLogin } from 'modules/dealers/types/ContextResponse';

import {
  FINAL_INSTRUCTIONS, KIOSK_LOCATION, SHAREBOX_KEY, SIGNATURE,
} from 'components/SelfServices/Steps/common';

import { VEHICLE_CHECK_QR_CODE, BASKET } from '../../onSite';
import COMMON_KIOSK_CHECK_IN_STEPS, { CommonKioskCheckInSteps } from '../common';

export type ShareboxI32CheckInSteps =
  CommonKioskCheckInSteps
  | SelfServiceSteps.SIGNATURE
  | SelfServiceSteps.KIOSK_LOCATION
  | SelfServiceSteps.SHAREBOX_KEY
  | SelfServiceSteps.VEHICLE_CHECK_QR_CODE
  | SelfServiceSteps.FINAL_INSTRUCTIONS;

// Alias 'INDOOR' flow
const SHAREBOX_I32_CHECK_IN_STEPS: Flow<ShareboxI32CheckInSteps> = {
  ...COMMON_KIOSK_CHECK_IN_STEPS,
  SIGNATURE: {
    ...SIGNATURE,
    previous: BASKET.key,
    next: SHAREBOX_KEY.key,
    data: {
      ...SIGNATURE.data,
      isFinalizeStep: true,
    },
  },
  // This one is shown in 'remote' flow, i.e. when the user is using their phone
  KIOSK_LOCATION: {
    ...KIOSK_LOCATION,
    key: SHAREBOX_KEY.key,
    previous: SIGNATURE.key,
    next: VEHICLE_CHECK_QR_CODE.key,
    data: {
      ...KIOSK_LOCATION.data,
      isVisible: (selfService, config) => (
        isVisibleStep(KIOSK_LOCATION, selfService, config) && config.connectionType === ConnectionTypeLogin.TOKEN
      ),
    },
  },
  // This one is shown when the user is using the kiosk
  SHAREBOX_KEY: {
    ...SHAREBOX_KEY,
    previous: SIGNATURE.key,
    next: VEHICLE_CHECK_QR_CODE.key,
  },
  VEHICLE_CHECK_QR_CODE: { ...VEHICLE_CHECK_QR_CODE, previous: SHAREBOX_KEY.key, next: FINAL_INSTRUCTIONS.key },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: VEHICLE_CHECK_QR_CODE.key },
};

export default SHAREBOX_I32_CHECK_IN_STEPS;
