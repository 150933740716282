// eslint-disable-next-line import/prefer-default-export
export enum SelfServiceSteps {
  BASKET = 'BASKET',
  BREAKDOWN_INFO = 'BREAKDOWN_INFO',
  COURTESY_VEHICLE = 'COURTESY_VEHICLE',
  CUSTOMER_INFO = 'CUSTOMER_INFO',
  EXTRAS = 'EXTRAS',
  FINAL_INSTRUCTIONS = 'FINAL_INSTRUCTIONS',
  FINAL_INVOICE = 'FINAL_INVOICE',
  PARTTEAM_KEY = 'PARTTEAM_KEY',
  SHAREBOX_KEY = 'SHAREBOX_KEY',
  KIOSK_LOCATION = 'KIOSK_LOCATION',
  KIOSK_USAGE = 'KIOSK_USAGE',
  MOBILITY_CONTRACT = 'MOBILITY_CONTRACT',
  MOBILITY_DOCUMENTS = 'MOBILITY_DOCUMENTS',
  MOBILITY_PARKING = 'MOBILITY_PARKING',
  PARKING = 'PARKING',
  QUESTIONS = 'QUESTIONS',
  REPAIR_ORDER = 'REPAIR_ORDER',
  SIGNATURE = 'SIGNATURE',
  SUMMARY = 'SUMMARY',
  VEHICLE_CHECK = 'VEHICLE_CHECK',
  VEHICLE_CHECK_QR_CODE = 'VEHICLE_CHECK_QR_CODE',
}

export enum SelfServiceAPISteps {
  APPOINTMENT_SUMMARY = 'APPOINTMENT_SUMMARY',
  BASKET = 'BASKET',
  CONTACT_INFO = 'CONTACT_INFO',
  COURTESY_CAR_INFO = 'COURTESY_CAR_INFO',
  COURTESY_CAR_PARKING = 'COURTESY_CAR_PARKING',
  CUSTOMER_CAR_INFO = 'CUSTOMER_CAR_INFO',
  DIRECTIONS_TO_KIOSK = 'DIRECTIONS_TO_KIOSK',
  EXTRAS_ADDITIONAL_SERVICES = 'EXTRAS_ADDITIONAL_SERVICES',
  FINAL_INSTRUCTIONS = 'FINAL_INSTRUCTIONS',
  FINAL_INVOICE = 'FINAL_INVOICE',
  GUIDED_VEHICLE_CHECK = 'GUIDED_VEHICLE_CHECK',
  INSTRUCTIONS = 'INSTRUCTIONS',
  ISSUE_DESCRIPTION = 'ISSUE_DESCRIPTION',
  KEYS = 'KEYS',
  KIOSK_USAGE_QUESTION = 'KIOSK_USAGE_QUESTION',
  MOBILITY = 'MOBILITY',
  NONE = 'NONE',
  PARKING = 'PARKING',
  QUESTIONS = 'QUESTIONS',
  REPAIR_ORDER = 'REPAIR_ORDER',
  REVIEW_PREVIOUS_ANSWERS = 'REVIEW_PREVIOUS_ANSWERS',
  SIGNATURE = 'SIGNATURE',
  SUMMARY_SIGNATURE = 'SUMMARY_SIGNATURE',
  VEHICLE_CHECK_QUESTION = 'VEHICLE_CHECK_QUESTION',
}
