import { Slot } from './Slot';

export enum KeyStatus {
  NONE = 'NONE',
  IN_SLOT = 'IN_SLOT',
  RETRIEVED = 'RETRIEVED',
}

export enum KioskStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  INACTIVE = 'INACTIVE',
}

export interface Kiosk {
  id: string;
  name: string;
  slots: Slot[];
  status: KioskStatus;
  available: boolean;
}
