import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getNotifications } from 'modules/notifications/selectors';
import { removeNotification } from 'modules/notifications/actions';

import { Alert } from 'components/ui';

const NOTIFICATION_TIMEOUT = 5000;

const Notifications: React.FC = () => {
  const dispatch = useDispatch();

  const currentNotifications = useSelector(getNotifications);

  const handleNotificationEnd = React.useCallback(() => dispatch(removeNotification()), []);

  return (
    <div className="fixed top-12 right-4 flex flex-col h-max z-50">
      {currentNotifications.map(({
        id, title, description, collapse, type,
      }) => (
        <Alert
          key={id}
          type={type}
          title={title}
          description={description}
          collapse={collapse}
          onClose={handleNotificationEnd}
          duration={NOTIFICATION_TIMEOUT}
        />
      ))}
    </div>
  );
};

export default Notifications;
