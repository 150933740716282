/* eslint-disable react/style-prop-object */
import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import type PageBaseProps from 'types/PageBase';

import { b } from 'utils/i18nUtils';
import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { isInvoicePaymentDelayable } from 'modules/selfServices/selectors';
import {
  useGetSelfServiceByIdQuery,
  useUpdateSelfServiceMutation,
} from 'modules/selfServices/service';

import PageFooter from 'components/ui/PageFooter';
import FinalInvoiceCard from './FinalInvoiceCard';

const FinalInvoiceTab: React.FC<PageBaseProps> = ({
  onNext,
  onPrev,
  isPenultimate,
}) => {
  const selfServiceId = useSelector(getSelectedSelfServiceId);
  const canDelayedPayment = useSelector(isInvoicePaymentDelayable);

  const [, { isLoading }] = useUpdateSelfServiceMutation({
    fixedCacheKey: 'UPDATE/SELF_SERVICE',
  });
  const { data: selfService } = useGetSelfServiceByIdQuery({
    id: selfServiceId,
  });

  const { finalInvoice, dealer } = selfService;
  const { payable: isInvoicePayable } = finalInvoice;

  const description = isInvoicePayable
    ? { id: 'page.finalInvoice.introduction', defaultMessage: 'In order to complete your <b>check-out</b>, we kindly ask you to <b>pay the following invoice</b> before picking-up your vehicle.' }
    : { id: 'page.finalInvoice.information', defaultMessage: 'For information, please find below the invoice for your appointment.' };

  return (
    <div className="main-content">
      <div className="flex flex-col items-center">
        <FormattedMessage
          id="page.finalInvoice.title"
          defaultMessage="Final invoice"
          tagName="h1"
        />
        <p className="text-center mt-5 text-gray-70">
          <FormattedMessage
            {...description}
            // eslint-disable-next-line max-len
            values={{ b }}
          />
        </p>
      </div>

      <div
        className={classNames('content kiosk:!max-w-2xl', {
          'pb-6': isInvoicePayable,
        })}
      >
        <FinalInvoiceCard
          onSkip={onNext}
          finalInvoice={finalInvoice}
          skipable={canDelayedPayment}
          unpayable={!isInvoicePayable}
          selfServiceId={selfServiceId}
          currencyIsoCode={dealer.currencyIsoCode}
        />
      </div>
      <PageFooter
        onNext={onNext}
        onPrev={onPrev}
        loading={isLoading}
        isPenultimate={isPenultimate}
        hideNextButton={isInvoicePayable}
      />
    </div>
  );
};

export default FinalInvoiceTab;
