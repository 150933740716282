import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';

import { useSelector } from 'hooks';
import { isDevelopment } from 'utils/appUtils';
import useQrCodeScanner from 'hooks/useQrCodeScanner';

import { SelfServiceOrigin, SelfServiceType } from 'modules/selfServices/types/SelfService';

import { useGetContextQuery } from 'modules/dealers/service';
import { useCheckQRCodeTokenMutation } from 'modules/auth/service';

import socketSlice from 'modules/sockets/reducer';

import {
  isKioskFull as isKioskFullSelector,
  isBreakdownAvailable as isBreakdownAvailableSelector,
} from 'modules/dealers/selectors';
import { isKioskOffline as isKioskOfflineSelector } from 'modules/healthCheck/selectors';

import Popup from 'components/ui/Popup';
import { ButtonType } from 'components/ui/Button';
import { Button, LanguageSelect, Spinner } from 'components/ui';

import NoWifi from 'assets/icons/noWifi.svg';
import CheckInLogo from 'assets/icons/checkIn.svg';
import CheckOutLogo from 'assets/icons/checkOut.svg';
import BreakdownLogo from 'assets/icons/breakdown.svg';

import './index.css';

const Home: React.FC = () => {
  useQrCodeScanner();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  const [showFullPopup, setShowFullPopup] = React.useState(false);

  const isKioskFull = useSelector(isKioskFullSelector);
  const isKioskOffline = useSelector(isKioskOfflineSelector);
  const isBreakdownAvailable = useSelector(isBreakdownAvailableSelector);

  const { data: contextData, isFetching } = useGetContextQuery();
  const {
    dealerName, brand, type, kioskId, kioskName, origin, kioskBackgroundMedia, backgroundMedia,
  } = contextData ?? {};
  const [login] = useCheckQRCodeTokenMutation();

  const isCheckIn = type === SelfServiceType.CHECK_IN;
  const isCheckOut = type === SelfServiceType.CHECK_OUT;

  const shouldShowCheckIn = (isCheckIn || !type);
  const shouldShowCheckOut = (isCheckOut || !type);
  const shouldShowBreakdown = isBreakdownAvailable && origin !== SelfServiceOrigin.HOME;

  const backgroundMediaPath = kioskBackgroundMedia?.path || backgroundMedia?.path;
  const hasPersonalizedBackground = Boolean(backgroundMediaPath);

  const handleCloseFullPopup = React.useCallback(() => setShowFullPopup(false), []);

  const handleClick = (selectedType: SelfServiceType) => () => {
    if (selectedType === SelfServiceType.BREAKDOWN && isKioskFull) {
      setShowFullPopup(true);
    } else {
      navigate(`/login${search}`, {
        state: {
          type: selectedType,
        },
      });
    }
  };

  const handlePrompt = () => {
    // eslint-disable-next-line no-alert
    const qrCodeToken = prompt('Please enter your QRCode token:');
    login({ qrCodeToken, kioskId });
  };

  React.useEffect(() => {
    dispatch(socketSlice.actions.closeSocket());
  }, [dispatch]);

  const homeTitle = kioskName || dealerName;

  const styleAttribute = React.useMemo(
    () => (hasPersonalizedBackground ? { '--background-url': `url(${backgroundMediaPath})` } as React.CSSProperties : {}),
    [hasPersonalizedBackground, backgroundMediaPath],
  );

  return (
    <>
      {isFetching && (
        <div className="flex items-center justify-center min-h-screen">
          <Spinner />
        </div>
      )}
      {!isFetching && (
        <div
          style={styleAttribute}
          className={classNames('flex flex-col items-center justify-start bg-cover bg-center homePage', { 'bg-home': !hasPersonalizedBackground, 'bg-[image:var(--background-url)]': hasPersonalizedBackground })}
        >
          <div className="flex justify-end relative w-full z-40 px-7 kiosk:px-12">
            {isKioskOffline && (
              <div className="absolute justify-center items-center flex w-full py-10 left-0">
                <div className="rounded-full text-center text-white bg-gray-50 bg-opacity-20 py-5 pl-5 pr-10 text-2xl font-bold flex items-center">
                  <div className="rounded-full w-10 h-10 bg-warn-bg relative flex justify-center items-center mr-5">
                    <NoWifi className="absolute w-6 aspect-square" />
                  </div>
                  <FormattedMessage id="offline.feedback" defaultMessage="No internet connection" />
                </div>
              </div>
            )}
            {isDevelopment && (
              <div className="hidden kiosk:block py-3 kiosk:py-10">
                <Button
                  type={ButtonType.PRIMARY}
                  onClick={handlePrompt}
                  className="kiosk:!py-6 kiosk:!px-6 mr-6"
                >
                  <div className="absolute -top-1 -left-3">
                    <span className="absolute block px-4 bg-error rounded-full -rotate-30 text-white !text-lg">dev</span>
                  </div>
                  <Cog6ToothIcon className="w-8 h-8" />
                </Button>
              </div>
            )}
            <LanguageSelect className="text-white bg-opacity-20" />
          </div>

          <div className="flex flex-col justify-between grow w-full max-w-7xl z-30">
            <div className="w-full max-w-7xl m-auto text-white mt-8 grow sm:grow-0 px-7">
              <div className="flex items-center flex-col">
                {brand && (
                  <div className="w-24 h-24 md:w-32 md:h-32" data-testid="logo">
                    <div className="bg-white p-3 w-full h-full flex mask-image">
                      <img src={brand.logoPathMobile} alt={brand.name} className="m-auto logo-home" />
                    </div>
                  </div>
                )}
                {homeTitle && (
                  <h1 className={classNames(
                    'home drop-shadow-md opacity-70 uppercase font-bold',
                    { 'truncate w-full': kioskName },
                  )}
                  >
                    {homeTitle}
                  </h1>
                )}
                <h2 className="home drop-shadow-md">
                  <FormattedMessage
                    id="home.title"
                    // eslint-disable-next-line max-len
                    defaultMessage="{type, select, CHECK_IN {Ready for your check-in} CHECK_OUT {Ready for your check-out} other {How can we help you} }?"
                    values={{ type }}
                  />
                </h2>
              </div>
            </div>
            <div
              className="flex flex-col items-start w-full m-auto pt-6 pb-12 max-w-4xl kiosk:w-full px-7 kiosk:px-12 kiosk:mb-52"
            >
              {shouldShowCheckIn && (
                <Button
                  analytics={{
                    category: 'Login',
                    action: 'CHECK_IN login',
                    label: 'Login started',
                  }}
                  disabled={isKioskOffline}
                  testId="check-in-button"
                  type={ButtonType.PRIMARY}
                  onClick={handleClick(SelfServiceType.CHECK_IN)}
                  className={classNames('flex items-center justify-center w-full !drop-shadow-md', { 'mb-6': shouldShowCheckOut })}
                >
                  <CheckInLogo className="w-12 mr-8 fill-gray-90" />
                  <FormattedMessage id="home.checkIn" defaultMessage="Check-in" />
                </Button>
              )}
              {shouldShowCheckOut && (
                <Button
                  analytics={{
                    category: 'Login',
                    action: 'CHECK_OUT login',
                    label: 'Login started',
                  }}
                  disabled={isKioskOffline}
                  testId="check-out-button"
                  type={ButtonType.PRIMARY}
                  className="flex items-center justify-center w-full !drop-shadow-md"
                  onClick={handleClick(SelfServiceType.CHECK_OUT)}
                >
                  <CheckOutLogo className="w-12 mr-8 fill-gray-90" />
                  <FormattedMessage id="home.checkOut" defaultMessage="Check-out" />
                </Button>
              )}
              {shouldShowBreakdown && (
                <>
                  <div className="h-px bg-white my-12 opacity-30 mx-auto w-[calc(100%-6rem)] !drop-shadow-md" />
                  <Button
                    analytics={{
                      category: 'Login',
                      action: 'BREAKDOWN login',
                      label: 'Login started',
                    }}
                    testId="breakdown-button"
                    type={ButtonType.PRIMARY}
                    disabled={isKioskOffline}
                    disabledStyle={isKioskFull}
                    className="flex items-center justify-center w-full !leading-12 !drop-shadow-md"
                    onClick={handleClick(SelfServiceType.BREAKDOWN)}
                  >
                    <BreakdownLogo className="w-12 mr-8 fill-gray-90" />
                    <FormattedMessage id="home.breakdown" defaultMessage="Breakdown service" />
                  </Button>
                </>
              )}
              {showFullPopup && (
                <Popup onClose={handleCloseFullPopup}>
                  <h2 className="py-16 text-center">
                    <FormattedMessage
                      id="kiosk.fullWarning"
                      defaultMessage="The kiosk is full. It is no longer possible to drop keys."
                      tagName="h2"
                    />
                  </h2>
                </Popup>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
