// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/mask_1.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerPicture {
  -webkit-mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  -webkit-mask-repeat: 'no-repeat';
  mask-repeat: 'no-repeat';
  -webkit-mask-position: center bottom;
  mask-position: center bottom;
  -webkit-mask-size: cover;
  mask-size: cover;
  width: 100%;
  /* Fix iPhone 12 Safari showing a thin line at the bottom. */
  border-bottom: 1px solid white;
}
`, "",{"version":3,"sources":["webpack://./src/components/Error/stylesError.css"],"names":[],"mappings":"AAAA;EACE,2DAAyD;EACzD,mDAAiD;EACjD,gCAAgC;EAChC,wBAAwB;EACxB,oCAAoC;EACpC,4BAA4B;EAC5B,wBAAwB;EACxB,gBAAgB;EAChB,WAAW;EACX,4DAA4D;EAC5D,8BAA8B;AAChC","sourcesContent":[".headerPicture {\n  -webkit-mask-image: url('../../assets/images/mask_1.png');\n  mask-image: url('../../assets/images/mask_1.png');\n  -webkit-mask-repeat: 'no-repeat';\n  mask-repeat: 'no-repeat';\n  -webkit-mask-position: center bottom;\n  mask-position: center bottom;\n  -webkit-mask-size: cover;\n  mask-size: cover;\n  width: 100%;\n  /* Fix iPhone 12 Safari showing a thin line at the bottom. */\n  border-bottom: 1px solid white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
