import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CookiesSettings } from 'modules/auth/types/InitialState';

export const PRIVACY_POLICY_URL = 'https://www.fleetback.com/privacy-policy';

type CookieConfig = {
  disabled: boolean,
  default: boolean,
  key: CookiesSettings,
  label: React.ReactNode,
  description: React.ReactNode,
};

export const COOKIES_CONFIG:Record<CookiesSettings, CookieConfig> = {
  [CookiesSettings.ESSENTIAL]: {
    disabled: true,
    default: true,
    key: CookiesSettings.ESSENTIAL,
    label: <FormattedMessage id="cookies.essentialTitle" defaultMessage="Essentials" />,
    description: <FormattedMessage id="cookies.essentialDescription" defaultMessage="We collect and process essential data useful for you to browse Fleetback, use its features, for the technical performance of the website, as well as for security purposes." />,
  },
  [CookiesSettings.ANALYTICS]: {
    disabled: false,
    default: false,
    key: CookiesSettings.ANALYTICS,
    label: <FormattedMessage id="cookies.analyticsTitle" defaultMessage="Web performance and analytics" />,
    description: <FormattedMessage id="cookies.analyticsDescription" defaultMessage="We use this data to improve the performance of our websites and services, as well as to conduct user experience and business-relevant analysis. These services collect information about how you use the website, like which pages you visited and which links you clicked on. Their sole purpose is to improve website functions. This includes cookies from third-party analytics services as long as the cookies are for the exclusive use of Fleetback." />,
  },
};

export const COOKIE_PANELS = Object.values(COOKIES_CONFIG);

export const DEFAULT_ENABLED_COOKIES = COOKIE_PANELS.reduce((acc, { key, default: selected }) => {
  if (selected) {
    acc.push(key);
  }
  return acc;
}, []);
