import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import InitialState from './types/InitialState';
import AddTimePayload from './types/AddTimePayload';

const elapsedTimeSlice = createSlice({
  name: 'elapsedTime',
  initialState: { timeByStep: {} } as InitialState,
  reducers: {
    addTime(state, { payload }: PayloadAction<AddTimePayload>) {
      return {
        timeByStep: {
          ...state.timeByStep,
          [payload.key]: payload.time + (state.timeByStep[payload.key] ?? 0),
        },
      };
    },
  },
});

export default elapsedTimeSlice;
