import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field, FormRenderProps, useField } from 'react-final-form';

import { useSelector } from 'hooks';
import ExtraStatus from 'types/ExtraStatus';
import { getHyperlink } from 'utils/linkUtils';
import { formatUndefined } from 'utils/formUtils';
import { getSelectedSelfService } from 'modules/selfServices/selectors';
import { getGeneralTermsConditionsLink } from 'modules/dealers/selectors';

import type PageBaseProps from 'types/PageBase';
import type { Extra, ExtrasForm as ExtrasFormType } from 'types/ExtrasForm';

import Content from './Content';
import Switch from '../../ui/Switch';
import PageFooter from '../../ui/PageFooter';
import Button from '../../ui/Button';

const Step: React.FC<FormRenderProps<ExtrasFormType> & Pick<PageBaseProps, 'onPrev' | 'shouldDisplayBackButton'>> = ({
  onPrev,
  handleSubmit,
  hasValidationErrors,
  shouldDisplayBackButton,
}) => {
  const selfService = useSelector(getSelectedSelfService);
  const generalTermsConditionsLink = useSelector(getGeneralTermsConditionsLink);

  const { value: extras = [] } = useField<Extra[]>('extras', { subscription: { value: true }, format: formatUndefined }).input;

  const isBasketEmpty = React.useMemo(
    () => {
      const hasAcceptedExtras = extras.some(({ status }) => status === ExtraStatus.ACCEPTED);
      const hasRepairOrder = selfService.repairOrder?.length > 0;
      return !hasAcceptedExtras && !hasRepairOrder;
    },
    [extras, selfService.repairOrder],
  );

  return (
    <div className="main-content">
      <div className="content">
        <p className="text-center text-low">
          <FormattedMessage
            id="basket.indication"
            defaultMessage="Summary of your basket"
          />
        </p>
        <Content />
        {!isBasketEmpty && (
          <div className="mt-l flex flex-col">
            <Field name="basketConsent" subscription={{ value: true }}>
              {({ input }) => (
                <Switch
                  data-testid="consent"
                  checked={input.value}
                  onChange={input.onChange}
                  labelClassName="text-xxs text-low"
                >
                  <FormattedMessage
                    id="basket.footer.basketConsent"
                    // eslint-disable-next-line max-len
                    defaultMessage="I have consented to the execution of the contract before the withdrawal period ends and acknowledge that the right to withdraw will expire upon the commencement of the contract’s execution."
                  />
                </Switch>
              )}
            </Field>
            <Field name="basketGTC" subscription={{ value: true }}>
              {({ input }) => (
                <Switch
                  className="mt-xs"
                  data-testid="GTC"
                  checked={input.value}
                  onChange={input.onChange}
                  labelClassName="text-xxs text-low"
                >
                  <FormattedMessage
                    id="basket.footer.basketGTC"
                    // eslint-disable-next-line max-len
                    defaultMessage="I accept the <gtc>general terms and conditions</gtc>."
                    values={{
                      gtc: getHyperlink({
                        'data-testid': 'general-terms',
                        href: generalTermsConditionsLink,
                      }),
                    }}
                  />
                </Switch>
              )}
            </Field>
          </div>
        )}
      </div>
      <PageFooter
        hideNextButton
        onPrev={onPrev}
        shouldDisplayBackButton={shouldDisplayBackButton}
      >
        <Button
          testId="next-step"
          onClick={handleSubmit}
          disabledStyle={hasValidationErrors}
          className="max-w-md w-full kiosk:inline-flex kiosk:justify-center kiosk:max-w-96 !py-0 h-normal-button text-center flex flex-col justify-center items-center leading-button"
        >
          <div className="line-clamp-2">
            {isBasketEmpty && <FormattedMessage id="steps.next" defaultMessage="Next" />}
            {!isBasketEmpty && <FormattedMessage id="basket.next" defaultMessage="Order with Payment Obligation" />}
          </div>
        </Button>
      </PageFooter>
    </div>
  );
};

export default Step;
