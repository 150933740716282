import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { getExtras } from 'utils/formUtils';
import { getSelectedSelfService } from 'modules/selfServices/selectors';

import { RootState } from '../../App/rootReducer';

const getState = (state: RootState) => state.basket;

export const isModalOpen = createDraftSafeSelector(getState, ({ modalOpen }) => modalOpen);

export const shouldDisplayBasketModal = createDraftSafeSelector(
  isModalOpen,
  getSelectedSelfService,
  (isOpen, selfService) => isOpen || getExtras(selfService)?.length > 0 || selfService?.repairOrder?.length > 0,
);
