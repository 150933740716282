import React from 'react';
import { useField } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChatBubbleBottomCenterIcon } from '@heroicons/react/24/outline';

import { formatUndefined } from 'utils/formUtils';

import Textarea from '../ui/Textarea';
import CardActions from '../ui/Card/CardActions';
import Button, { ButtonType } from '../ui/Button';
import { YesNoChoice } from '../../types/Question';

const QuestionCardActions: React.FC<{ name: string }> = ({ name }) => {
  const intl = useIntl();

  const { value: comment, onChange: handleChangeComment } = useField<string>(`${name}.comment`, {
    subscription: { value: true },
    format: formatUndefined,
  }).input;

  const { value: status, onChange: handleChangeStatus } = useField<YesNoChoice, HTMLElement>(
    `${name}.response`,
    { subscription: { value: true } },
  ).input;

  const [shouldShowComment, setShouldShowComment] = React.useState<boolean>(Boolean(comment));

  const handleClick = React.useCallback(() => setShouldShowComment(true), []);

  const handleCommentChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => handleChangeComment(event.target.value),
    [handleChangeComment],
  );

  const handleAcceptQuestion = React.useCallback((accept: boolean) => {
    handleChangeStatus(accept ? YesNoChoice.YES : YesNoChoice.NO);
  }, [handleChangeStatus]);

  return (
    <div>
      <CardActions onClick={handleAcceptQuestion} refusePrimary={status && status === YesNoChoice.YES} />
      {!shouldShowComment && (
        <>
          <div className="w-full h-px bg-border-default mt-3" />
          <Button
            type={ButtonType.LIGHT}
            className="w-full my-1"
            testId="AddComment"
            onClick={handleClick}
          >
            <div className="flex justify-center items-center kiosk:text-xl">
              <ChatBubbleBottomCenterIcon className="h-5 w-5 mr-3" />
              <FormattedMessage id="repairOrder.addComment" defaultMessage="Add comment" />
            </div>
          </Button>
        </>
      )}
      {shouldShowComment && (
        <>
          <div className="w-full h-px bg-border-default mt-5" />
          <Textarea
            label={intl.formatMessage({
              id: 'repairOrder.comment',
              defaultMessage: 'Comment',
            })}
            inputClassName="mb-0"
            value={comment}
            data-testid="comment"
            rows={3}
            onChange={handleCommentChange}
          />
        </>
      )}
    </div>
  );
};

export default QuestionCardActions;
