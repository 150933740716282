import React from 'react';
import { addSeconds } from 'date-fns';
import { Outlet, useLocation } from 'react-router-dom';

import useInactivity from 'hooks/useInactivity';
import { useDispatch, useSelector } from 'hooks';

import { setSchedule } from 'modules/auth/actions';
import { hasScheduledTime } from 'modules/auth/selectors';
import { ScheduleTypeEnum } from 'modules/auth/types/InitialState';
import { hasError as hasErrorSelector, isIdle } from 'modules/kiosk/selectors';
import { INACTIVITY_ADMIN, INACTIVITY_TIME_TO_LOGOUT } from 'modules/auth/constants';

import Header from './Header';
import Footer from './Footer';
import InactivityModal from './InactivityModal';

const AdminLayout = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const doorsIdleState = useSelector(isIdle);
  const hasError = useSelector(hasErrorSelector);
  const displayInactivityModal = useSelector((state) => hasScheduledTime(state, ScheduleTypeEnum.LOGOUT));

  const handleShowInactivityModal = React.useCallback(() => {
    dispatch(
      setSchedule({
        [ScheduleTypeEnum.LOGOUT]: addSeconds(Date.now(), INACTIVITY_TIME_TO_LOGOUT).toISOString(),
      }),
    );
  }, [dispatch]);

  const showNavigation = doorsIdleState && !hasError;

  const { restart } = useInactivity({
    seconds: INACTIVITY_ADMIN,
    onInactive: handleShowInactivityModal,
    paused: !doorsIdleState,
  });

  React.useEffect(() => {
    if (!displayInactivityModal && doorsIdleState) {
      restart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayInactivityModal, doorsIdleState]);

  const hideBackButton = location.pathname === '/admin';
  return (
    <div className="flex flex-col overflow-hidden">
      <Header disableNavigation={!showNavigation} />
      <Outlet />
      {showNavigation && <Footer hideBackButton={hideBackButton} />}
      {displayInactivityModal && <InactivityModal />}
    </div>
  );
};

export default AdminLayout;
