import React from 'react';

import { downloadURL, getFilenameAndExtension } from 'utils/downloadUtils';

import PreviewIcon from 'assets/icons/preview.svg';
import DownloadIcon from 'assets/icons/download.svg';

import type { AbstractMedia } from '../../types/AbstractMedia';

const DocumentLine: React.FC<{
  document: AbstractMedia;
  hideDownload?: boolean;
  onPreview?: () => void;
}> = ({ document, hideDownload, onPreview }) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { name, extension } = getFilenameAndExtension(document.filename);

  const handleDownload = React.useCallback(() => {
    downloadURL(document.path, document.filename);
  }, [document]);

  const displayDownloadBtn = !hideDownload;
  const displayPreviewBtn = typeof onPreview === 'function';

  return (
    <div
      key={document.id}
      className="border-t-2 border-input-bg first:border-0 first:pt-0"
    >
      <div className="flex justify-between items-center w-full">
        <span className="flex overflow-hidden font-medium text-base text-gray-70 max-w-min">
          <span className="overflow-hidden text-ellipsis whitespace-nowrap">{name}</span>
          <span className="whitespace-nowrap shrink-0">{extension}</span>
        </span>
        {displayDownloadBtn && (
          <div
            onClick={handleDownload}
            data-testid="download-document"
            className="cursor-pointer pl-2"
          >
            <DownloadIcon className="w-8 h-8" />
          </div>
        )}
        {displayPreviewBtn && (
          <div
            className="cursor-pointer pl-2"
            data-testid="preview-document"
            onClick={onPreview}
          >
            <PreviewIcon className="w-8 h-8 fill-gray-90" />
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentLine;
