import { StateFromReducersMapObject, combineReducers } from '@reduxjs/toolkit';

import ui from './ui';
import data from './data';
import doors from './doors';
import slots from './slots';
import selfServices from './selfServices';

const reducer = {
  ui,
  data,
  doors,
  slots,
  selfServices,
};

export default combineReducers(reducer);
export type InitialState = StateFromReducersMapObject<typeof reducer>;
