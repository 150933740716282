import ReactGA from 'react-ga4';
import { jwtDecode } from 'jwt-decode';
import { push } from 'redux-first-history';
import { Props as MessageProps } from 'react-intl/src/components/message';

import api from 'service';
import { br } from 'utils/i18nUtils';
import dealersApi from 'modules/dealers/service';
import selfServicesApi from 'modules/selfServices/service';

import {
  LoginResponse,
  LoginMFAResponse,
  DecodedAdminToken,
  SendMFACodeResponse,
  SendAdminCodeResponse,
  GetMFAConnectionResponse,
} from 'modules/auth/types/LoginResponse';
import {
  ChannelEnum,
  CheckQRCodeTokenRequest,
  GetMFAConnectionRequest,
  LoginMFARequest,
  LoginRequest,
  SendAdminCodeRequest,
  SendMFACodeRequest,
} from 'modules/auth/types/LoginRequest';
import { RootState } from 'App/Store';
import { ErrorTypeEnum } from 'types/Error';
import { Token } from 'modules/auth/types/Token';
import { InitialState, MFA } from 'modules/auth/types/InitialState';
import { NotificationType } from 'modules/notifications/types/Notification';
import SelfServiceStatus from 'modules/selfServices/types/SelfServiceStatus';
import { SelfServiceOrigin, SelfServiceType } from 'modules/selfServices/types/SelfService';

import {
  ADMIN, KIOSK, LOGIN, LOGIN_MFA,
} from 'constants/url';

import { setQrCodeToken } from 'modules/auth/actions';
import { addNotification } from 'modules/notifications/actions';

import { getSearch, isAdminLoginPage as isAdminLoginPageSelector } from 'modules/router/selectors';

import { canCollectAnalyticsData, getMFAData, getSelectedSelfServiceId } from './selectors';

const RedirectPathConfig = {
  [SelfServiceType.CHECK_IN]: '/checkin',
  [SelfServiceType.CHECK_OUT]: '/checkout',
  [SelfServiceType.BREAKDOWN]: '/breakdown',
};

interface ErrorData {
  type?: ErrorTypeEnum;
  status: string;
  message: string;
  data?: Record<string, string | number | boolean | React.ReactNode>;
}

const createErrorNotification = (
  {
    type, status, message, data,
  }: ErrorData,
  title: MessageProps = {
    id: 'login.error.title',
    defaultMessage: 'Login failed',
  },
) => addNotification({
  title,
  description: {
    id: type ? `error.types.${type}` : `login.error.description.${status}`,
    defaultMessage: message ?? 'Unknown error',
    values: { br, ...data },
  },
  type: NotificationType.ERROR,
});

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<InitialState, LoginRequest>({
      query: ({ registration, policies }) => ({
        method: 'POST',
        url: LOGIN,
        body: { registration, policies },
      }),
      transformResponse: (response: LoginResponse) => {
        const token = jwtDecode<Token>(response.token);
        return { ...response, selfServiceId: token.self_service_id, dealerId: token.dealer_id };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: payload } = await queryFulfilled;
          const search = getSearch(getState() as RootState);
          const canUseGA = canCollectAnalyticsData(getState() as RootState);

          const { data: selfService, isSuccess } = await dispatch(
            selfServicesApi.endpoints.getSelfServiceById.initiate({ id: payload.selfServiceId }),
          );

          const { type = SelfServiceType.CHECK_IN } = dealersApi.endpoints.getContext.select()(getState()).data;
          dispatch(push(`${RedirectPathConfig[type]}${search}`));
          if (canUseGA && isSuccess) {
            ReactGA.event({
              category: 'Login',
              action: `${selfService.origin} ${selfService.type} login`,
              label: 'Login completed',
            });
          }
        } catch ({ error }) {
          dispatch(
            createErrorNotification({
              type: error?.data?.errorType,
              message: error?.data?.errorMessage,
              status: error?.status,
            }),
          );
        }
      },
    }),
    getMFAConnection: builder.mutation<GetMFAConnectionResponse, GetMFAConnectionRequest>({
      query: ({ type = SelfServiceType.CHECK_IN, ...params }) => ({
        method: 'GET',
        url: LOGIN_MFA,
        params: { ...params, type },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          dispatch(
            createErrorNotification({
              type: error?.data?.errorType,
              message: error?.data?.errorMessage,
              status: error?.status,
              data: error?.data?.errorMetadata,
            }),
          );
        }
      },
      transformResponse: (response: GetMFAConnectionResponse) => {
        if (Array.isArray(response.kioskDealers)) {
          const kioskDealersWithSortedBrands = response.kioskDealers.map((dealer) => ({
            ...dealer,
            brands: dealer.brands.sort(({ name: aName }, { name: bName }) => aName.localeCompare(bName)),
          }));
          const sortedBrands = response.brands.sort(({ name: aName }, { name: bName }) => aName.localeCompare(bName));
          return { ...response, kioskDealers: kioskDealersWithSortedBrands, brands: sortedBrands };
        }
        return response;
      },
    }),
    sendMFACode: builder.mutation<SendMFACodeResponse, SendMFACodeRequest>({
      query: ({ connectionId, selfServiceId, channel = ChannelEnum.SMS }) => ({
        method: 'POST',
        url: `${LOGIN_MFA}/send`,
        body: { connectionId, selfServiceId, channel },
      }),
      transformResponse: (response: SendMFACodeResponse, _, arg: SendMFACodeRequest) => ({
        ...response,
        channel: arg.channel,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          dispatch(
            createErrorNotification(
              {
                type: error?.data?.errorType,
                message: error?.data?.errorMessage,
                status: error?.status,
              },
              { id: 'login.error.sendTitle', defaultMessage: 'Send failed' },
            ),
          );
        }
      },
    }),
    loginMFA: builder.mutation<LoginMFAResponse, LoginMFARequest>({
      query: ({
        selfServiceId, connectionId, code, policies,
      }) => ({
        method: 'POST',
        url: `${LOGIN_MFA}/login`,
        body: {
          connectionId, selfServiceId, code, policies,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          const state = getState() as RootState;
          const search = getSearch(state);
          const { type, origin } = getMFAData(state) as MFA;
          const selfServiceId = getSelectedSelfServiceId(state);
          const canUseGA = canCollectAnalyticsData(getState() as RootState);

          const { data: selfService, isSuccess } = await dispatch(
            selfServicesApi.endpoints.getSelfServiceById.initiate({ id: selfServiceId }),
          );

          if (isSuccess) {
            const isAnswered = selfService.originContext[origin]?.status === SelfServiceStatus.ANSWERED;
            const hasHomeAnswered = selfService.originContext.HOME?.status === SelfServiceStatus.ANSWERED;
            const shouldGoToSkipPage = !isAnswered
              && hasHomeAnswered
              && selfService.type === SelfServiceType.CHECK_IN
              && selfService.origin !== SelfServiceOrigin.HOME;
            const url = shouldGoToSkipPage ? '/resume' : RedirectPathConfig[type];
            dispatch(push(`${url}${search}`));

            if (canUseGA) {
              ReactGA.event({
                category: 'Login',
                action: `${selfService.origin} ${selfService.type} login`,
                label: 'Login completed',
              });
            }
          }
        } catch ({ error }) {
          if (error?.data?.errorType !== ErrorTypeEnum.MFA_WRONG_CODE) {
            dispatch(
              createErrorNotification({
                type: error?.data?.errorType,
                message: error?.data?.errorMessage,
                status: error?.status,
              }),
            );
          }
        }
      },
    }),
    checkQRCodeToken: builder.mutation<void, CheckQRCodeTokenRequest>({
      query: ({ qrCodeToken, kioskId }) => ({
        method: 'POST',
        url: `${ADMIN}${KIOSK}/${kioskId}/login`,
        headers: { Authorization: `${qrCodeToken}` },
      }),
      async onQueryStarted({ qrCodeToken }, { dispatch, queryFulfilled, getState }) {
        try {
          const { meta } = await queryFulfilled;

          if (meta.response.status === 204) {
            const search = getSearch(getState() as RootState);
            dispatch(setQrCodeToken(qrCodeToken));
            dispatch(push(`/admin/login${search}`));
          }
          // eslint-disable-next-line no-empty
        } catch ({ error }) {
          dispatch(createErrorNotification({
            status: error?.status,
            type: ErrorTypeEnum.SO_WRONG_QR_CODE,
            message: 'The scanned QR code is not valid or has expired.{br}Please ensure that you have a working internet connection on your mobile device.',
          }));
        }
      },
    }),
    loginAdmin: builder.mutation<SendAdminCodeResponse & DecodedAdminToken, SendAdminCodeRequest>({
      query: ({ qrCodeToken, kioskId, pinCode }) => ({
        method: 'POST',
        url: `${ADMIN}${KIOSK}/${kioskId}/login`,
        body: { pinCode },
        headers: { Authorization: qrCodeToken },
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled, getState }) => {
        try {
          await queryFulfilled;

          const state = getState() as RootState;
          const search = getSearch(state);
          const isLoginPage = isAdminLoginPageSelector(state);

          if (isLoginPage) {
            dispatch(push(`/admin${search}`));
          }
          // eslint-disable-next-line no-empty
        } catch ({ error }) {}
      },
      transformResponse: (response: SendAdminCodeResponse) => {
        const decodedToken = jwtDecode<DecodedAdminToken>(response.token);
        return { ...response, ...decodedToken };
      },
    }),
  }),
});

export default authApi;

export const {
  useLoginMutation,
  useGetMFAConnectionMutation,
  useSendMFACodeMutation,
  useLoginMFAMutation,
  useCheckQRCodeTokenMutation,
  useLoginAdminMutation,
} = authApi;
