import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames, { Argument } from 'classnames';

import { Carousel, Spinner } from 'components/ui';
import { AbstractMedia, MediaType } from 'types/AbstractMedia';

import Trash from 'assets/icons/trash.svg';
import Retry from 'assets/icons/retry.svg';
import EyeIcon from 'assets/icons/eye.svg';
import Warning from 'assets/icons/warning.svg';
import CheckIcon from 'assets/icons/check.svg';
import CameraIcon from 'assets/icons/camera.svg';
import Uploading from 'assets/icons/uploading.svg';
import PictureIcon from 'assets/icons/picture.svg';

export interface CardProps {
  media: AbstractMedia;
  hideOverlay?: boolean;
  hidePreview?: boolean;
  onUpload: (file: File) => void;
  onRetry: (file: File) => void;
  onDelete: (id: string) => void;
  className?: Argument;
  isUploading?: boolean;
  hasError?: boolean;
}

const MediaCard: React.FC<CardProps> = ({
  media, hideOverlay, hidePreview, onUpload, onDelete, className, isUploading, hasError, onRetry,
}) => {
  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const [file, setFile] = React.useState<File | undefined>(undefined);

  const { type, uploadId } = media;
  const isUploaded = Boolean(uploadId);
  const UploadIcon = type === MediaType.VIDEO ? CameraIcon : PictureIcon;

  const displayUploadIcon = !isUploading && !hasError && !isUploaded;
  const displayErrorStatus = !isUploading && hasError;
  const displayCheckStatus = !isUploading && isUploaded;
  const displayStatus = isUploading || isUploaded || hasError;

  const handleUpload = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files?.[0];
    if (newFile) {
      setFile(newFile);
      onUpload(newFile);
    }
  }, [onUpload]);

  const handleRetry = React.useCallback(() => {
    onRetry(file);
  }, [file, onRetry]);

  const handleDelete = React.useCallback(() => {
    onDelete(uploadId);
  }, [uploadId, onDelete]);

  const handleOpen = React.useCallback(() => {
    setIsFullscreen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setIsFullscreen(false);
  }, []);

  return (
    <div className="relative rounded-xl overflow-hidden">
      <Carousel
        medias={[media]}
        onClose={handleClose}
        fullscreen={isFullscreen}
        className={className}
      />
      <div className={classNames('absolute flex top-0 left-0 w-full h-full z-20', { 'justify-end items-end': isUploaded, 'justify-center items-center': !isUploaded })}>
        {displayUploadIcon && (
          <label className="flex justify-center items-center w-14 h-14 p-3 !m-2 rounded-full cursor-pointer bg-white" data-testid="upload">
            <input type="file" accept={type === MediaType.IMAGE ? 'image/*' : 'video/*'} capture="environment" className="hidden" onChange={handleUpload} data-testid="upload-input" disabled={isUploading} />
            {isUploading ? <Spinner /> : <UploadIcon />}
          </label>
        )}
        {hasError && (
          <div className="flex flex-col items-center text-center text-white uppercase font-bold">
            <FormattedMessage id="error.media.upload.retry" defaultMessage="Upload fail, retry" />
            <div
              className="flex justify-center items-center w-14 h-14 p-3 !m-2 rounded-full cursor-pointer bg-white"
              data-testid="retry"
              onClick={handleRetry}
            >
              {isUploading ? <Spinner /> : <Retry />}
            </div>
          </div>
        )}
        {!hidePreview && (
          <div
            className="flex justify-center items-center w-14 h-14 p-3 !m-2 rounded-full cursor-pointer bg-white"
            onClick={handleOpen}
            data-testid="preview"
          >
            <EyeIcon />
          </div>
        )}
        {isUploaded && (
          <div
            className="flex justify-center items-center w-14 h-14 p-3 !m-2 rounded-full cursor-pointer bg-white"
            onClick={handleDelete}
            data-testid="delete"
          >
            <Trash className="fill-error" />
          </div>
        )}
      </div>
      {!hideOverlay && (
        <div
          className="absolute flex justify-center top-0 left-0 w-full h-full bg-tertiary opacity-60 z-10 rounded-xl"
        />
      )}
      {displayStatus && (
        <div className={classNames(
          'absolute top-0 right-0 w-0 h-0 border-solid z-30 border-0 border-r-60 border-b-60 border-transparent border-r-success',
          { 'border-r-warning': isUploading, 'border-r-error': hasError },
        )}
        >
          {isUploading && <Uploading className="ml-7 mt-1.5 w-6 h-6 vertical-pulse" />}
          {displayCheckStatus && <CheckIcon className="ml-7 mt-1.5 w-6 h-6 fill-white" />}
          {displayErrorStatus && <Warning className="ml-7 mt-1.5 w-6 h-6 fill-white" />}
        </div>
      )}
    </div>
  );
};

export default MediaCard;
