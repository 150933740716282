import React from 'react';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

import PageBaseProps from 'types/PageBase';
import FormKeys from 'modules/form/types/FormKeys';
import { QuestionsForm as QuestionsFormType } from 'types/QuestionsForm';

import Popup from '../ui/Popup';
import ConnectedForm from '../ui/ConnectedForm';
import MandatoryWarning from '../MandatoryWarning';
import getDecorator from '../ui/ConnectedForm/decorator';
import QuestionsFormRender from './QuestionsFormRender';

const validateForm = (intl: IntlShape) => (
  { questions = [] }: QuestionsFormType,
): object | undefined => questions.reduce((acc, question, index) => ({
  ...acc,
  ...(question.mandatory && !question.response && ({
    [`questions[${index}].error`]: intl.formatMessage({
      id: 'validation.required',
      defaultMessage: 'This field is mandatory',
    }),
  })),
}), {});

const decorator = getDecorator<QuestionsFormType>(FormKeys.QUESTIONS);

const QuestionsForm: React.FC<PageBaseProps> = ({ onNext, onPrev }) => {
  const intl = useIntl();
  return (
    <ConnectedForm
      onPrev={onPrev}
      onSubmit={onNext}
      decorators={[decorator]}
      formKey={FormKeys.QUESTIONS}
      validate={validateForm(intl)}
      mutators={{ ...arrayMutators }}
      component={QuestionsFormRender}
      subscription={{ hasValidationErrors: true }}
      errorComponent={(
        <Popup
          title={(
            <FormattedMessage
              id="questions.mandatoryModal.title"
              defaultMessage="You must first answer all mandatory questions"
              tagName="h2"
            />
          )}
        >
          <MandatoryWarning />
        </Popup>
      )}
    />
  );
};

export default QuestionsForm;
