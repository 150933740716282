import React from 'react';
import { Argument } from 'classnames';
import { FormattedMessage } from 'react-intl';

import { b } from 'utils/i18nUtils';
import { getHyperlink } from 'utils/linkUtils';

import { Switch } from '../ui';

interface LoginTermsProps {
  isChecked: boolean;
  onAccept: () => void;
  confidentialityPolicyLink?: string;
  className?: Argument;
}

const LoginTerms: React.FC<LoginTermsProps> = ({
  isChecked,
  onAccept,
  confidentialityPolicyLink,
  className,
}) => (
  <Switch
    checked={isChecked}
    onChange={onAccept}
    className={className}
    data-testid="terms-switch"
  >
    <FormattedMessage
      id="login.terms"
      // eslint-disable-next-line max-len
      defaultMessage="I acknowledge <policy>the data handling policy</policy>."
      values={{
        policy: getHyperlink({
          href: confidentialityPolicyLink,
          'data-testid': 'confidentiality-policy',
          description: <FormattedMessage
            id="login.termsModal.description"
            defaultMessage="You can access <b>{terms}</b> using the QR code or the URL below"
            values={{ b, terms: <FormattedMessage id="login.termsModal.termsList.CONFIDENTIALITY_POLICY" defaultMessage="the data handling policy" /> }}
            tagName="p"
          />,
        }),
        hasConfidentialityPolicy: Boolean(confidentialityPolicyLink),
      }}
    />
  </Switch>
);

export default LoginTerms;
