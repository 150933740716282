import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { ButtonType } from 'components/ui/Button';
import { KEY, PARKING } from 'components/SelfServices/Steps/common';
import { SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import { b } from 'utils/i18nUtils';
import { useDispatch, useSelector } from 'hooks';
import useElapsedTime from 'hooks/useElapsedTime';

import { addTime } from 'modules/elapsedTime/actions';
import { setCurrentStep } from 'modules/steps/actions';
import { finalizeSelfService } from 'modules/selfServices/actions';

import { getStepsConfig } from 'modules/form/selectors';
import { getSelectedSelfServiceId } from 'modules/auth/selectors';

import { useGetSelfServiceByIdQuery } from 'modules/selfServices/service';

import { Button, PageHeader } from 'components/ui';
import { getSelfServiceSteps } from 'components/SelfServices/Steps';
import * as checkInSteps from 'components/SelfServices/Steps/check-in';

import ChecklistIcon from 'assets/icons/checklist.svg';

const LAST_STEPS_KEYS = [
  // Steps _before_ saving the self-service
  PARKING.key,
  SelfServiceSteps.MOBILITY_CONTRACT,
  SelfServiceSteps.COURTESY_VEHICLE,
  checkInSteps.VEHICLE_CHECK.key,
  KEY.key,
  // Steps _after_ saving the self-service
  checkInSteps.FINAL_INSTRUCTIONS.key,
];

const Skip: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { getElapsedTime, reset } = useElapsedTime();

  const selfServiceId = useSelector(getSelectedSelfServiceId);
  const config = useSelector(getStepsConfig);

  const {
    data: selfService,
    isSuccess,
    isError,
  } = useGetSelfServiceByIdQuery({ id: selfServiceId });

  const steps = selfService ? getSelfServiceSteps(selfService, config) : [];

  const nextStep = steps.find(({ key }) => LAST_STEPS_KEYS.includes(key));

  const handleNext = () => {
    navigate(`/checkin${search}`);
  };

  const handleGoToLastStep = () => {
    if (nextStep.key === 'FINAL_INSTRUCTIONS') {
      dispatch(finalizeSelfService(nextStep.key, getElapsedTime()));
    } else {
      dispatch(addTime({ key: nextStep.key, time: getElapsedTime() }));
    }

    reset();
    dispatch(setCurrentStep(nextStep.key));
    handleNext();
  };

  if (!selfServiceId || isError) {
    return <Navigate to={`/${search}`} />;
  }

  return (
    <div className="flex flex-col items-center justify-start px-7 xs:px-5 min-h-screen">
      <PageHeader currentIndex={0} />
      <div className="main-content">
        <ChecklistIcon className="w-full max-w-lg kiosk:max-w-3xl" />
        <div className="text-center">
          <p className="mb-6">
            <FormattedMessage
              id="skip.description"
              defaultMessage="Your check-in has <b>already been completed</b>, congratulations!"
              values={{ b }}
            />
          </p>
          <FormattedMessage
            id="skip.detail"
            defaultMessage="Do you want to <b>go through all the check-in steps</b> again or <b>go directly to the final steps</b>?"
            values={{ b }}
            tagName="span"
          />
        </div>
        <div className="flex flex-col kiosk:flex-row w-full py-6">
          <Button
            type={ButtonType.SECONDARY}
            onClick={handleNext}
            disabled={!isSuccess}
            className="w-full mb-6 kiosk:mb-0 kiosk:mr-5"
            testId="review-checkin"
          >
            <FormattedMessage
              id="skip.review"
              defaultMessage="Review my check-in"
            />
          </Button>
          <Button
            type={ButtonType.TERTIARY}
            onClick={handleGoToLastStep}
            disabled={!isSuccess}
            className="w-full kiosk:ml-5"
            testId="goto-last-step"
          >
            <FormattedMessage
              id="skip.finalSteps"
              defaultMessage="Go to final steps"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Skip;
