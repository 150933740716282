import { push } from 'redux-first-history';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import { AppStartListening } from 'App/ListenerMiddleware';

import api from 'service/index';
import kioskService from 'modules/kiosk/service';
import translationsApi from 'modules/translations/service';

import { locationChange } from 'modules/router/actions';
import { logout, setCookies, setLoginData } from 'modules/auth/actions';

import {
  getAdminToken, getLoginData, getSelectedSelfServiceId, getToken,
} from 'modules/auth/selectors';
import { getDealerId, getDefaultBrandId, isKioskOrigin } from 'modules/dealers/selectors';
import { getKioskId } from 'modules/kiosk/selectors';
import { isLoginPage as isLoginPageSelector } from 'modules/router/selectors';
import { isOffline as isOfflineSelector } from 'modules/healthCheck/selectors';

import { getUrlParam } from 'utils/urlUtils';

import { DoorsStatus } from 'modules/kiosk/types/KeysSafe';
import { setDoorStatus } from 'modules/kiosk/actions';
import type { RootState } from '../../App/Store';
import { LOCAL_STORAGE_KEY } from './types/InitialState';

const listenerMiddleware = createListenerMiddleware();

const startAppListening = listenerMiddleware.startListening as AppStartListening;

startAppListening({
  actionCreator: setCookies,
  effect: ({ payload }) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(payload));
  },
});

startAppListening({
  actionCreator: logout,
  effect: async ({ meta }, { dispatch, getOriginalState }) => {
    const { asAdmin } = meta;

    const state = getOriginalState() as RootState;
    const token = getToken(state);
    const kioskId = getKioskId(state);
    const isKiosk = isKioskOrigin(state);
    const adminToken = getAdminToken(state);
    const isOffline = isOfflineSelector(state);
    const selfServiceId = getSelectedSelfServiceId(state);

    if (!isOffline) {
      if (asAdmin) {
        dispatch(kioskService.endpoints.closeAllSlotsAsAdmin.initiate({ kioskId, adminToken }));
      } else if (isKiosk) {
        dispatch(kioskService.endpoints.reset.initiate({ kioskId, selfServiceId, token }));
      }
    }

    dispatch(api.util.resetApiState());
    // Need to reset it manually as closeAllSlotsAsAdmin will put back doors as closed.
    dispatch(setDoorStatus(DoorsStatus.PENDING));
    dispatch(translationsApi.endpoints.getLanguages.initiate());

    const pathToken = getUrlParam('token');
    dispatch(push(`/?token=${pathToken}`));
  },
});

// Initialise Login page with default value
startAppListening({
  matcher: locationChange.match,
  effect: async (_, { dispatch, getState }) => {
    const isLoginPage = isLoginPageSelector(getState());
    if (isLoginPage) {
      const state = getState();
      const currentLoginData = getLoginData(state);
      const dealerId = currentLoginData?.dealerId ?? getDealerId(state);
      const brandId = getDefaultBrandId(state, dealerId);
      dispatch(setLoginData({
        dealerId, brandId, ...currentLoginData,
      }));
    }
  },
});

export default listenerMiddleware;
