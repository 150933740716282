import React from 'react';
import { useSelector } from 'react-redux';

import { getLanguages } from 'modules/translations/selectors';

import { getDefaultLanguage } from 'utils/i18nUtils';

import Label from 'types/Label';
import Language from 'types/LanguageEnum';

const useLabelTranslation = () => {
  const userLanguages = useSelector(getLanguages);
  const defaultLanguage = getDefaultLanguage();

  const languages = React.useMemo<Array<Language>>(
    () => [...userLanguages, defaultLanguage],
    [userLanguages, defaultLanguage],
  );

  return React.useMemo(() => ({
    getLabelTranslation: (label: Label) => {
    // Find the first language that has a translation
      const language = languages.find((lang) => Boolean(label[lang]));

      // If no language has a translation, return the first available translation
      return language ? label[language] : Object.values(label).filter(Boolean)[0];
    },
  }), [languages]);
};

export default useLabelTranslation;
