import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { HistoryRouter } from 'redux-first-history/rr6';

import Notifications from '../components/Notifications';

import Router from './Router';
import IntlWrapper from './Intl';
import { history, store, persistor } from './Store';

import 'flag-icons/css/flag-icons.min.css';

const App: React.FC = () => (
  <React.StrictMode>
    <Provider store={store}>
      {/* PersistGate is used to display a loader while the store is rehydrated */}
      <PersistGate persistor={persistor} loading={null}>
        <IntlWrapper>
          <HistoryRouter history={history}>
            <Router />
            <Notifications />
          </HistoryRouter>
        </IntlWrapper>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

export default App;
