import React from 'react';
import { useIntl } from 'react-intl';
import { useField } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';

import Label from 'types/Label';
import Card from 'components/ui/Card';

import QuestionType from 'types/Question';
import FormKeys from 'modules/form/types/FormKeys';
import useLabelTranslation from 'hooks/useLabelTranslation';
import { isIntlMessage } from 'modules/notifications/types/Notification';

import QuestionCardActions from './QuestionCardActions';

interface QuestionFormProps {
  name: string;
  questionFilter?: (question: QuestionType) => boolean;
}

const Question: React.FC<QuestionFormProps> = ({ name, questionFilter }) => {
  const intl = useIntl();
  const { getLabelTranslation } = useLabelTranslation();

  const { value: question } = useField(name, { subscription: { value: true } }).input;

  const title = isIntlMessage(question.title)
    ? intl.formatMessage(question.title)
    : getLabelTranslation(question.title as Label);

  return (
    questionFilter(question) && (
      <Card {...question} centerText title={title}>
        <QuestionCardActions name={name} />
      </Card>
    )
  );
};

const Questions: React.FC<{
  name: FormKeys;
  questionFilter?: (question: QuestionType) => boolean;
}> = ({ name, questionFilter = () => true }) => {
  const { fields: questions } = useFieldArray<QuestionType>(name, { subscription: {} });
  return (
    <>
      {questions.map((path: string) => (
        <Question key={path} name={path} questionFilter={questionFilter} />
      ))}
    </>
  );
};

export default Questions;
