import { v4 as uuidv4 } from 'uuid';

import { AbstractMedia } from 'types/AbstractMedia';
import { FormVehicleCheck } from 'modules/vehicleCheck/types/FormVehicleCheck';
import { Item, VehicleCheckModel, VehicleCheckModelType } from 'modules/vehicleCheck/types/VehicleCheckModel';
import { AnyObject } from 'react-final-form';

export const getAdditionalItem = (): Item => ({
  id: uuidv4(),
  type: VehicleCheckModelType.ADDITIONAL,
});

export const formatDataToFormValues = (selfServiceId: string, data?: VehicleCheckModel): FormVehicleCheck => {
  if (data) {
    const items = [...data.items, getAdditionalItem()].reduce(
      (acc, item) => ({ ...acc, [item.type]: [...(acc[item.type] ?? []), item] }),
      {} as Record<VehicleCheckModelType, Array<Item>>,
    );
    return {
      selfServiceId,
      items,
    };
  }
  return undefined;
};

interface VehicleCheckResponseItem extends Omit<Item, 'responseMedia'> {
  responseMedia: Pick<AbstractMedia, 'uploadId' | 'type'>;
}

interface VehicleCheckResponse extends Omit<FormVehicleCheck, 'items'> {
  items: Array<VehicleCheckResponseItem>,
}

export const formatFormValuesToData = (formValues: FormVehicleCheck): VehicleCheckResponse => {
  const items = Object.values(formValues.items).flatMap((item) => item)
    .filter((item) => item.type !== VehicleCheckModelType.ADDITIONAL || item.responseMedia)
    .map((item) => {
      const { uploadId, type } = item.responseMedia ?? {};
      return ({ ...item, responseMedia: uploadId ? { uploadId, type } : undefined });
    });

  return ({
    ...formValues,
    items,
  });
};

export const hasValidationError = (errorState: AnyObject, error: string) => Object.values(errorState?.items ?? {}).flatMap((item: AnyObject) => item).map((item) => item?.responseMedia?.uploadId).includes(error);
