import React from 'react';
import useIsActiveTab from './useActiveTab';

const useElapsedTime = () => {
  const isActive = useIsActiveTab();
  const elapsedTime = React.useRef(0);

  React.useEffect(() => {
    let interval: number;

    if (isActive) {
      interval = window.setInterval(() => elapsedTime.current++, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isActive]);

  return React.useMemo(() => ({
    // If the user click really fast, the elapsed time can be 0 and produce an API error, so we return 1
    getElapsedTime: () => (Math.max(elapsedTime.current, 1)),
    reset: () => { elapsedTime.current = 0; },
  }), [elapsedTime]);
};

export default useElapsedTime;
