import React from 'react';

const isoMapping: Record<string, string> = {
  en: 'gb',
  sv: 'se',
};

interface FlagProps {
  locale: string;
}

const Flag: React.FC<FlagProps> = ({ locale }) => {
  const isoLocale = isoMapping[locale] ?? locale;

  return (
    <span
      className={`fi fi-${isoLocale} !bg-cover rounded-full shadow-inner w-6 h-6 kiosk:w-8 kiosk:h-8`}
    />
  );
};

export default Flag;
