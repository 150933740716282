import { createSlice } from '@reduxjs/toolkit';

import healthCheckService from 'modules/healthCheck/service';

export interface InitialState {
  online: boolean;
  failureCount: number;
}

const healthCheckSlice = createSlice({
  name: 'healthCheck',
  initialState: {
    online: true,
    failureCount: 0,
  } as InitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      healthCheckService.endpoints.healthCheck.matchFulfilled,
      (state) => ({ ...state, online: true, failureCount: 0 }),
    );
    builder.addMatcher(
      healthCheckService.endpoints.healthCheck.matchRejected,
      (state) => {
        const nbError = state.failureCount + 1;
        if (nbError < 2) {
          return ({ ...state, failureCount: nbError });
        }
        return ({ ...state, online: false });
      },
    );
  },
});

export default healthCheckSlice;
