import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button, { ButtonProps, ButtonSize, ButtonType } from '../Button';

interface CardActionsProps {
  acceptBtnProps?: ButtonProps,
  refuseBtnProps?: ButtonProps,
  onClick?: (accept: boolean) => void,
  refusePrimary?: boolean,
}

const CardActions: React.FC<CardActionsProps> = ({
  acceptBtnProps, refuseBtnProps, onClick, refusePrimary,
}) => {
  const handleOnAccept = React.useCallback(() => onClick?.(true), [onClick]);
  const handleOnRefuse = React.useCallback(() => onClick?.(false), [onClick]);

  return (
    <div className="relative grid grid-cols-2">
      <Button
        testId="accept"
        size={ButtonSize.SMALL}
        onClick={handleOnAccept}
        className="py-3 kiosk:!py-6 kiosk:text-xl mr-1.5"
        // `=== true` allows to test type of refusePrimary as well (can be undefined)
        type={refusePrimary === true ? ButtonType.TERTIARY : ButtonType.PRIMARY}
        {...acceptBtnProps}
      >
        <FormattedMessage id="confirm.yes" defaultMessage="Yes" />
      </Button>
      <Button
        testId="refuse"
        size={ButtonSize.SMALL}
        onClick={handleOnRefuse}
        className="py-3 kiosk:!py-6 kiosk:text-xl ml-1.5"
        // `!== false` allows to test type of refusePrimary as well (can be undefined)
        type={refusePrimary !== false ? ButtonType.PRIMARY : ButtonType.TERTIARY}
        {...refuseBtnProps}
      >
        <FormattedMessage id="confirm.no" defaultMessage="No" />
      </Button>
    </div>
  );
};

export default CardActions;
