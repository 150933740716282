import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { b } from 'utils/i18nUtils';

import CheckIcon from 'assets/icons/check.svg';
import Cross from 'assets/icons/cross.svg';
import classNames from 'classnames';

export enum PaymentStatus {
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

const CONFIG_BY_STATUS = {
  [PaymentStatus.SUCCESS]: {
    title: {
      id: 'page.finalInvoice.modal.kioskInstruction.paidTitle',
      defaultMessage: 'Payment completed',
    },
    description: {
      id: 'page.finalInvoice.modal.kioskInstruction.landingContent',
      defaultMessage: 'Thank you for your payment! You can now <b>continue</b> your check-out <b>on the kiosk.</b>',
      values: { b: (text: ReactNode) => b(text, 'text-gray-90 text-lg') },
    },
    icon: <CheckIcon className="h-12 w-16 fill-white" />,
    className: 'bg-icon-success',
  },
  [PaymentStatus.FAILURE]: {
    title: {
      id: 'page.finalInvoice.modal.kioskInstruction.paymentFailure',
      defaultMessage: 'Payment failure',
    },
    description: {
      id: 'page.finalInvoice.modal.kioskInstruction.failureDescription',
      defaultMessage: 'Unfortunately we were unable to process the payment. Please <b>go back</b> to the <b>payment link</b> and try again.',
      values: { b: (text: ReactNode) => b(text, 'text-gray-90 text-lg') },
    },
    icon: <Cross className="h-7 fill-white" />,
    className: 'bg-error',
  },
};

const PaymentLanding: React.FC<{ status: PaymentStatus }> = ({ status }) => (
  <div className="flex justify-center items-center bg-cover bg-center bg-home relative min-h-screen">
    <div
      className="flex flex-col justify-center items-center w-80 h-102 p-6 bg-white bg-opacity-95 rounded-xl gap-7"
    >
      <div className="text-center text-gray-90 text-2xl font-extrabold">
        <FormattedMessage {...CONFIG_BY_STATUS[status].title} />
      </div>
      <div
        className={classNames(
          'flex justify-center items-center w-36 h-36 bg-opacity-20 rounded-full',
          CONFIG_BY_STATUS[status].className,
        )}
      >
        <div
          className={classNames(
            'flex items-center justify-center w-24 h-24 rounded-full',
            CONFIG_BY_STATUS[status].className,
          )}
        >
          {CONFIG_BY_STATUS[status].icon}
        </div>
      </div>
      <span className="text-center text-lg text-gray-500 font-medium">
        <FormattedMessage {...CONFIG_BY_STATUS[status].description} />
      </span>
    </div>
  </div>
);

export default PaymentLanding;
