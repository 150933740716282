import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getCurrentStep } from 'modules/steps/selector';

import QuestionMark from 'assets/icons/questionMark.svg';

import Modal from './Modal';
import DealersPhone from './DealersPhone';
import Button, { ButtonType } from './Button';

const HelpLineButton: React.FC = () => {
  const ref = React.useRef<HTMLButtonElement>();

  const [isOpen, setIsOpen] = React.useState(false);
  const currentStep = useSelector(getCurrentStep);

  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);

  return (
    <>
      <Button
        className="h-20 w-20 !p-2 mr-4"
        onClick={handleOpenModal}
        type={ButtonType.SECONDARY}
        analytics={{
          category: 'Help',
          action: 'Help button',
          label: `Help needed from ${currentStep}`,
        }}
      >
        <QuestionMark className="m-auto w-6 h-6 kiosk:w-8 kiosk:h-8" />
      </Button>
      <Modal
        open={isOpen}
        focusRef={ref}
        className="w-full max-w-2xl"
        title={<FormattedMessage id="helpLine.title" defaultMessage="Need help?" tagName="h2" />}
      >
        <div className="flex flex-col items-center">
          <p className="text-center">
            <FormattedMessage
              id="helpLine.message"
              defaultMessage="If something is not working properly, please do not hesitate to contact the helpline below."
            />
          </p>
          <DealersPhone />
        </div>
        <Button ref={ref} onClick={handleCloseModal} className="w-full mt-10">
          <FormattedMessage id="close" defaultMessage="Close" />
        </Button>
      </Modal>
    </>
  );
};
export default HelpLineButton;
