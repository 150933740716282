import React from 'react';
import { useIntl } from 'react-intl';
import { Field } from 'react-final-form';

import Input, { InputProps } from '../Input';

type FormInputProps = Omit<InputProps, 'value' | 'onChange'> & {
  name: string;
  format?: (value: string, name: string) => string;
  parse?: (value: string, name: string) => string;
  validate?: (value: string, allValues: unknown) => string | undefined;
};

const FormInput: React.FC<FormInputProps> = ({
  label,
  name,
  type,
  disabled,
  required,
  format,
  parse,
  validate,
  className,
  icon,
  'data-testid': dataTestId,
  ...rest
}) => {
  const intl = useIntl();
  return (
    <Field name={name} subscription={{ value: true, error: true }} format={format} parse={parse} validate={validate}>
      {({ input, meta }) => (
        <Input
          label={label}
          value={input.value}
          onChange={input.onChange}
          type={type}
          disabled={disabled}
          required={required}
          className={className}
          icon={icon}
          error={meta.error && intl.formatMessage({ id: meta.error, defaultMessage: meta.error })}
          data-testid={dataTestId}
          {...rest}
        />
      )}
    </Field>
  );
};

export default FormInput;
