import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { logout } from 'modules/auth/actions';
import PaymentStatus from './types/PaymentStatus';

const INITIAL_STATE = {
  isPayingModalOpen: false,
  status: PaymentStatus.WAITING_FOR_USER,
};

const onlinePaymentSlice = createSlice({
  name: 'onlinePayment',
  initialState: INITIAL_STATE,
  reducers: {
    openPayingModal: (state) => ({
      ...state,
      isPayingModalOpen: true,
    }),
    closePayingModal: (state) => ({
      ...state,
      isPayingModalOpen: false,
    }),
    setPaymentStatus: (state, action: PayloadAction<PaymentStatus>) => ({
      ...state,
      status: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, () => INITIAL_STATE);
  },
});

export default onlinePaymentSlice;
