import { createDraftSafeSelector } from '@reduxjs/toolkit';

import vehicleCheckApi from './service';
import type { RootState } from '../../App/Store';
import { getCurrentPageType, getSelfServiceId } from './selectors';

const getRoot = (state: RootState) => state;

const getContextSelectors = createDraftSafeSelector(getRoot, (state) => vehicleCheckApi.endpoints.getVCContext.select()(state));
export const isLoadingGetContext = createDraftSafeSelector(getContextSelectors, (selectors) => selectors.isLoading);

const getModelSelectors = createDraftSafeSelector(getRoot, getSelfServiceId, getCurrentPageType, (state, selfServiceId, type) => vehicleCheckApi.endpoints.getModel.select({ selfServiceId, type })(state));
export const isLoadingGetModel = createDraftSafeSelector(getModelSelectors, (selectors) => selectors.isLoading);
export const isSuccessGetModel = createDraftSafeSelector(getModelSelectors, (selectors) => selectors.isSuccess);
