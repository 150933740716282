import React from 'react';
import { useField } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';

import Card from 'components/ui/Card';
import ExtraStatus from 'types/ExtraStatus';
import { parse, format } from 'components/ui/Card/utils';

import QuestionType, { YesNoChoice } from 'types/Question';
import { formatUndefined } from 'utils/formUtils';
import useLabelTranslation from 'hooks/useLabelTranslation';

interface QuestionFormProps {
  name: string;
}

const Question: React.FC<QuestionFormProps> = ({ name }) => {
  const { getLabelTranslation } = useLabelTranslation();

  const { value: question } = useField<QuestionType>(name, { subscription: { value: true } }).input;
  const {
    value: comment,
    onChange: handleChangeComment,
  } = useField<string>(`${name}.comment`, { subscription: { value: true }, format: formatUndefined }).input;

  const {
    value: status,
    onChange: handleChangeStatus,
  } = useField<YesNoChoice, HTMLElement, ExtraStatus>(`${name}.response`, {
    subscription: { value: true },
    parse,
    format,
  }).input;

  return question && (
    <Card
      allowComment
      centerText
      {...question}
      status={status}
      comment={comment}
      onStatusChange={handleChangeStatus}
      onCommentChange={handleChangeComment}
      titleClassName="grid-cols-1 justify-center"
      title={getLabelTranslation(question.title)}
    />
  );
};

const Questions: React.FC = () => {
  const { fields: questions } = useFieldArray<QuestionType>('questions', { subscription: {} });

  return <>{questions.map((name: string) => (<Question key={name} name={name} />))}</>;
};

export default Questions;
