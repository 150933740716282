import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'hooks';
import { b, i } from 'utils/i18nUtils';
import { isPdf } from 'utils/downloadUtils';
import { isPublicDevice } from 'modules/dealers/selectors';

import Modal from './Modal';
import Carousel from './Carousel';
import QRCodeViewer from './QRCodeViewer';
import Button, { ButtonType } from './Button';
import { MediaType } from '../../types/AbstractMedia';

export type LinkProps = {
  'data-testid'?: string;
  description?: React.ReactNode;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const Link: React.FC<LinkProps> = ({
  href, className, description, onClick, children, ...props
}) => {
  const mediaId = React.useId();
  const ref = React.useRef<HTMLButtonElement>();

  const isPublic = useSelector(isPublicDevice);
  const [displayContent, setDisplayContent] = React.useState<boolean>();

  const media = React.useMemo(() => ({
    path: href,
    id: mediaId,
    type: isPdf(href) ? MediaType.DOCUMENT : undefined,
  }), [href, mediaId]);
  const shouldDisplayPDFViewer = React.useMemo(() => media.type === MediaType.DOCUMENT, [media.type]);

  const hideContent = React.useCallback(() => {
    setDisplayContent(false);
  }, []);

  const handleClick = React.useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    if (isPublic) {
      event.preventDefault();
      setDisplayContent(true);
    }
    onClick?.(event);
  }, [isPublic, onClick]);

  return (
    <>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={classNames('font-bold', className)}
        {...props}
        onClick={handleClick}
      >
        {children}
      </a>
      {displayContent && (
        <>
          {shouldDisplayPDFViewer && (
            <Carousel
              fullscreen
              medias={[media]}
              onClose={hideContent}
            />
          )}
          {!shouldDisplayPDFViewer && (
            <Modal
              open
              focusRef={ref}
              hideCloseButton
              title={<h2 className="first-letter:uppercase">{children}</h2>}
              className="w-full max-w-modal"
              onClose={hideContent}
            >
              <div className="flex flex-col size-full text-center items-center">
                {description}
                <QRCodeViewer
                  content={media.path}
                  className={classNames('!size-1/2', { 'mt-4': Boolean(description) })}
                />
                <p className="w-full break-words mt-4">
                  <FormattedMessage
                    id="login.termsModal.url"
                    defaultMessage="<b>URL:</b> <i>{url}</i>"
                    values={{ url: media.path, b, i }}
                  />
                </p>
                <Button
                  ref={ref}
                  className="w-full mt-4"
                  onClick={hideContent}
                  type={ButtonType.SECONDARY}
                >
                  <FormattedMessage id="close" defaultMessage="Close" />
                </Button>
              </div>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default Link;
