import React from 'react';
import { useDispatch } from 'react-redux';

import Media, { MediaInterface, UploadStatus } from 'types/Media';
import UploadMediaRequest from 'modules/medias/types/UploadMediaRequest';
import { NotificationType } from 'modules/notifications/types/Notification';
import MediaUploadUrlRequest from 'modules/medias/types/MediaUploadUrlRequest';

import { addNotification } from 'modules/notifications/actions';

import {
  useDeleteMediaMutation, useGetMediaUrlMutation, useUploadMediaMutation,
} from 'modules/medias/service';

import useBrowse from './useBrowse';

export interface UseUploadData {
  medias: MediaInterface[];
  upload: (params: Omit<MediaUploadUrlRequest, 'id'> & Pick<UploadMediaRequest, 'file'> & { isSignature?: boolean }) => Promise<MediaInterface | null>;
  browse: ReturnType<typeof useBrowse>;
  remove: (uploadId: string, { deleteOnServer }?: { deleteOnServer?: boolean }) => Promise<void>;
  isDeleting: boolean;
  isLoading: boolean;
  isError: boolean;
}

const useUpload = (selfServiceId: string, initialMedias: MediaInterface[] = []): UseUploadData => {
  const browse = useBrowse();
  const dispatch = useDispatch();

  const [medias, setMedias] = React.useState<MediaInterface[]>(initialMedias);

  const [getUrl, { isLoading: isUrlLoading, isError: isUrlError }] = useGetMediaUrlMutation();
  const [uploadMedia, { isLoading: isUploadLoading, isError: isUploadError }] = useUploadMediaMutation();
  const [deleteMedia, { isLoading: isDeleteLoading, isError: isDeleteError }] = useDeleteMediaMutation();

  const upload = React.useCallback(
    async (
      params: Omit<MediaUploadUrlRequest, 'id'> & Pick<UploadMediaRequest, 'file'> & { isSignature?: boolean },
    ) => {
      const media = new Media({
        ...params, path: URL.createObjectURL(params.file), status: UploadStatus.UPLOADING,
      });

      try {
        setMedias((prevMedias) => [...prevMedias, media]);

        const data = await getUrl({ id: selfServiceId, ...params }).unwrap();
        const {
          uploadId, path: url, filename, type,
        } = data;

        await uploadMedia({ url, file: params.file }).unwrap();

        setMedias((prevMedias) => prevMedias.map((prevMedia) => {
          if (prevMedia === media) {
            return new Media({
              ...prevMedia,
              ...media,
              filename,
              uploadId,
              type,
              status: UploadStatus.UPLOADED,
            });
          }

          return prevMedia;
        }));

        return new Media({
          ...media, uploadId, type, status: UploadStatus.UPLOADED,
        });
      } catch (error) {
        setMedias((prevMedias) => prevMedias.filter((prevMedia) => prevMedia !== media));

        dispatch(addNotification({
          title: {
            id: 'repairOrder.title',
            defaultMessage: 'Repair order',
          },
          description: {
            id: 'defaultError.description',
            defaultMessage: 'Please try again later or contact us.',
          },
          type: NotificationType.ERROR,
        }));
      }

      return null;
    },
    [dispatch, selfServiceId, getUrl, uploadMedia],
  );

  const remove = React.useCallback(async (uploadId: string, { deleteOnServer }: { deleteOnServer?: boolean } = {}) => {
    try {
      if (deleteOnServer) {
        setMedias((prevMedias) => prevMedias.map((media) => (
          media.uploadId === uploadId || media.id === uploadId
            ? new Media({ ...media, status: UploadStatus.DELETING })
            : media
        )));

        await deleteMedia({ selfServiceId, id: uploadId }).unwrap();
      }

      setMedias((prevMedias) => prevMedias.filter(({ uploadId: mediaId, id }) => mediaId !== uploadId && id !== uploadId));
    } catch { /* Do nothing */ }
  }, [deleteMedia, selfServiceId]);

  return React.useMemo(() => ({
    medias,
    upload,
    browse,
    remove,
    isDeleting: isDeleteLoading,
    isLoading: isUrlLoading || isUploadLoading,
    isError: isUrlError || isUploadError || isDeleteError,
  }), [
    medias,
    browse,
    upload,
    remove,
    isUrlError,
    isUploadError,
    isDeleteError,
    isDeleteLoading,
    isUrlLoading,
    isUploadLoading,
  ]);
};

export default useUpload;
