import React from 'react';
import classNames, { Argument } from 'classnames';

export interface StepIndicatorProps {
  currentIndex: number;
  length: number;
  className?: Argument;
  stepClassName?: Argument;
  'data-testid'?: string;
}

const StepIndicator: React.FC<StepIndicatorProps> = ({
  currentIndex,
  length: lengthProp,
  className,
  stepClassName,
  'data-testid': dataTestId = 'steps',
}) => {
  const length = lengthProp > 0 ? lengthProp : 0;
  const steps = React.useMemo(
    () => new Array(length).fill(undefined),
    [length],
  );
  return (
    <div
      className={classNames('flex grow items-center justify-center py-2 px-5', className)}
      data-testid={dataTestId}
    >
      {steps.map((_, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={classNames(
            'ease-in duration-300 bg-gray-70/20 rounded-full h-1.5 sm:h-1 w-1.5 sm:w-6 xs:mx-0.5 mx-1.5 sm:mx-1 ',
            { '!bg-gray-90 xs:!h-2 !h-2.5 sm:!h-1 xs:!w-2 !w-2.5 sm:!w-6': currentIndex === index },
            stepClassName,
          )}
          data-testid={
            currentIndex === index
              ? `step-selected-${index}`
              : `step-${index}`
          }
        />
      ))}
    </div>
  );
};

export default StepIndicator;
