import React from 'react';
import { FormattedMessage } from 'react-intl';

import './stylesError.css';

const Error: React.FC = () => (
  <div className="flex flex-col items-center">
    <div className="flex justify-center items-center w-full h-52 overflow-hidden bg-home bg-cover bg-center relative" />
    <div className="description text-center w-full max-w-7xl py-14 px-7">
      <p className="text-gray-70 text-xl">
        <FormattedMessage id="page.error.subTitle" defaultMessage="404 error" />
      </p>
      <h1
        className="mb-4 text-gray-90"
        data-testid="title"
      >
        <FormattedMessage
          id="page.error.title"
          defaultMessage="Page not found..."
        />
      </h1>
      <p className="text-gray-70" data-testid="description">
        <FormattedMessage
          id="page.error.message"
          defaultMessage="Sorry, the page you are looking for does not exist or has been moved."
        />
      </p>
    </div>
  </div>
);

export default Error;
