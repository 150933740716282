import React from 'react';
import * as Sentry from '@sentry/react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { getUrlParam } from 'utils/urlUtils';

import { getAdminToken, getQRCodeToken } from 'modules/auth/selectors';
import { isKioskOffline as isKioskOfflineSelector } from 'modules/healthCheck/selectors';

import Home from 'components/Home';
import Skip from 'components/Skip';
import Admin from 'components/Admin';
import Login from 'components/Login';
import Cookies from 'components/Cookies';
import Slots from 'components/Admin/Slots';
import Error404 from 'components/Error/Error404';
import AdminLayout from 'components/Admin/Layout';
import VehicleCheck from 'components/VehicleCheck';
import AdminLogin from 'components/Admin/AdminLogin';
import Management from 'components/Admin/Management';
import SelfServiceLayout from 'components/SelfServices';
import VehicleCheckForm from 'components/VehicleCheck/VehicleCheckForm';

import './refreshData';
import { useSelector } from '../hooks';
import OfflineModal from '../components/ui/OfflineModal';
import useInitAnalytics from '../hooks/useInitAnalytics';
import FinalInstructions from '../components/FinalInstructions';
import PaymentLanding, { PaymentStatus } from '../components/PaymentCompleted';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Router: React.FC = () => {
  useInitAnalytics();

  const location = useLocation();

  const isValidUrl = getUrlParam('token');

  const adminToken = useSelector(getAdminToken);
  const qrCodeToken = useSelector(getQRCodeToken);

  const isKioskOffline = useSelector(isKioskOfflineSelector);
  const displayOfflineModal = isKioskOffline && location.pathname !== '/'; // hide the modal on the home page

  return isValidUrl ? (
    <>
      <Cookies />
      {displayOfflineModal && <OfflineModal />}
      <SentryRoutes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="resume" element={<Skip />} />
          <Route path="checkin" element={<SelfServiceLayout />} />
          <Route path="checkout" element={<SelfServiceLayout />} />
          <Route path="breakdown" element={<SelfServiceLayout />} />
          {qrCodeToken && <Route path="admin/login" element={<AdminLogin />} />}
          {adminToken && (
            <Route path="admin" element={<AdminLayout />}>
              <Route index element={<Admin />} />
              <Route path="slots" element={<Slots />} />
              <Route path="management/:type" element={<Management />} />
            </Route>
          )}
          <Route path="vehicle-check">
            <Route index element={<VehicleCheck />} />
            <Route path=":type" element={<VehicleCheckForm />} />
            <Route path="final-instructions/:selfServiceId" element={<FinalInstructions />} />
          </Route>
        </Route>
        <Route path="*" element={<Error404 />} />
      </SentryRoutes>
    </>
  ) : (
    <Routes>
      <Route path="/payment-success" element={<PaymentLanding status={PaymentStatus.SUCCESS} />} />
      <Route path="/payment-failure" element={<PaymentLanding status={PaymentStatus.FAILURE} />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default Router;
