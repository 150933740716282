import React from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { canCollectAnalyticsData } from 'modules/auth/selectors';

const useInitAnalytics = () => {
  const location = useLocation();
  const canUseGA = useSelector(canCollectAnalyticsData);

  const trackingId = process.env.GOOGLE_TRACKING_ID;
  const canUse = canUseGA && Boolean(trackingId);

  if (canUse) {
    ReactGA.initialize(trackingId);
  }

  React.useEffect(() => {
    if (canUse) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location, canUse]);
};

export default useInitAnalytics;
