import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'hooks';

import { closeKeysSafe, openKeysSafe } from 'modules/kiosk/actions';

import { logout } from 'modules/auth/actions';
import { KeyStatus } from 'modules/kiosk/types/Kiosk';
import { hasScheduledTime } from 'modules/auth/selectors';
import { ScheduleTypeEnum } from 'modules/auth/types/InitialState';
import { getSelectedSelfService } from 'modules/selfServices/selectors';
import { SelfServiceType } from 'modules/selfServices/types/SelfService';
import {
  isClosed, getIsCloseSuccess, hasError as hasErrorSelector, isOpened,
} from 'modules/kiosk/selectors';

import { Spinner } from 'components/ui';
import KeysManagementModal from 'components/Key/KeysManagementModal';

import KeysError from './KeysError';
import Inactivity from './Inactivity';
import PageBaseProps from '../../types/PageBase';
import KeysManagementModalContent from './KeysManagementModalContent';

const Key: React.FC<PageBaseProps> = ({ onNext }) => {
  const dispatch = useDispatch();

  const doorsClosed = useSelector(isClosed);
  const doorsOpened = useSelector(isOpened);
  const hasError = useSelector(hasErrorSelector);
  const isCloseSuccess = useSelector(getIsCloseSuccess);
  const selfService = useSelector(getSelectedSelfService);
  const isInactive = useSelector((state) => hasScheduledTime(state, ScheduleTypeEnum.DOORS));

  const type = selfService.type === SelfServiceType.BREAKDOWN ? SelfServiceType.CHECK_IN : selfService.type;

  const mobilityEnabled = selfService.mobilityEnabled && (selfService.type !== SelfServiceType.CHECK_IN || selfService.keyStatus === KeyStatus.IN_SLOT);

  const handleOpenDoor = () => dispatch(openKeysSafe({ selfServiceId: selfService.id, selfServiceType: selfService.type }));

  const handleCloseDoor = () => dispatch(closeKeysSafe({ selfServiceId: selfService.id, selfServiceType: selfService.type }));

  React.useEffect(() => {
    handleOpenDoor();
    // This useEffect should only be triggered once when mounting the component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isCloseSuccess) {
      if (isInactive) {
        dispatch(logout());
      } else {
        onNext?.();
      }
    }
    // We don't want to trigger this effect when `onNext` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCloseSuccess]);

  return (
    <div className="pt-60 h-full">
      <div className="flex flex-col gap-4 items-center">
        <h1 className={classNames(
          'leading-normal transition-all ease-out duration-500',
          { '!text-5xl': !doorsClosed },
        )}
        >
          {mobilityEnabled && (
            <FormattedMessage
              id="key.mobility.title"
              defaultMessage="Swap keys"
            />
          )}
          {!mobilityEnabled && (
            <FormattedMessage
              id={`key.${type}.title`}
              defaultMessage={type === SelfServiceType.CHECK_IN ? 'Drop keys' : 'Retrieve keys'}
            />
          )}
        </h1>
      </div>
      <KeysManagementModal>
        {hasError && <KeysError onOpen={handleOpenDoor} onClose={handleCloseDoor} />}
        {!hasError && selfService && <KeysManagementModalContent {...selfService} mobilityEnabled={mobilityEnabled} onClose={handleCloseDoor} />}
      </KeysManagementModal>
      {doorsClosed && <Spinner className="mt-96 kiosk:!w-36 kiosk:!h-36" />}
      {doorsOpened && <Inactivity onInactive={handleCloseDoor} />}
    </div>
  );
};

export default Key;
