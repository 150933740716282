import React from 'react';
import { addSeconds } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import useCountdown from 'hooks/useCountdown';

import { b } from 'utils/i18nUtils';
import { useSendPayingLinkMutation } from 'modules/selfServices/service';

import PaymentStatus from 'modules/onlinePayment/types/PaymentStatus';

import { ButtonType } from 'components/ui/Button';
import { Button, Modal, QRCodeViewer } from 'components/ui';

import CheckIcon from 'assets/icons/check.svg';
import instructionsGif from 'assets/images/qrCodeScan.gif';

const INSTRUCTION_TIME = 3400;

interface Props {
  link: string;
  selfServiceId: string;
  paymentStatus: PaymentStatus;
  open?: boolean;
  onCancel?: () => void;
}

const PayingModal: React.FC<Props> = ({
  open, selfServiceId, paymentStatus, link, onCancel,
}) => {
  const ref = React.useRef();
  const instructionRef = React.useRef<HTMLImageElement>();

  const [countdownDate, setCountdownDate] = React.useState(new Date());
  const [showInstruction, setShowInstruction] = React.useState(false);

  const { countdown } = useCountdown({ date: countdownDate });
  const [sendPayingLink, { isLoading: isSending }] = useSendPayingLinkMutation();

  const isPending = paymentStatus === PaymentStatus.PENDING;
  const isSuccess = paymentStatus === PaymentStatus.SUCCESS;
  const isWaitingForUser = paymentStatus === PaymentStatus.WAITING_FOR_USER;

  const title = React.useMemo(() => {
    switch (paymentStatus) {
      case PaymentStatus.PENDING:
        return { id: 'page.finalInvoice.modal.pending.title', defaultMessage: 'Payment in progress' };
      case PaymentStatus.SUCCESS:
        return { id: 'page.finalInvoice.modal.kioskInstruction.paidTitle', defaultMessage: 'Payment completed' };
      case PaymentStatus.ERROR:
        return { id: 'page.finalInvoice.payment.error.unknownError', defaultMessage: 'Payment error' };
      default:
        return { id: 'page.finalInvoice.qrcode.title', defaultMessage: 'QR code payment' };
    }
  }, [paymentStatus]);

  const handleOnClickSend = React.useCallback(
    () => {
      setCountdownDate(addSeconds(new Date(), 60));
      sendPayingLink({ id: selfServiceId });
    },
    [selfServiceId, sendPayingLink],
  );

  React.useEffect(() => {
    if (open) {
      setShowInstruction(isWaitingForUser);
    }
  }, [open, isWaitingForUser]);

  React.useLayoutEffect(() => {
    if (showInstruction) {
      const callback = () => setTimeout(() => setShowInstruction(false), INSTRUCTION_TIME);

      const instruction = instructionRef.current;

      if (instruction) {
        if (instruction.complete) {
          callback();
        } else {
          instruction.addEventListener('load', callback);
          return () => instruction.removeEventListener('load', callback);
        }
      }
    }

    return undefined;
  }, [instructionRef, showInstruction]);

  return (
    <Modal
      open={open}
      focusRef={ref}
      className="w-full max-w-md kiosk:max-w-4xl kiosk:pt-36 kiosk:pb-32"
      title={!showInstruction && <FormattedMessage {...title} tagName="h2" />}
    >
      <div data-testid="paying-modal" className="flex flex-col gap-10 items-center mt-5 kiosk:mt-10">
        {showInstruction && (
          <img
            alt=""
            ref={instructionRef}
            src={instructionsGif}
            className="w-full aspect-square"
            data-testid="instructions-animation"
          />
        )}
        {!showInstruction && (
          <>
            {isWaitingForUser && (
              <>
                <div className="flex justify-center w-full px-6 py-12 bg-gray-100 rounded-2xl">
                  <QRCodeViewer content={link} />
                </div>
                <p className="text-center">
                  <FormattedMessage
                    id="page.finalInvoice.qrcode.instructions"
                    defaultMessage="<b>Scan the QR code</b> with your mobile device's camera to <b>complete the payment</b>."
                    tagName={undefined}
                    values={{ b }}
                  />
                </p>
                <Button
                  testId="send-link"
                  loading={isSending}
                  type={ButtonType.LIGHT}
                  onClick={handleOnClickSend}
                  disabled={isSending || countdown > 0}
                  className="underline !p-0 disabled:font-normal disabled:no-underline"
                >
                  {countdown === 0 && (
                    <FormattedMessage id="page.vehicleCheck.qrCodeStep.sendLink" defaultMessage="Send me the link" />
                  )}
                  {countdown > 0 && (
                    <FormattedMessage
                      id="login.resend.resend"
                      defaultMessage="Resent ({countdown})"
                      values={{ countdown }}
                    />
                  )}
                </Button>
              </>
            )}
            {isPending && (
              <>
                <div className="w-2/3 aspect-square grid place-items-center relative">
                  <div className="absolute w-full aspect-square bg-amber-500 opacity-20 rounded-full
                                  animate-fleetback-ping"
                  />
                  <div className="z-10 flex justify-center items-center gap-4 w-5/12 aspect-square rounded-full
                                bg-amber-500"
                  >
                    <div className="rounded-full w-1/12 aspect-square bg-white" />
                    <div className="rounded-full w-1/12 aspect-square bg-white" />
                    <div className="rounded-full w-1/12 aspect-square bg-white" />
                  </div>
                </div>
                <FormattedMessage
                  id="page.finalInvoice.qrcode.waiting"
                  defaultMessage="<b>Waiting for payment</b>."
                  values={{ b }}
                  tagName="p"
                />
              </>
            )}
            {isSuccess && (
              <>
                <div className="w-2/3 aspect-square grid place-items-center bg-icon-success/[.20] rounded-full">
                  <CheckIcon
                    className="fill-white check-icon rounded-full w-2/5 h-2/5 bg-icon-success ring-8 ring-icon-success"
                  />
                </div>
                <FormattedMessage
                  id="page.finalInvoice.modal.kioskInstruction.paidContent"
                  defaultMessage="Thank you for <b>your payment!</b>"
                  values={{ b }}
                  tagName="p"
                />
              </>
            )}
            {paymentStatus === PaymentStatus.ERROR && (
              <div className="w-2/3 aspect-square grid place-items-center bg-error/[.20] rounded-full">
                <ExclamationTriangleIcon className="text-white rounded-full w-2/5 h-2/5 bg-error ring-8 ring-error" />
              </div>
            )}
            <div className="flex flex-col kiosk:flex-row w-full mt-4 kiosk:mt-10">
              <Button
                ref={ref}
                onClick={onCancel}
                className="w-full"
                testId="back-button-modal"
                type={ButtonType.SECONDARY}
              >
                {isPending && <FormattedMessage id="cancel" defaultMessage="Cancel" />}
                {!isPending && (
                  <>
                    {!isSuccess && <FormattedMessage id="steps.back" defaultMessage="Back" />}
                    {isSuccess && <FormattedMessage id="socket.modal.confirm" defaultMessage="Continue" />}
                  </>
                )}
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default PayingModal;
