import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useAnalytics from './useAnalytics';

interface StepCommon<T, P> {
  key: string;
  data?: T;
  next?: string;
  previous?: string;
}

type StepRender<P> = | {
  render: (arg0: P) => React.ReactNode;
} | {
  component: React.ComponentType<P>;
};

export type Step<T = void, P = unknown> = StepCommon<T, P> & StepRender<P>;

export interface UseStepsParams<T, P> {
  steps: Array<Step<T, P>>;
  initialStep?: Step<T, P> | string;
  loop?: boolean;
  onChange?: (step: Step<T, P>) => void;
}

export interface UseStepsData<T, P> {
  currentStep: Step<T, P>;
  next: () => void;
  previous: () => void;
  setStep: (step: string | Step<T, P>) => void;
}

function useSteps<T = void, P = unknown>({
  steps, initialStep: initialStepProp, onChange,
}: UseStepsParams<T, P>): UseStepsData<T, P> {
  const { onClick } = useAnalytics();
  const location = useLocation();
  const navigate = useNavigate();

  const currentStep = React.useMemo(() => {
    let initialStep;

    if (typeof initialStepProp === 'string') {
      initialStep = steps.find(({ key }) => key === initialStepProp);
    } else {
      initialStep = initialStepProp;
    }

    return initialStep ?? steps[0];
  }, [initialStepProp, steps]);

  // Fills the history with the steps, this is a  workaround 🩹 waiting for moving moving steps to the router.
  const init = React.useCallback(() => {
    const currentKeyIndex = steps.findIndex(({ key }) => key === currentStep.key);

    if (currentKeyIndex > 0) {
      let index = 0;
      // 🤮 Push every step in history until the current step
      do {
        const { key } = steps[index];
        const urlParams = new URLSearchParams(location.search);
        urlParams.set('step', key);

        navigate({ ...location, search: urlParams.toString() }, { replace: index === 0, state: location.state });
        index++;
      } while (index <= currentKeyIndex);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep.key, location.pathname, location.search, location.state, steps]);

  // Fills the history with the steps every time the current step changes to avoid back button issues.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(init, [currentStep.key, steps]);

  // Handle navigation (browser + inApp)
  React.useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const stepName = urlParams.get('step');
    if (steps.length > 0 && stepName && stepName !== currentStep.key) {
      const step = steps.find(({ key }) => key === stepName);
      if (step) {
        onChange?.(step);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep.key, location]);

  const handleSetStep = React.useCallback((stepParam: string | Step<T, P>, forward?: boolean) => {
    const newStep = typeof stepParam === 'string'
      ? steps.find(({ key }) => key === stepParam)
      : stepParam;

    if (newStep !== currentStep && steps.includes(newStep)) {
      if (forward) {
        const urlParams = new URLSearchParams(location.search);
        urlParams.set('step', newStep.key);
        navigate({ ...location, search: urlParams.toString() }, { state: location.state });
      } else {
        navigate(-1);
      }
    }
  }, [steps, currentStep, location, navigate]);

  const next = React.useCallback(
    () => onClick({ category: 'Navigate', action: 'Next', label: currentStep.next }, handleSetStep)(currentStep.next, true),
    [currentStep.next, handleSetStep, onClick],
  );

  const previous = React.useCallback(
    () => onClick({ category: 'Navigate', action: 'Previous', label: currentStep.previous }, handleSetStep)(currentStep.previous),
    [currentStep.previous, handleSetStep, onClick],
  );

  return React.useMemo(() => ({
    next,
    previous,
    currentStep,
    setStep: handleSetStep,
  }), [currentStep, next, previous, handleSetStep]);
}

export default useSteps;
