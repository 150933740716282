import {
  FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistStore, persistReducer,
} from 'redux-persist';
import * as Sentry from '@sentry/react';
import { configureStore } from '@reduxjs/toolkit';

import api from 'service/index';
import authListenerMiddleware from 'modules/auth/middleware';
import kioskListenerMiddleware from 'modules/kiosk/middleware';
import socketListenerMiddleware from 'modules/sockets/middleware';
import translationsMiddleware from 'modules/translations/middleware';
import healthCheckListenerMiddleware from 'modules/healthCheck/middleware';
import selfServiceListenerMiddleware from 'modules/selfServices/middleware';
import vehicleCheckListenerMiddleware from 'modules/vehicleCheck/middleware';

import rootReducer from './rootReducer';
import { persistConfig } from './persist';
import { listenerMiddleware } from './ListenerMiddleware';
import { routerMiddleware, createReduxHistory } from './firstHistoryConfig';

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const MIDDLEWARES = [
  api.middleware,
  routerMiddleware,
  listenerMiddleware.middleware,
  authListenerMiddleware.middleware,
  translationsMiddleware.middleware,
  kioskListenerMiddleware.middleware,
  socketListenerMiddleware.middleware,
  healthCheckListenerMiddleware.middleware,
  selfServiceListenerMiddleware.middleware,
  vehicleCheckListenerMiddleware.middleware,
];

export const configStore = (
  withMiddlewares = true,
  withSentry = true,
  withPersist = true,
  preloadedState = {},
) => configureStore({
  reducer: withPersist ? persistedReducer : rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(withMiddlewares ? MIDDLEWARES : []),
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(withSentry ? [sentryReduxEnhancer] : []),
  preloadedState,
});

export const store = configStore();
export const persistor = persistStore(store);
export const history = createReduxHistory(store);

export type AppDispatch = typeof store.dispatch;
export { type RootState } from './rootReducer';
