import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import PageBaseProps from 'types/PageBase';

import Button, { ButtonType, ButtonProps } from './Button';

type PageFooterProps = {
  shouldDisplayBackButton?: boolean;
  hideNextButton?: boolean;
  nextText?: MessageDescriptor;
  hint?: React.ReactNode;
  wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
} & ButtonProps & PageBaseProps;

const Footer: React.FC<React.PropsWithChildren<PageFooterProps>> = ({
  shouldDisplayBackButton,
  onPrev,
  onNext,
  isPenultimate,
  hideNextButton,
  children,
  hint,
  nextText = { id: 'steps.next', defaultMessage: 'Next' },
  loading,
  wrapperProps,
  ...rest
}) => (
  <div
    {...wrapperProps}
    className={classNames(
      'nextButton kiosk:z-40',
      wrapperProps?.className,
    )}
  >
    {hint && <div className="p-3xs text-xxs text-center">{hint}</div>}
    <div className={classNames(
      'w-full flex items-center justify-center mt-2xs',
      shouldDisplayBackButton ? 'kiosk:justify-between' : 'kiosk:justify-end',
    )}
    >
      {shouldDisplayBackButton && (
        <Button
          type={ButtonType.SECONDARY}
          onClick={onPrev}
          testId="footer-prev-step"
          className="max-w-md w-full hidden kiosk:inline-flex kiosk:justify-center kiosk:w-72"
          disabled={loading}
        >
          <FormattedMessage
            id="steps.back"
            defaultMessage="Back"
          />
        </Button>
      )}
      {!hideNextButton && (
        <Button
          onClick={onNext}
          testId="next-step"
          className="max-w-md w-full kiosk:inline-flex kiosk:justify-center kiosk:w-72"
          loading={loading}
          {...rest}
        >
          {isPenultimate && <FormattedMessage id="steps.finish" defaultMessage="Finish" />}
          {!isPenultimate && <FormattedMessage {...nextText} />}
        </Button>
      )}
      {children}
    </div>
  </div>
);

export default Footer;
