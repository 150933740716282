import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import classNames, { Argument } from 'classnames';
import { Dialog, Transition } from '@headlessui/react';

import CrossIcon from 'assets/icons/cross.svg';
import { isLowPerfMode } from 'modules/app/selectors';

import { isIntlMessage } from 'modules/notifications/types/Notification';
import { Props as MessageProps } from 'react-intl/src/components/message';
import Button, { ButtonType } from '../Button';
import { useSelector } from '../../../hooks';
import ShareboxEmergencyAccess from '../ShareboxEmergencyAccess';

export interface ModalProps {
  open: boolean;
  className?: Argument;
  title?: MessageProps | React.ReactNode;
  onClose?: (value: boolean) => void;
  focusRef?: React.RefObject<HTMLElement>;
  // Use to hide the close button but still keep the outside click behavior to close the modal.
  hideCloseButton?: boolean;
  outsideClickClose?: boolean;
}

const dummyCloseFunction = () => { };

export const wrapperTransitions: Record<string, string> = {
  leaveTo: 'opacity-0',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leaveFrom: 'opacity-100',
  enter: 'ease-out duration-300',
  leave: 'ease-in duration-200',
};

const contentTransitions = {
  leave: 'ease-in duration-200',
  leaveTo: 'opacity-0 scale-95',
  enter: 'ease-out duration-300',
  enterFrom: 'opacity-0 scale-95',
  enterTo: 'opacity-100 scale-100',
  leaveFrom: 'opacity-100 scale-100',
};

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  children, open, className, title, onClose, focusRef, hideCloseButton, outsideClickClose = true,
}) => {
  const intl = useIntl();
  const lowPerfMode = useSelector(isLowPerfMode);

  const handleClick = () => onClose(false);
  const showCloseButton = !hideCloseButton && typeof onClose === 'function';
  const outsideClickCloseFn = outsideClickClose && typeof onClose === 'function' ? onClose : dummyCloseFunction;

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-60" onClose={outsideClickCloseFn} initialFocus={focusRef}>
        <Transition.Child
          as={Fragment}
          data-testid="modal-transition"
          {...(!lowPerfMode ? wrapperTransitions : {})}
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto" data-testid="modal">
          <ShareboxEmergencyAccess />
          <div className="flex min-h-full items-end md:items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              {...(!lowPerfMode ? contentTransitions : {})}
            >
              <Dialog.Panel
                className={classNames(
                  // eslint-disable-next-line max-len
                  'transform overflow-hidden rounded-t-modal md:rounded-modal bg-white p-4 kioskSharebox:p-6 kiosk:p-8 text-left align-middle shadow-xl transition-all',
                  className,
                  { 'pt-4 kioskSharebox:pt-6 kiosk:pt-8': typeof onClose === 'function' },
                )}
              >
                <Dialog.Title
                  as="div"
                  className={classNames({
                    'px-12 kiosk:px-18': showCloseButton,
                    'mb-l': Boolean(children),
                  }, 'relative text-center')}
                >
                  {isIntlMessage(title) ? intl.formatMessage(title) : title}
                  {showCloseButton && (
                    <Button
                      type={ButtonType.LIGHT}
                      onClick={handleClick}
                      testId="closeModal"
                      className="absolute top-0 right-0 p-0 ml-auto kiosk:!p-6 kioskSharebox:!p-2"
                    >
                      <CrossIcon className="w-3.5 kiosk:w-7 kioskSharebox:w-6" />
                    </Button>
                  )}
                </Dialog.Title>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
