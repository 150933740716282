import React from 'react';
import { addSeconds } from 'date-fns';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'hooks';
import useCountdown from 'hooks/useCountdown';

import { resetSchedule, setSchedule } from 'modules/auth/actions';
import { ScheduleTypeEnum } from 'modules/auth/types/InitialState';
import { getScheduledTime, hasScheduledTime } from 'modules/auth/selectors';
import { INACTIVITY_TIME_OPENED_DOORS, INACTIVITY_TIME_TO_LOGOUT } from 'modules/auth/constants';

import { Button, Modal } from 'components/ui';
import { ButtonType } from 'components/ui/Button';
import InactivityProgressBar from 'components/Admin/InactivityModal/InactivityProgressBar';

const getAutoCloseDate = () => addSeconds(new Date(), INACTIVITY_TIME_OPENED_DOORS);

interface InactivityProps {
  onInactive: () => void;
}

const Inactivity: React.FC<InactivityProps> = ({ onInactive }) => {
  const dispatch = useDispatch();

  const isInactive = useSelector((state) => hasScheduledTime(state, ScheduleTypeEnum.DOORS));
  const inactivityDate = useSelector((state) => getScheduledTime(state, ScheduleTypeEnum.DOORS));

  const [dateToAutoClose, setDateToAutoClose] = React.useState(getAutoCloseDate());

  const { countdown: timeRemaining } = useCountdown({ date: dateToAutoClose });

  const handleShowInactivity = React.useCallback(() => {
    dispatch(
      setSchedule({
        [ScheduleTypeEnum.DOORS]: addSeconds(Date.now(), INACTIVITY_TIME_TO_LOGOUT).toISOString(),
      }),
    );
  }, [dispatch]);

  const handleContinue = React.useCallback(() => {
    dispatch(resetSchedule());
    setDateToAutoClose(getAutoCloseDate());
  }, [dispatch]);

  React.useEffect(() => {
    if (timeRemaining === 0) {
      handleShowInactivity();
    }
  }, [timeRemaining, handleShowInactivity]);

  return (
    isInactive && (
      <Modal
        open
        className="modal-large"
        title={(
          <div className="mb-16">
            <span className="text-center">
              <FormattedMessage id="inactivity.modal.title" defaultMessage="Are you still there?" tagName="h2" />
            </span>
          </div>
        )}
      >
        <div className="px-[20%]">
          <InactivityProgressBar date={inactivityDate} onEnded={onInactive} />
        </div>
        <div className="flex flex-col items-center gap-4 mt-20">
          <p className="text-center">
            <FormattedMessage
              id="inactivity.modal.keys"
              defaultMessage="The door will automatically close if you do not respond."
            />
          </p>
        </div>
        <div className="flex items-center gap-4 mt-20">
          <Button testId="modal-continue" onClick={handleContinue} className="w-1/2">
            <FormattedMessage id="continue.yes" defaultMessage="Yes, continue" />
          </Button>
          <Button testId="modal-confirm" onClick={onInactive} className="w-1/2" type={ButtonType.SECONDARY}>
            <FormattedMessage id="socket.updateLogoutModal.logOut" defaultMessage="Log out" values={{ countdown: 0 }} />
          </Button>
        </div>
      </Modal>
    )
  );
};

export default Inactivity;
