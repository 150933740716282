import React from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import { formatDate } from 'utils/dateUtils';

import { KeyStatus } from 'modules/kiosk/types/Kiosk';
import { KeyType, ReadySelfService } from 'modules/kiosk/types/GetReadySelfServices';

import KeyBox from 'assets/icons/keyBox.svg';
import CarOk from 'assets/icons/carOk.svg';

import { Button } from '../ui';
import useUsedSlots from './useUsedSlots';

const Date: React.FC<{ title: React.ReactNode; value?: string }> = ({ title, value }) => {
  const intl = useIntl();
  const dateToDisplay = typeof value === 'string'
    ? intl.formatDate(formatDate(value), {
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    })
    : '-';
  return (
    <div className="flex gap-1">
      <span className="!text-xl w-20 font-bold">{title}</span>
      <span className="!text-xl">{dateToDisplay}</span>
    </div>
  );
};

const IconComponent = {
  [KeyType.CUSTOMER]: KeyBox,
  [KeyType.MOBILITY]: CarOk,
};

type ManagementItemProps = ReadySelfService & {
  actionTitle: MessageDescriptor;
  onClick: React.MouseEventHandler;
};
const ManagementItem: React.FC<ManagementItemProps> = ({
  startDate,
  endDate,
  customerDisplayName,
  registration,
  onClick,
  actionTitle,
  keyStatus,
  keyType,
}) => {
  const { isFull } = useUsedSlots();
  const Icon = IconComponent[keyType];

  const disabled = isFull && keyStatus !== KeyStatus.IN_SLOT;

  return (
    <div className="flex justify-between items-center rounded-4xl p-5 pl-10 bg-input-bg h-32 w-full gap-4">
      <div className="flex items-center grow">
        <div className="w-1/2">
          <span className="block !text-xl font-bold">{registration}</span>
          <span className="block !text-xl">{customerDisplayName}</span>
        </div>
        <div className="w-1/2">
          <Date title={<FormattedMessage id="start" defaultMessage="Start" />} value={startDate} />
          <Date title={<FormattedMessage id="end" defaultMessage="End" />} value={endDate} />
        </div>
      </div>
      <div className="w-20 h-20">
        {keyStatus === KeyStatus.IN_SLOT && (
          <div className="bg-white rounded-3xl p-4">
            <Icon className="w-12 h-12 fill-tertiary" />
          </div>
        )}

      </div>
      <Button onClick={onClick} testId="itemAction" disabledStyle={disabled} className="w-64 h-full !p-0">
        <span className="!text-xl !leading-6 font-semibold mx-4">
          <FormattedMessage {...actionTitle} />
        </span>
      </Button>
    </div>
  );
};

export default ManagementItem;
