import { REHYDRATE } from 'redux-persist';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import actions from 'modules/translations/actions';
import translationsApi from 'modules/translations/service';
import { AppStartListening } from 'App/ListenerMiddleware';

import { getSelectedLanguage } from './selectors';

const listenerMiddleware = createListenerMiddleware();

const startAppListening = listenerMiddleware.startListening as AppStartListening;

startAppListening({
  type: REHYDRATE,
  effect: async (_, { dispatch, getState }) => {
    const language = getSelectedLanguage(getState());
    dispatch(translationsApi.endpoints.getLanguages.initiate());
    dispatch(
      translationsApi.endpoints.getTranslationsByLanguage.initiate({
        language,
      }, { fixedCacheKey: 'getTranslations' }),
    );
  },
});

startAppListening({
  actionCreator: actions.setSelectedLanguage,
  effect: (_, { dispatch, getState }) => {
    const language = getSelectedLanguage(getState());
    dispatch(translationsApi.endpoints.getTranslationsByLanguage.initiate({
      language,
    }, {
      fixedCacheKey: 'getTranslations',
    }));
  },
});

export default listenerMiddleware;
