import { createDraftSafeSelector } from '@reduxjs/toolkit';

import translationsApi from 'modules/translations/service';
import { getCustomerLanguage } from 'modules/selfServices/selectors';
import { getDealerCustomerLanguage } from 'modules/dealers/selectors';

import type { RootState } from '../../App/Store';

export const getState = (state: RootState) => state.translations;

export const getSelectedLanguage = createDraftSafeSelector(
  getState,
  ({ selectedLanguage }) => selectedLanguage,
);

export const getLanguagesSelectors = translationsApi.endpoints.getLanguages.select();
export const getTranslationsSelectors = translationsApi.endpoints.getTranslationsByLanguage.select('getTranslations');

export const getTranslations = createDraftSafeSelector(
  getState,
  ({ messages }) => messages ?? {},
);

export const getLanguages = createDraftSafeSelector(
  getSelectedLanguage,
  getCustomerLanguage,
  getDealerCustomerLanguage,
  (...languages) => languages.filter(Boolean),
);

export const getAllLanguages = createDraftSafeSelector(getLanguagesSelectors, ({ data: languages }) => languages ?? []);
