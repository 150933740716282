import React from 'react';
import classNames from 'classnames';

import Outside from '../Outside';
import { MenuItemClickParams, MenuProps } from '../Menu';

interface DropdownProps {
  overlay: React.ReactElement<MenuProps>;
  className?: string;
}

const Dropdown: React.FC<React.PropsWithChildren<DropdownProps>> = ({
  overlay,
  className,
  children,
}) => {
  const { onClick } = overlay.props;
  const [visible, setVisible] = React.useState<boolean>(false);

  const handleOnClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(() => {
    setVisible((prev) => !prev);
  }, []);

  const handleClickOutside = React.useCallback(() => {
    setVisible(false);
  }, []);

  const handleItemOnClick = React.useCallback(
    (params: MenuItemClickParams) => {
      setVisible(false);
      onClick?.(params);
    },
    [onClick],
  );

  const overlayMenu = React.useMemo(
    () => React.cloneElement(overlay, {
      ...overlay.props,
      visible,
      onClick: handleItemOnClick,
    }),
    [visible, handleItemOnClick, overlay],
  );

  return (
    <div className={classNames(className, 'relative inline-block text-left z-10')}>
      <Outside onClickOutside={handleClickOutside}>
        <div
          data-testid="dropdown-body"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={handleOnClick}
          className="cursor-pointer"
        >
          {children}
        </div>
        {overlayMenu}
      </Outside>
    </div>
  );
};

export default Dropdown;
