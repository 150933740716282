import React from 'react';
import { addSeconds } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import { useDispatch, useSelector } from 'hooks';
import socketSlice from 'modules/sockets/reducer';
import { getLastUpdatedTime } from 'modules/sockets/selectors';
import { logout } from 'modules/auth/actions';

import { Button, Modal } from '../ui';
import useCountdown from '../../hooks/useCountdown';

const UpdateLogoutModal: React.FC = () => {
  const dispatch = useDispatch();

  const ref = React.useRef<HTMLButtonElement>();

  const updateDate = useSelector(getLastUpdatedTime);

  const handleCloseModal = React.useCallback(() => {
    dispatch(logout());
    dispatch(socketSlice.actions.closeLogoutModal());
  }, [dispatch]);

  const dateToRedirect = React.useMemo(
    () => addSeconds(new Date(updateDate), 10),
    [updateDate],
  );

  const { countdown: timeRemaining } = useCountdown({ date: dateToRedirect });

  React.useEffect(() => {
    if (timeRemaining === 0) {
      handleCloseModal();
    }
  }, [timeRemaining, handleCloseModal]);

  return (
    <Modal
      open
      focusRef={ref}
      className="w-full max-w-md"
      title={(
        <h3 className="text-center">
          <FormattedMessage
            id="socket.updateLogoutModal.title"
            defaultMessage="Sorry, you cannot complete this check-in anymore. If you think this is a mistake, please contact us."
            tagName="span"
          />
        </h3>
      )}
    >
      <div className="flex flex-col items-center mt-5">
        <Button
          ref={ref}
          testId="logOut"
          onClick={handleCloseModal}
          className="w-full mt-5 mb-4"
        >
          <FormattedMessage id="socket.updateLogoutModal.logOut" defaultMessage="Log out{countdown, select, 0 {} other { ({countdown})}}" values={{ countdown: timeRemaining }} />
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateLogoutModal;
