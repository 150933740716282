import { canCollectAnalyticsData } from 'modules/auth/selectors';
import ReactGA from 'react-ga4';

import { UaEventOptions } from 'react-ga4/types/ga4';
import { useSelector } from 'react-redux';

export type Analytics = string | UaEventOptions;

type Callback = (...args: unknown[]) => void;
const useAnalytics = () => {
  const canUseGA = useSelector(canCollectAnalyticsData);

  const onClick = (analytics: Analytics, callback: Callback) => (...args: unknown[]) => {
    if (typeof callback === 'function') {
      callback(...args);
    }

    if (canUseGA && Boolean(analytics) && typeof analytics !== 'string' && analytics?.category && analytics?.action) {
      ReactGA.event(analytics);
    }
  };

  return {
    ga: ReactGA,
    onClick,
  };
};

export default useAnalytics;
