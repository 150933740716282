import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { useSelector } from 'hooks';
import PageBaseProps from 'types/PageBase';
import { getCurrentStep } from 'modules/steps/selector';
import { getSelfServiceSteps } from 'components/SelfServices/Steps';
import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { useGetSelfServiceByIdQuery } from 'modules/selfServices/service';

import { getStepsConfig } from 'modules/form/selectors';
import Button, { ButtonType, ButtonProps } from './Button';

type PageFooterProps = {
  shouldDisplayBackButton?: boolean;
  hideNextButton?: boolean;
  nextText?: MessageDescriptor;
} & ButtonProps & PageBaseProps;

export const Footer: React.FC<React.PropsWithChildren<PageFooterProps>> = ({
  shouldDisplayBackButton, onPrev, onNext, isPenultimate, hideNextButton, children, nextText = { id: 'steps.next', defaultMessage: 'Next' }, loading, ...rest
}) => (
  <div className={classNames('nextButton', shouldDisplayBackButton ? 'kiosk:justify-between' : 'kiosk:justify-end')}>
    {shouldDisplayBackButton && (
      <Button
        type={ButtonType.SECONDARY}
        onClick={onPrev}
        testId="footer-prev-step"
        className="max-w-md w-full hidden kiosk:inline-flex kiosk:justify-center kiosk:w-72"
        disabled={loading}
      >
        <FormattedMessage
          id="steps.back"
          defaultMessage="Back"
        />
      </Button>
    )}
    {!hideNextButton && (
      <Button
        onClick={onNext}
        testId="next-step"
        className="max-w-md w-full kiosk:inline-flex kiosk:justify-center kiosk:w-72"
        loading={loading}
        {...rest}
      >
        {isPenultimate && <FormattedMessage id="steps.finish" defaultMessage="Finish" />}
        {!isPenultimate && <FormattedMessage {...nextText} />}
      </Button>
    )}
    {children}
  </div>
);

const PageFooter: React.FC<PageFooterProps> = (props) => {
  const currentStep = useSelector(getCurrentStep);
  const selfServiceId = useSelector(getSelectedSelfServiceId);
  const config = useSelector(getStepsConfig);

  const { data: selfService } = useGetSelfServiceByIdQuery({ id: selfServiceId });

  const visibleSteps = Object.values(getSelfServiceSteps(selfService, config)).filter(({ data }) => !data?.hideHeader);
  const currentIndex = visibleSteps.findIndex(({ key }) => key === currentStep);

  // Handle display of back button on login/steps page for the kiosk
  const shouldDisplayBackButton = currentIndex > 0;

  return (<Footer {...props} shouldDisplayBackButton={shouldDisplayBackButton} />);
};

export default PageFooter;
