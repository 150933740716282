import { createDraftSafeSelector } from '@reduxjs/toolkit';

import type { RootState } from 'App/Store';
import selfServicesApi from 'modules/selfServices/service';
import { getMFAData, getSelectedSelfServiceId } from 'modules/auth/selectors';
import { getOrigin, isCheckoutSelfService } from 'modules/dealers/selectors';

import { SelfServiceOrigin, SelfServiceType } from './types/SelfService';

const getRoot = (state: RootState) => state;

export const getSelectedSelfService = createDraftSafeSelector(
  getRoot,
  getSelectedSelfServiceId,
  (state, selectedSelfServiceId) => {
    const { data } = selfServicesApi.endpoints.getSelfServiceById.select({
      id: selectedSelfServiceId,
    })(state);
    return data;
  },
);

export const isSelfServiceLoading = createDraftSafeSelector(
  getRoot,
  getSelectedSelfServiceId,
  (state, selectedSelfServiceId) => {
    const { isLoading } = selfServicesApi.endpoints.getSelfServiceById.select({
      id: selectedSelfServiceId,
    })(state);
    return isLoading;
  },
);

export const getCustomerInfo = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.customerInfo,
);

export const getCustomerLanguage = createDraftSafeSelector(
  getCustomerInfo,
  (customerInfo) => customerInfo?.language,
);

export const getParkingInfo = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.parking,
);

export const getFinalInvoice = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.finalInvoice,
);

export const isFinalInvoicePayable = createDraftSafeSelector(
  getFinalInvoice,
  (finalInvoice) => Boolean(finalInvoice?.payable),
);

export const isInvoicePaymentMandatory = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => Boolean(selfService?.invoicePaymentMandatory),
);

export const isInvoicePaymentSourceDelayable = createDraftSafeSelector(
  getOrigin,
  (origin) => origin !== SelfServiceOrigin.KIOSK,
);

export const isInvoicePaymentDelayable = createDraftSafeSelector(
  isFinalInvoicePayable,
  isCheckoutSelfService,
  isInvoicePaymentSourceDelayable,
  (isPayable, isCheckout, delayableType) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    isPayable && isCheckout && delayableType,
);

export const getSelfServiceStatus = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.status,
);

// This is useful to know if the mobility WAS accepted before, hence there are new documents to send
export const isMobilityAccepted = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.mobility?.customerAccepted === true,
);

export const getMobilityDocuments = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.mobility?.documents ?? [],
);

export const getMobilityContract = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.mobility?.contract,
);

export const displayMobilityParking = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.mobilityEnabled && selfService.type === SelfServiceType.CHECK_OUT,
);

export const getMobilityVehicle = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.mobility?.vehicle,
);

export const getParkingMaps = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.parkingMap?.maps,
);

export const isMobilityEnabled = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.mobilityEnabled,
);

export const isCourtesyVehicleCheckEnabled = createDraftSafeSelector(
  getMFAData,
  isMobilityEnabled,
  (mfa, hasMobility) => mfa?.mobility?.courtesyVehicleCheckEnabled && hasMobility,
);
