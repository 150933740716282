import { Step } from '../hooks/useSteps';

export function getFollowingStep<T extends Step<unknown, unknown>>(
  currentStep: T,
  steps: Array<T>,
  reference: Array<T>,
  direction: 'next' | 'previous' = 'next',
): string {
  if (steps.some(({ key }) => key === currentStep[direction])) {
    return currentStep[direction];
  }

  const followingStep = reference.find(({ key }) => key === currentStep[direction]);
  if (followingStep) {
    return getFollowingStep(followingStep, steps, reference, direction);
  }

  return undefined;
}
