import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { InitialState } from 'modules/sockets/reducer';

import type { RootState } from '../../App/Store';

export const getState = (state: RootState): InitialState => state.socket;

export const isModalDisplayed = createDraftSafeSelector(
  getState,
  ({ displayModal }) => displayModal,
);
export const isLogoutModalDisplayed = createDraftSafeSelector(
  getState,
  ({ displayLogoutModal }) => displayLogoutModal,
);
export const getStepToRedirect = createDraftSafeSelector(
  getState,
  ({ stepToRedirect }) => stepToRedirect,
);
export const getLastUpdatedTime = createDraftSafeSelector(
  getState,
  ({ lastUpdatedTime }) => lastUpdatedTime,
);
export const isInstructionModalDisplayed = createDraftSafeSelector(
  getState,
  ({ displayInstructionModal }) => displayInstructionModal,
);
