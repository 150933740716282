import { Props as MessageProps } from 'react-intl/src/components/message';

export enum NotificationType {
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
}

export interface Collapse {
  title: MessageProps | React.ReactNode;
  description: MessageProps | React.ReactNode;
}

export default interface Notification {
  id?: number;
  title: MessageProps;
  description: MessageProps;
  collapse?: Collapse;
  type: NotificationType;
}

export const isIntlMessage = (message: any): message is MessageProps => (
  Boolean(message?.id && message?.defaultMessage)
);
