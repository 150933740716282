import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { SelfServiceType } from 'modules/selfServices/types/SelfService';

import { Button } from 'components/ui';
import { ButtonType } from 'components/ui/Button';

import CheckInIcon from 'assets/icons/checkIn.svg';
import CheckOutIcon from 'assets/icons/checkOut.svg';

interface TypeButtonProps {
  icon: React.ReactNode;
  type: string;
  description: string;
  onClick: () => void;
  'data-testid'?: string;
}

const TypeButton: React.FC<TypeButtonProps> = ({
  icon, type, description, onClick, 'data-testid': dataCy,
}) => {
  const [animation, setAnimation] = React.useState(false);

  const handleOnClick = React.useCallback(() => setAnimation(true), []);

  React.useLayoutEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (animation) {
      timeoutId = setTimeout(() => {
        onClick?.();
        setAnimation(false);
      }, 300);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [animation, onClick]);

  return (
    <Button
      testId={dataCy}
      onClick={handleOnClick}
      type={ButtonType.LIGHT}
      className="flex-1 bg-secondary rounded-3xl border-2 border-border-bg overflow-hidden hover:no-underline focus:no-underline"
    >
      <div className={classNames(
        'absolute rounded-full w-1/3 bg-white aspect-square left-1/3 transition-all duration-300 z-0',
        animation && 'scale-500',
      )}
      />
      <div className="flex flex-col items-center relative z-10">
        <div className="rounded-full w-48 h-48 bg-white p-10 flex items-center justify-center mb-5">
          {icon}
        </div>
        <h3>{type}</h3>
        <p className="font-normal small">{description}</p>
      </div>
    </Button>
  );
};

const Admin = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { search } = useLocation();

  const handleOnClick = (type: SelfServiceType) => () => navigate(`/admin/management/${type}${search}`);

  return (
    <div className="main-content !pt-120">
      <h1 className="text-center"><FormattedMessage id="home.admin" defaultMessage="What would you like to do?" /></h1>
      <div className="flex gap-3 w-5/6">
        <TypeButton
          onClick={handleOnClick(SelfServiceType.CHECK_IN)}
          icon={<CheckInIcon className="aspect-square w-20 h-20 fill-tertiary" />}
          data-testid="check-in"
          type={intl.formatMessage({ id: 'home.checkIn', defaultMessage: 'Check-in' })}
          description={intl.formatMessage({ id: 'home.manage.checkIn', defaultMessage: 'Manage my check-ins' })}
        />
        <TypeButton
          onClick={handleOnClick(SelfServiceType.CHECK_OUT)}
          icon={<CheckOutIcon className="aspect-square w-20 h-20 fill-tertiary" />}
          data-testid="check-out"
          type={intl.formatMessage({ id: 'home.checkOut', defaultMessage: 'Check-out' })}
          description={intl.formatMessage({ id: 'home.manage.checkOut', defaultMessage: 'Manage my check-outs' })}
        />
      </div>
    </div>
  );
};

export default Admin;
