import React from 'react';
import arrayMutators from 'final-form-arrays';

import PageBaseProps from 'types/PageBase';
import FormKeys from 'modules/form/types/FormKeys';
import { MobilityFormType } from 'types/MobilityDocumentFormType';

import ConnectedForm from 'components/ui/ConnectedForm';
import getDecorator from 'components/ui/ConnectedForm/decorator';
import MobilityDocumentFormRender from './MobilityDocumentFormRender';
import MobilityError from './MobilityError';

const decorators = [getDecorator<MobilityFormType>(FormKeys.MOBILITY)];

const MobilityDocumentForm: React.FC<PageBaseProps> = ({ onNext, onPrev, ...pageProps }) => (
  <ConnectedForm
    onPrev={onPrev}
    onSubmit={onNext}
    keepDirtyOnReinitialize
    decorators={decorators}
    mutators={{ ...arrayMutators }}
    subscription={{ errors: true }}
    errorComponent={<MobilityError />}
    formKey={FormKeys.MOBILITY}
    component={MobilityDocumentFormRender}
    {...pageProps}
  />
);

export default MobilityDocumentForm;
