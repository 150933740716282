import React from 'react';
import classNames from 'classnames';

import useMediaMetrics from 'hooks/useMediaMetrics';

import { AbstractMedia } from 'types/AbstractMedia';

import Play from 'assets/icons/play.svg';

import MediaLoader from './MediaLoader';
import Button, { ButtonType } from './Button';

interface VideoProps {
  media: AbstractMedia;
  thumbnail?: boolean;
  'data-testid'?: string;
}

const isIOSSafari = /iPad|iPhone|iPod/.test(navigator.userAgent);

const Video: React.FC<VideoProps> = ({ media, thumbnail, 'data-testid': testId }) => {
  const ref = React.useRef<HTMLVideoElement>(null);
  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);

  const mediaMetrics = useMediaMetrics(media);

  const isBlobUrl = media?.path.startsWith('blob:');

  // Pause video when it's not visible
  React.useLayoutEffect(() => {
    if (ref.current) {
      if (thumbnail && isIOSSafari && isBlobUrl) {
        // Trick to load preview on IOS safari with blob url
        ref.current.autoplay = true;
        ref.current.pause();
        ref.current.load();
        ref.current.autoplay = false;
      }

      if (thumbnail && !ref.current.paused) {
        ref.current.pause();
        ref.current.currentTime = 0;
      }

      const observer = new IntersectionObserver((entries) => {
        if (entries.every(({ isIntersecting }) => !isIntersecting)) {
          ref.current.pause();
          ref.current.currentTime = 0;
        } else if (ref.current.paused && !thumbnail) {
          ref.current.play();
        }
      }, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      });

      observer.observe(ref.current as HTMLVideoElement);

      return () => observer.disconnect();
    }

    return undefined;
  }, [isBlobUrl, thumbnail]);

  const handleLoadedData = () => {
    setIsVideoLoaded(true);
  };

  return (
    <>
      <div className={classNames({ hidden: !isVideoLoaded, contents: isVideoLoaded })}>
        {thumbnail && (
          <Button
            testId="carousel-play"
            type={ButtonType.WHITE}
            className="rounded-full w-10 h-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white drop-shadow-xl active:!bg-white !p-3 z-20 cursor-pointer play-button"
          >
            <Play className="fill-gray-90" />
          </Button>
        )}
        <video
          ref={ref}
          preload="auto"
          className={`max-h-full w-full ${thumbnail ? ' bg-neutral-100' : ' bg-recommended-bg'}`}
          data-testid={testId}
          autoPlay={!thumbnail}
          controls={!thumbnail}
          playsInline={!thumbnail}
          disablePictureInPicture
          disableRemotePlayback
          onLoadedData={handleLoadedData}
          controlsList="nofullscreen nodownload noremoteplayback noplaybackrate nopip"
          {...mediaMetrics}
        >
          <source src={isBlobUrl ? media.path : `${media.path}#t=0.001`} />
          <track kind="captions" />
        </video>
      </div>
      {!isVideoLoaded && <MediaLoader displaySpinner={!thumbnail} />}
    </>
  );
};

export default Video;
