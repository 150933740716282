import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BASE_URL } from 'constants/url';
import { getAuthorisationToken } from 'modules/auth/selectors';

import type { RootState } from '../App/Store';

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { endpoint, getState }) => {
    const token = getAuthorisationToken(getState() as RootState, endpoint);
    if (token) {
      headers.set('Authorization', token);
    }

    return headers;
  },
  cache: 'no-cache',
});

const api = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: ['Login'],
  endpoints: () => ({}),
});

export default api;
