import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import useCountdown from 'hooks/useCountdown';
import { ButtonType } from 'components/ui/Button';
import RadioBullet from 'components/ui/RadioBullet';
import type { MFA } from 'modules/auth/types/InitialState';
import { ChannelEnum } from 'modules/auth/types/LoginRequest';
import { useSendMFACodeMutation } from 'modules/auth/service';
import { getMFAData, getSelectedSelfServiceId } from 'modules/auth/selectors';
import {
  Button, Modal,
} from 'components/ui';

interface ResendCodeModalProps {
  onClose: (isOpen: boolean) => void;
}

const getChannel = ({ email, phoneNumber }: Pick<MFA, 'email' | 'phoneNumber'>) => {
  let channel;
  if (phoneNumber) {
    channel = ChannelEnum.SMS;
  } else if (email) {
    channel = ChannelEnum.EMAIL;
  }
  return channel;
};

const ResendCodeModal: React.FC<ResendCodeModalProps> = ({ onClose }) => {
  const ref = React.useRef<HTMLButtonElement>();

  const [sendMFACode, { isLoading }] = useSendMFACodeMutation();
  const {
    connectionId, retryExpiration, phoneNumber, email, remainingCode,
  } = useSelector(getMFAData);
  const selfServiceId = useSelector(getSelectedSelfServiceId);

  const [channel, setChannel] = React.useState(getChannel({ email, phoneNumber }));
  const { countdown: timeRemaining } = useCountdown({ date: retryExpiration });
  const maxAttemptReached = remainingCode === 0;
  const isDisabled = isLoading || timeRemaining > 0 || maxAttemptReached;

  const handleClose = React.useCallback(() => onClose(false), [onClose]);

  const handleResend = React.useCallback(async () => {
    try {
      await sendMFACode({ connectionId, selfServiceId, channel }).unwrap();
      handleClose();
    } catch (error) {
      // do nothing, modal stays opened
    }
  }, [sendMFACode, connectionId, selfServiceId, channel, handleClose]);

  const handleChange = (value: ChannelEnum) => {
    setChannel(value);
  };

  return (
    <Modal
      open
      focusRef={ref}
      className="w-full max-w-md kiosk:max-w-4xl"
      onClose={onClose}
      title={(
        <FormattedMessage
          id="login.resend.resendCode"
          defaultMessage="Resend the code"
          tagName="h2"
        />
      )}
    >
      <div className="flex flex-col items-center mt-5" data-testid="resendModal">
        <FormattedMessage id="login.resend.tryAgain" defaultMessage="If you did not receive the code, you can try again by {channel, select, SMS {sms} EMAIL {email} other {sms or email}}." values={{ channel: getChannel({ email, phoneNumber }) }} tagName="p" />

        <div className="my-4 kiosk:mb-10 w-full">
          {phoneNumber && (
            <RadioBullet
              name="channel"
              value={ChannelEnum.SMS}
              checked={channel === ChannelEnum.SMS}
              onChange={handleChange}
              data-testid="sms-radio"
              title={(
                <FormattedMessage
                  id="login.resend.sms.title"
                  defaultMessage="SMS"
                />
              )}
              description={(
                <FormattedMessage
                  id="login.resend.sms.description"
                  defaultMessage="SMS sent to {phoneNumber}"
                  values={{ phoneNumber }}
                />
              )}
            />
          )}
        </div>
        <div className="mb-4 kiosk:mb-10 w-full">
          {email && (
            <RadioBullet
              name="channel"
              value={ChannelEnum.EMAIL}
              checked={channel === ChannelEnum.EMAIL}
              onChange={handleChange}
              data-testid="email-radio"
              title={(
                <FormattedMessage
                  id="login.resend.email.title"
                  defaultMessage="Email"
                />
              )}
              description={(
                <FormattedMessage
                  id="login.resend.email.description"
                  defaultMessage="Email sent to {email}"
                  values={{ email }}
                />
              )}
            />
          )}

        </div>
        {maxAttemptReached && <span className="text-center mb-4"><FormattedMessage id="login.resend.reachedMaxAttempts" defaultMessage="You reached the maximum number of attempts." /></span>}
        {!maxAttemptReached && (
          <Button
            ref={ref}
            loading={isLoading}
            onClick={handleResend}
            className="w-full mt-5 mb-4"
            disabled={isDisabled}
            testId="resendCode"
          >
            <FormattedMessage id="login.resend.resend" defaultMessage="Resend{countdown, select, 0 {} other { ({countdown})}}" values={{ countdown: timeRemaining }} />
          </Button>
        )}
        <Button onClick={handleClose} type={ButtonType.SECONDARY} className="w-full" testId="cancel-button">
          <FormattedMessage id="cancel" defaultMessage="Cancel" />
        </Button>
      </div>
    </Modal>
  );
};

export default ResendCodeModal;
