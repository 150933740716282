import Label from '../../../types/Label';
import { AbstractMedia } from '../../../types/AbstractMedia';

export enum VehicleCheckStatus {
  OPEN = 'OPEN',
  DELETED = 'DELETED',
}

export enum VehicleCheckModelType {
  ADDITIONAL = 'ADDITIONAL',
  MANDATORY = 'MANDATORY',
  OPTIONAL = 'OPTIONAL',
}

export interface Item {
  id: string,
  media?: AbstractMedia,
  responseMedia?: AbstractMedia,
  name?: Label | string,
  instruction?: Label,
  type: VehicleCheckModelType,
}

export interface VehicleCheckModel {
  status: VehicleCheckStatus,
  items: Array<Item>,
  id: string,
  name: string,
}

export enum VehicleCheckType {
  PERSONAL = 'PERSONAL',
  COURTESY = 'COURTESY',
}
