import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import authApi from 'modules/auth/service';
import dealersApi from 'modules/dealers/service';

import InitialState from './types/InitialState';
import { ContextResponse } from './types/ContextResponse';

const dealersSlice = createSlice({
  name: 'dealers',
  initialState: {
    context: null,
  } as InitialState,
  reducers: {
    resetContext(state) {
      return {
        ...state,
        context: {
          ...state.context,
          selfServicePhoneNumber: null,
          dealerName: null,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      dealersApi.endpoints.getContext.matchFulfilled,
      (state, action: PayloadAction<ContextResponse>) => ({
        ...state,
        context: action.payload,
      }),
    );
    builder.addMatcher(authApi.endpoints.getMFAConnection.matchFulfilled, (state, action) => {
      const {
        connectionId, email, phoneNumber, selfServiceId, ...rest
      } = action.payload;

      return {
        ...state,
        context: {
          ...state.context,
          ...rest,
        },
      };
    });
  },
});

export default dealersSlice;
