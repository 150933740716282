import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormRenderProps, useField } from 'react-final-form';

import PageBaseProps from 'types/PageBase';
import { formatSelectData, formatToArray } from 'utils/formUtils';
import { Reason, RequestedWorks } from 'types/BreakdownInfo';
import { isEqualPhoneNumber } from 'modules/selfServices/types/SelfService';

import PageFooter from '../ui/PageFooter';
import SelectInput from '../ui/SelectInput';
import FormInput from '../ui/form/FormInput';
import FormTextarea from '../ui/form/FormTextarea';
import PhoneNumberInput from '../ui/PhoneNumberInput';

const Content: React.FC<PageBaseProps & FormRenderProps> = ({
  onPrev, handleSubmit, initialValues, pristine,
}) => {
  const intl = useIntl();

  const phoneNumberInput = useField('customerPhoneNumber', {
    subscription: { value: true },
    isEqual: isEqualPhoneNumber,
  }).input;
  const { input: reasonInput } = useField('breakdownServiceReason', { subscription: { value: true } });
  const { input: requestedWorks } = useField('breakdownServiceRequestedWorks', {
    subscription: { value: true },
    format: formatToArray<RequestedWorks>,
  });

  const [hasError, setHasError] = React.useState(false);

  const reasonInputData = formatSelectData(Reason, intl, 'breakdownInfo.reason');
  const requestedWorkData = formatSelectData(RequestedWorks, intl, 'breakdownInfo.requestedWorks');

  const displaySkipText = pristine && !initialValues;

  return (
    <div className="main-content">
      <div className="flex flex-col items-center">
        <FormattedMessage
          tagName="h1"
          id="breakdownInfo.title"
          defaultMessage="Information"
        />
        <div className="description mt-5 text-center">
          <FormattedMessage
            id="breakdownInfo.description"
            defaultMessage="Fill in the customer’s contact information and provide details about the breakdown."
            tagName="p"
          />
        </div>
      </div>
      <div className="content">
        <FormInput
          name="customerFirstName"
          autoComplete="given-name"
          data-testid="firstName-input"
          label={intl.formatMessage({ id: 'customerInfo.firstName', defaultMessage: 'First name' })}
        />
        <FormInput
          name="customerLastName"
          autoComplete="family-name"
          data-testid="lastName-input"
          label={intl.formatMessage({ id: 'customerInfo.lastName', defaultMessage: 'Last name' })}
        />
        <PhoneNumberInput
          required
          onError={setHasError}
          className="border-b-2 pb-10 kiosk:pb-20 mb-0"
          {...phoneNumberInput}
        />
        <h3 className="mt-10 kiosk:mt-20 text-center">
          <FormattedMessage
            id="breakdownInfo.breakdownDescription"
            defaultMessage="Breakdown description"
          />
        </h3>
        <SelectInput
          {...reasonInput}
          data={reasonInputData}
          data-testid="reason-input"
          label={intl.formatMessage({ id: 'breakdownInfo.reasonLabel', defaultMessage: 'Reason for tow' })}
        />
        <SelectInput
          {...requestedWorks}
          multiple
          data={requestedWorkData}
          data-testid="requestedWorks-input"
          label={intl.formatMessage({ id: 'breakdownInfo.requestedWorksLabel', defaultMessage: 'Requested work' })}
        />
        <FormTextarea
          data-testid="comment"
          name="breakdownServiceComment"
          className="w-full px-5 kiosk:px-10"
          label={intl.formatMessage({ id: 'repairOrder.comment', defaultMessage: 'Comment' })}
        />
      </div>
      <PageFooter
        onPrev={onPrev}
        disabled={hasError}
        onNext={handleSubmit}
        nextText={displaySkipText ? { id: 'breakdownInfo.skip', defaultMessage: 'Skip' } : undefined}
      />
    </div>
  );
};

export default Content;
