import React from 'react';
import type { Blocker, History, Transition } from 'history';
import { Navigator as BaseNavigator, UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

export type ShouldPromptType = (location: Transition['location'], action: Transition['action']) => boolean;

interface Navigator extends BaseNavigator {
  block: History['block'];
}

type NavigationContextWithBlock = React.ContextType<typeof NavigationContext> & { navigator: Navigator };

/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
function useBlocker(blocker: Blocker, when = true) {
  const { navigator } = React.useContext(NavigationContext) as NavigationContextWithBlock;

  React.useEffect(() => {
    if (!when) {
      return;
    }

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    // eslint-disable-next-line consistent-return
    return unblock;
  }, [navigator, blocker, when]);
}

/**
 * @source https://github.com/remix-run/react-router/issues/8139#issuecomment-1021457943
 */
function usePrompt(message: string, shouldPrompt: ShouldPromptType, shouldBlock: boolean, callback: () => void) {
  const blocker = React.useCallback((tx: Transition) => {
    if (shouldPrompt(tx.location, tx.action)) {
      // eslint-disable-next-line no-alert
      const response = window.confirm(message);
      if (response) {
        callback();
      }
    } else {
      tx.retry();
    }
  }, [callback, message, shouldPrompt]);
  return useBlocker(blocker, shouldBlock);
}

export default usePrompt;
