import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFieldArray } from 'react-final-form-arrays';
import { FormRenderProps, useForm, useFormState } from 'react-final-form';

import { ButtonType } from 'components/ui/Button';
import { FormVehicleCheck } from 'modules/vehicleCheck/types/FormVehicleCheck';
import { VehicleCheckModelType } from 'modules/vehicleCheck/types/VehicleCheckModel';

import { Button } from 'components/ui';
import Popup from 'components/ui/Popup';
import Uploading from 'assets/icons/uploading.svg';

import VehicleCheckCard from './VehicleCheckCard';
import MandatoryWarning from '../MandatoryWarning';

import { getAdditionalItem, hasValidationError } from './utils';

type ContentProps = FormRenderProps<FormVehicleCheck> & {
  setDisplayDiscardModal: (value: boolean) => void
};

const Content: React.FC<ContentProps> = ({ setDisplayDiscardModal }) => {
  const { submit } = useForm();
  const { errors } = useFormState({ subscription: { errors: true } });
  const [displayValidationModal, setDisplayValidationModal] = React.useState(false);

  const hasRequiredItems = hasValidationError(errors, 'validation.required');
  const hasUploadingItems = hasValidationError(errors, 'validation.loading');
  const hasErrors = hasRequiredItems || hasUploadingItems;

  const { fields: optionalItems } = useFieldArray(`items.${VehicleCheckModelType.OPTIONAL}`, {
    subscription: { length: true },
  });
  const { fields: mandatoryItems } = useFieldArray(`items.${VehicleCheckModelType.MANDATORY}`, {
    subscription: { length: true },
  });
  const { fields: additionalItems } = useFieldArray(`items.${VehicleCheckModelType.ADDITIONAL}`, {
    subscription: { length: true },
  });

  const nbMandatory = mandatoryItems.length;
  const nbOptional = optionalItems.length + additionalItems.length - 1; // remove the fake one

  const handleSubmit = () => {
    if (hasErrors) {
      setDisplayValidationModal(true);
    } else {
      submit();
    }
  };

  const handleDiscard = () => setDisplayDiscardModal(true);
  const handleAddEmptyItem = () => additionalItems.push(getAdditionalItem());
  const handleDeleteItem = (index: number) => () => additionalItems.remove(index);

  return (
    <>
      {nbMandatory > 0 && (
        <>
          <div className="flex items-center py-5 bg-white sticky top-0 px-8 z-40">
            <h2
              // eslint-disable-next-line max-len
              className="relative uppercase text-lg pb-3 after:content-[''] after:absolute after:left-0 after:bottom-0 after:bg-default after:w-12 after:h-1 after:rounded"
            >
              <FormattedMessage id="mandatory" defaultMessage="Mandatory" />
            </h2>
            <span className="ml-2 pb-3 text-s">
              (
              <FormattedMessage
                id="page.vehicleCheck.nbMedia"
                defaultMessage="{nbMedia, plural, one {# Media} other {# Media}}"
                values={{ nbMedia: nbMandatory }}
              />
              )
            </span>
          </div>
          <div className="px-8 grid landscape:md:grid-cols-3 grid-cols-1 gap-x-5 gap-y-12 z-0">
            {mandatoryItems.map((fieldName) => (
              <VehicleCheckCard key={fieldName} fieldName={fieldName} />
            ))}
          </div>
        </>
      )}
      {nbOptional > 0 && (
        <>
          <div className="flex items-center py-5 bg-white sticky top-0 px-8 mt-12 z-40">
            <h2
              // eslint-disable-next-line max-len
              className="relative uppercase text-lg pb-3 after:content-[''] after:absolute after:left-0 after:bottom-0 after:bg-default after:w-12 after:h-1 after:rounded"
            >
              <FormattedMessage id="optional" defaultMessage="Optional" />
            </h2>
            <span className="ml-2 pb-3 text-s">
              (
              <FormattedMessage
                id="page.vehicleCheck.nbMedia"
                defaultMessage="{nbMedia, plural, one {# Media} other {# Media}}"
                values={{ nbMedia: nbOptional }}
              />
              )
            </span>
          </div>
          <div className="px-8 grid landscape:md:grid-cols-3 grid-cols-1 gap-x-5 gap-y-12 z-0">
            {optionalItems.map((fieldName) => (
              <VehicleCheckCard key={fieldName} fieldName={fieldName} />
            ))}
            {additionalItems.map((fieldName, index) => (
              <VehicleCheckCard
                key={fieldName}
                fieldName={fieldName}
                onUpload={handleAddEmptyItem}
                onDelete={handleDeleteItem(index)}
              />
            ))}
          </div>
        </>
      )}
      <div className="px-8 flex items-center justify-center flex-col py-12">
        <Button
          className="w-full"
          onClick={handleSubmit}
          disabledStyle={hasErrors}
          type={ButtonType.TERTIARY}
          testId="submit-vehicleCheck"
        >
          <FormattedMessage id="page.vehicleCheck.submit" defaultMessage="Send my vehicle check" />
        </Button>
        <div
          onClick={handleDiscard}
          className="mt-6 underline cursor-pointer text-center"
          data-testid="cancel-vehicleCheck"
        >
          <FormattedMessage
            id="page.vehicleCheck.discard"
            // eslint-disable-next-line max-len
            defaultMessage="I no longer wish to carry out {nbVehicleCheck, plural, one {a vehicle check} other {vehicle checks}}"
            values={{ nbVehicleCheck: 1 }}
          />
        </div>
      </div>
      {displayValidationModal && (
        <Popup
          onClose={setDisplayValidationModal}
          title={hasRequiredItems && (
            <FormattedMessage
              id="page.vehicleCheck.validation.required.title"
              defaultMessage="You must first add all mandatory media"
              tagName="h2"
            />
          )}
        >
          {hasRequiredItems && <MandatoryWarning />}
          {!hasRequiredItems && (
            <div className="flex flex-col justify-center items-center">
              <div className="size-12 rounded-full bg-warning-default flex justify-center items-center mb-2">
                <Uploading className="size-8 vertical-pulse" />
              </div>
              <h2 className="font-bold mb-2">
                <FormattedMessage
                  id="page.vehicleCheck.validation.loading.title"
                  defaultMessage="Please wait a moment"
                />
              </h2>
              <FormattedMessage
                id="page.vehicleCheck.validation.loading.description"
                defaultMessage="Some media are still being uploaded."
                tagName="p"
              />
            </div>
          )}
        </Popup>
      )}
    </>
  );
};

export default Content;
