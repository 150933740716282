import { createAction, PrepareAction } from '@reduxjs/toolkit';

export const prepareFinalize:PrepareAction<{ time?: number, nextStep: string }> = (nextStep:string, time:number) => ({
  payload: {
    time,
    nextStep,
  },
});

export const finalizeSelfService = createAction('SELF_SERVICE/FINALIZE', prepareFinalize);
