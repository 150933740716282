import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormRenderProps } from 'react-final-form';

import { QuestionsForm as QuestionsFormType } from 'types/QuestionsForm';

import PageFooter from 'components/ui/PageFooter';
import PageBaseProps from 'types/PageBase';
import Questions from './Questions';

const QuestionsFormRender: React.FC<FormRenderProps<QuestionsFormType> & Pick<PageBaseProps, 'onPrev'>> = ({
  onPrev,
  handleSubmit,
  hasValidationErrors,
}) => (
  <div className="main-content max-w-3xl">
    <div className="flex flex-col items-center">
      <FormattedMessage id="questions.title" defaultMessage="Questions" tagName="h1" />
    </div>
    <div className="content">
      <Questions />
    </div>
    <PageFooter
      onNext={handleSubmit}
      onPrev={onPrev}
      disabledStyle={hasValidationErrors}
    />
  </div>
);

export default QuestionsFormRender;
