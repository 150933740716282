import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'hooks';
import { Button, Spinner } from 'components/ui';
import { ButtonType } from 'components/ui/Button';
import { KeyStatus } from 'modules/kiosk/types/Kiosk';
import { SelfServiceType } from 'modules/selfServices/types/SelfService';
import { ReadySelfService } from 'modules/kiosk/types/GetReadySelfServices';
import { getSlotById, getSlotIdBySelfServiceId, isKioskDataLoading } from 'modules/kiosk/selectors';

import SlotCamera from '../SlotCamera';

const AdminKeysManagementModalContent: React.FC<ReadySelfService & { onClose: (accepted: boolean) => void }> = ({
  id: selfServiceId,
  type,
  slotId: currentSlotId,
  registration,
  customerDisplayName,
  keyStatus,
  onClose,
}) => {
  const isLoading = useSelector(isKioskDataLoading);
  const slotId = useSelector((state) => getSlotIdBySelfServiceId(state, selfServiceId));
  const { rack } = useSelector((state) => getSlotById(state, slotId));

  const isCheckOut = type === SelfServiceType.CHECK_OUT;
  const isMobilityCheckIn = type === SelfServiceType.CHECK_IN && keyStatus === KeyStatus.NONE;
  const isDropping = (isCheckOut && !currentSlotId) || isMobilityCheckIn;

  const handleYesNo = React.useCallback((hasAccepted: boolean) => () => onClose(hasAccepted), [onClose]);

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <h1>{registration}</h1>
          <div className="text-2xl">
            {`${registration} ${customerDisplayName ? `- ${customerDisplayName}` : ''}`}
          </div>
          <h1 className="mt-10 text-center !leading-normal" data-testid="key-question">
            <FormattedMessage
              id={`admin.manage.key.question.${isDropping ? SelfServiceType.CHECK_OUT : SelfServiceType.CHECK_IN}`}
              defaultMessage={
                isDropping ? 'Have you dropped the keys?' : 'Have you collected the keys?'
              }
            />
          </h1>
          <SlotCamera rack={rack} className="my-10" />
          {isDropping && (
            <p className="text-center text-gray-70 font-bold mb-10">
              <FormattedMessage id="key.slotHelper" defaultMessage="Please ensure that the key are well visible below and without any keychains." />
            </p>
          )}
          <div className="w-176 bg-white rounded-5xl flex flex-col items-center">
            <div className="w-full flex justify-center items-center px-8 my-10 gap-5">
              <Button className="min-w-18" onClick={handleYesNo(true)} testId="key-yes-btn">
                <FormattedMessage id="confirm.yes" defaultMessage="Yes" />
              </Button>
              <Button className="min-w-18" type={ButtonType.SECONDARY} onClick={handleYesNo(false)} testId="key-no-btn">
                <FormattedMessage id="confirm.no" defaultMessage="No" />
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AdminKeysManagementModalContent;
