/* eslint-disable import/prefer-default-export */

export const arrayDeepEqual = (a:unknown, b:unknown):boolean => Array.isArray(a)
    && Array.isArray(b)
    && a.length === b.length
    && a.every((val, index) => {
      if (typeof val === 'object') {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return Array.isArray(val) ? arrayDeepEqual(a[index], b[index]) : shallowEqual(a[index], b[index]);
      }
      return val === b[index];
    });

export const shallowEqual = <T extends Record<string, unknown>>(object1:T, object2:T) => {
  const keys1 = Object.keys(object1 ?? {});
  const keys2 = Object.keys(object2 ?? {});

  if (keys1.length !== keys2.length) {
    return false;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const field of keys1) {
    let areDifferent = false;
    if (typeof object1[field] === 'object') {
      const fn = Array.isArray(object1[field]) ? arrayDeepEqual : shallowEqual;
      areDifferent = fn(object2[field] as any, object1[field] as any);
    } else {
      areDifferent = object1[field] !== object2[field];
      if (areDifferent) {
        return false;
      }
    }
  }
  return true;
};
