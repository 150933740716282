import React from 'react';
import { Switch as SwitchBase } from '@headlessui/react';
import classNames, { Argument } from 'classnames';

interface SwitchProps {
  checked: boolean;
  disabled?: boolean;
  labelAfter?: boolean;
  onChange: (checked: boolean) => void;
  className?: Argument;
  labelClassName?: Argument;
  'data-testid'?: string;
  size?: 'default' | 'small'
}

const Switch: React.FC<React.PropsWithChildren<SwitchProps>> = ({
  checked, onChange, disabled, labelAfter, children, className, 'data-testid': dataTestId, labelClassName, size,
}) => (
  <SwitchBase.Group>
    <div className={classNames('flex items-center', {
      'flex-row-reverse': labelAfter,
    }, className)}
    >
      <SwitchBase
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className={classNames({ disabled }, [size, 'switch outline-0 focus:shadow-focus'])}
      >
        <span
          data-testid={dataTestId}
          className={classNames({ checked }, checked ? 'kiosk:!translate-x-[3.25rem]' : 'kiosk:translate-x-1')}
        />

      </SwitchBase>
      <SwitchBase.Label className={classNames(labelClassName, 'flex items-center text-left ml-2')}>
        <span className="align-middle">{children}</span>
      </SwitchBase.Label>
    </div>
  </SwitchBase.Group>
);

export default Switch;
