import React from 'react';
import { Tab } from '@headlessui/react';
import { FormattedMessage } from 'react-intl';

import { b } from 'utils/i18nUtils';
import PageFooter from 'components/ui/PageFooter';
import { isPublicDevice } from 'modules/dealers/selectors';
import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { useGetSelfServiceByIdQuery, useUpdateSelfServiceMutation } from 'modules/selfServices/service';

import { useSelector } from '../../hooks';
import PageBaseProps from '../../types/PageBase';
import { Attachments, Carousel, FileList } from '../ui';
import { AbstractMedia, MediaType } from '../../types/AbstractMedia';

const AppointmentSummary: React.FC<PageBaseProps> = ({ onNext, onPrev, isPenultimate }) => {
  const isPublic = useSelector(isPublicDevice);
  const selfServiceId = useSelector(getSelectedSelfServiceId);

  const { data } = useGetSelfServiceByIdQuery({ id: selfServiceId });
  const [, { isLoading }] = useUpdateSelfServiceMutation({ fixedCacheKey: 'UPDATE/SELF_SERVICE' });

  const [isCarouselFullscreen, setCarouselFullscreen] = React.useState(false);

  const { medias, documents } = React.useMemo(
    () => (data.interventionMedias ?? []).reduce((acc, media) => {
      const mediaType = media.type === MediaType.DOCUMENT ? 'documents' : 'medias';
      acc[mediaType].push(media);
      return acc;
    }, { medias: [], documents: [] } as { medias: AbstractMedia[], documents: AbstractMedia[] }),
    [data.interventionMedias],
  );

  const hasDocuments = documents.length > 0;
  const hasMedias = medias.length > 0;

  const hasSelfServiceMedias = data.medias?.length > 0;
  const hasMediasToDisplay = hasMedias || hasDocuments;

  // Appointments medias
  const handleCarouselOpen = React.useCallback(() => setCarouselFullscreen(true), []);
  const handleCarouselClose = React.useCallback(() => setCarouselFullscreen(false), []);

  return (
    <div className="main-content">
      <div className="flex flex-col text-center">
        <FormattedMessage
          id="page.appointmentSummary.title"
          defaultMessage="Your appointment"
          tagName="h1"
        />
        <div className="description text-gray-70 mt-5">
          <FormattedMessage
            id="page.appointmentSummary.subtitle"
            defaultMessage="We are <b>finished</b> with your vehicle and it is <b>ready to be picked up.</b>"
            values={{ b }}
            tagName="p"
          />
        </div>
      </div>
      <div className="content">
        {data.comment && (
          <>
            <div className="w-full h-px bg-divider-bg" />
            <div className="flex flex-col text-center">
              <FormattedMessage
                id="page.appointmentSummary.dealerMessage.title"
                defaultMessage="A message for you"
                tagName="h3"
              />
              <p className="font-medium italic whitespace-pre-wrap before:content-[open-quote] after:content-[close-quote] break-words mt-5">
                {data.comment}
              </p>
            </div>
          </>
        )}

        {hasSelfServiceMedias && (
          <div
            className="flex flex-col rounded-xl overflow-hidden w-full mt-2 kiosk:mt-10"
            data-testid="medias-carousel"
          >
            <Carousel
              medias={data.medias}
              onClose={handleCarouselClose}
              fullscreen={isCarouselFullscreen}
              onMediaClick={handleCarouselOpen}
            />
          </div>
        )}

        {hasMediasToDisplay && (
          <>
            <div className="w-full h-px bg-divider-bg my-5" />
            <span className="text-center">
              <FormattedMessage
                id="page.appointmentSummary.mediasAndDocuments.title"
                defaultMessage="Media and documents related to your appointment"
                tagName="h3"
              />
            </span>
            <Tab.Group>
              {hasDocuments && hasMedias && (
                <Tab.List
                  className="flex w-72 xs:w-64 h-12 kiosk:h-24 rounded-full bg-secondary p-1 mx-auto"
                  data-testid="medias-tab"
                >
                  {hasMedias && (
                    <Tab className="w-full text-center ui-selected:rounded-full ui-selected:bg-white kiosk:text-lg">
                      <FormattedMessage
                        id="page.appointmentSummary.mediasAndDocuments.medias"
                        defaultMessage="Medias"
                      />
                    </Tab>
                  )}
                  {hasDocuments && (
                    <Tab
                      className="w-full text-center ui-selected:rounded-full ui-selected:bg-white kiosk:text-lg"
                      data-testid="documents-tab"
                    >
                      <FormattedMessage
                        id="page.appointmentSummary.mediasAndDocuments.documents"
                        defaultMessage="Documents"
                      />
                    </Tab>
                  )}
                </Tab.List>
              )}
              {hasMedias && (
                <Tab.Panel className="w-full m-auto">
                  <FileList medias={medias} showDownload={!isPublic} />
                </Tab.Panel>
              )}
              {hasDocuments && (
                <Tab.Panel className="w-full">
                  <Attachments documents={documents} documentClassName="border-input-bg first:border-0 first:pt-0" />
                </Tab.Panel>
              )}
            </Tab.Group>
          </>
        )}
      </div>
      <PageFooter
        onNext={onNext}
        onPrev={onPrev}
        loading={isLoading}
        isPenultimate={isPenultimate}
      />
    </div>
  );
};

export default AppointmentSummary;
