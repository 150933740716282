import React from 'react';

import ConnectedForm from 'components/ui/ConnectedForm';
import getDecorator from 'components/ui/ConnectedForm/decorator';

import FormKeys from 'modules/form/types/FormKeys';

import PageBaseProps from 'types/PageBase';
import { VehicleCheckForm as VehicleCheckFormType } from 'types/VehicleCheckForm';

import VehicleCheckFormRender from './VehicleCheckFormRender';

const decorator = getDecorator<VehicleCheckFormType>(FormKeys.VEHICLE_CHECK);

const VehicleCheckForm: React.FC<PageBaseProps> = ({ onNext, onPrev }) => (
  <ConnectedForm
    onSubmit={onNext}
    onPrev={onPrev}
    component={VehicleCheckFormRender}
    subscription={{}}
    decorators={[decorator]}
    formKey={FormKeys.VEHICLE_CHECK}
  />
);

export default VehicleCheckForm;
