import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { useSelector } from 'hooks';
import { computeTax } from 'utils/priceUtils';
import TrashIcon from 'assets/icons/trash.svg';
import useLabelTranslation from 'hooks/useLabelTranslation';
import { getPayerById } from 'modules/selfServices/selectors';
import type { RepairOrder } from 'modules/selfServices/types/SelfService';

import type { Extra } from 'types/ExtrasForm';

import Button, { ButtonType } from '../../ui/Button';

type BasketItemsProps = (RepairOrder | Extra) & Pick<RepairOrder, 'payerId'> & {
  vat: number,
  adviceId?: string,
  currency?: string,
  onRemove?: () => void,
};

const Item: React.FC<BasketItemsProps> = ({
  vat,
  price,
  payerId,
  adviceId,
  onRemove,
  currency,
  adviceName,
  coefficient,
  discountedPrice,
}) => {
  const { getLabelTranslation } = useLabelTranslation();

  const payer = useSelector((state) => getPayerById(state, payerId));

  const showDiscount = price !== discountedPrice;
  const canRemove = typeof onRemove === 'function';

  const showPrice = discountedPrice >= 0;
  const name = getLabelTranslation(adviceName);
  const payerName = payer ? getLabelTranslation(payer.name) : undefined;
  const priceWithVAT = React.useMemo(() => computeTax(price, vat, coefficient), [coefficient, price, vat]);
  const discountedPriceWithVAT = React.useMemo(() => computeTax(discountedPrice, vat, coefficient), [coefficient, discountedPrice, vat]);

  return (
    <>
      <div className="flex justify-between items-center px-2xs">
        <span className="text-xs font-bold">{name}</span>
        <div className="flex items-center">
          {showPrice && (
            <div className="flex flex-col items-end justify-center">
              <span className="font-bold text-xs">
                {/* eslint-disable-next-line react/style-prop-object */}
                <FormattedNumber value={discountedPriceWithVAT} style="currency" currency={currency} />
              </span>
              {showDiscount && (
                <span className="line-through italic text-xs">
                  {/* eslint-disable-next-line react/style-prop-object */}
                  <FormattedNumber value={priceWithVAT} style="currency" currency={currency} />
                </span>
              )}
            </div>
          )}
          {canRemove && (
            <Button
              onClick={onRemove}
              type={ButtonType.SECONDARY}
              testId={`basket-remove-${adviceId}`}
              className="ml-xs size-10 kiosk:size-20 !p-2 rounded-xl"
            >
              <TrashIcon className="m-auto size-6 kiosk:size-8" />
            </Button>
          )}
        </div>
      </div>
      {payer && (
        <div className="text-right text-lowest text-xs italic truncate font-semibold px-2xs">
          <FormattedMessage
            id="billing.payerLabel"
            defaultMessage="Billing: {payerName}"
            values={{ payerName }}
          />
        </div>
      )}
    </>
  );
};

export default Item;
