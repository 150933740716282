import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { b } from 'utils/i18nUtils';
import { useSelector } from 'hooks';

import PageBaseProps from 'types/PageBase';

import { getMobilityVehicle, getParkingMaps } from 'modules/selfServices/selectors';

import Value from 'components/Parking/Value';
import { FileList, PageFooter } from 'components/ui';
import ParkingContent from 'components/Parking/ParkingContent';

const CourtesyVehicle: React.FC<PageBaseProps> = ({ onNext, onPrev }) => {
  const intl = useIntl();

  const maps = useSelector(getParkingMaps);
  const vehicle = useSelector(getMobilityVehicle);

  const { medias, parking } = vehicle;

  const information = React.useMemo(
    () => [
      {
        translation: {
          id: 'courtesyVehicle.info.description',
          defaultMessage: 'Brand and model',
        },
        value: vehicle.description,
      },
      {
        translation: {
          id: 'login.licensePlate',
          defaultMessage: 'License plate',
        },
        value: vehicle.registration,
      },
      {
        translation: {
          id: 'courtesyVehicle.info.color',
          defaultMessage: 'Color',
        },
        value: vehicle.color,
      },
      {
        translation: {
          id: 'courtesyVehicle.info.fuelLevel',
          defaultMessage: 'Fuel level',
        },
        value: vehicle.fuelLevel && intl.formatMessage({
          id: `fuelLevel.${vehicle.fuelLevel}`,
          defaultMessage: vehicle.fuelLevel.toString(),
        }),
      },
      {
        translation: {
          id: 'courtesyVehicle.info.mileage',
          defaultMessage: 'Plate number',
        },
        value: vehicle.mileage?.toString(),
      },
    ].filter(({ value }) => Boolean(value)),
    [intl, vehicle],
  );

  return (
    <div className="main-content">
      <div className="content">
        <div className="flex flex-col items-center kiosk:mb-10">
          <FormattedMessage
            id="courtesyVehicle.title"
            defaultMessage="Courtesy vehicle"
            tagName="h1"
          />
          <p className="text-center mt-5 text-gray-70">
            <FormattedMessage
              id="courtesyVehicle.description"
              defaultMessage="Information about <b>your courtesy vehicle</b>"
              values={{ b }}
            />
          </p>
        </div>
        {information.length > 0 && (
          <div className="flex flex-wrap justify-center w-full bg-secondary rounded-lg p-2 kiosk:rounded-2xl kiosk:p-4 kiosk:mb-10">
            {information.map(({ translation, value }) => (
              <Value key={translation.id} value={value} label={intl.formatMessage(translation)} inputClassName="overflow-ellipsis" />
            ))}
          </div>
        )}
        {medias?.length > 0 && <FileList medias={medias} className="w-full justify-center kiosk:mb-10" />}
        {parking && (
          <>
            <div className="h-px w-10/12 bg-divider-bg kiosk:mb-10 mx-auto" />
            <ParkingContent
              maps={maps}
              hideInstructions
              title={{
                id: 'courtesyVehicle.parkingTitle',
                defaultMessage: 'Where to find it',
              }}
              {...parking}
            />
          </>
        )}
        <p className="text-center text-gray-70 px-10 kiosk:mt-10">
          <FormattedMessage
            id="courtesyVehicle.email"
            defaultMessage="This information <b>will be sent to you</b> by email at the end of your check-in."
            values={{ b }}
          />
        </p>
      </div>
      <PageFooter onNext={onNext} onPrev={onPrev} />
    </div>
  );
};

export default CourtesyVehicle;
