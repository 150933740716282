import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InitialState {
  displayModal: boolean;
  displayLogoutModal: boolean;
  displayInstructionModal: boolean;
  stepToRedirect: string;
  lastUpdatedTime: number;
}

const initialState = {
  displayModal: false,
  displayLogoutModal: false,
  displayInstructionModal: false,
  stepToRedirect: null,
  lastUpdatedTime: null,
} as InitialState;

const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    displayModal: (state, action: PayloadAction<string>) => ({
      ...state,
      displayModal: true,
      displayLogoutModal: false,
      stepToRedirect: action.payload,
      lastUpdatedTime: Date.now(),
    }),
    closeModal: (state, action: PayloadAction<string>) => ({
      ...state,
      displayModal: false,
      stepToRedirect: action.payload,
    }),
    openLogoutModal: () => ({
      ...initialState,
      displayLogoutModal: true,
      lastUpdatedTime: Date.now(),
    }),
    closeLogoutModal: (state) => ({
      ...state,
      displayLogoutModal: false,
    }),
    openInstructionModal: (state) => ({
      ...state,
      displayInstructionModal: true,
    }),
    closeInstructionModal: (state) => ({
      ...state,
      displayInstructionModal: false,
    }),
    closeSocket: () => initialState,
  },
});

export default socketSlice;
