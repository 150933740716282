import React from 'react';
import { useDispatch } from 'react-redux';
import classNames, { Argument } from 'classnames';

import { useSelector } from 'hooks';
import LanguageEnum from 'types/LanguageEnum';
import actions from 'modules/translations/actions';
import {
  getAllLanguages, getSelectedLanguage, getLanguagesSelectors, getTranslationsSelectors,
} from 'modules/translations/selectors';

import Spinner from '../Spinner';
import Dropdown from '../Dropdown';
import MenuItem from '../Menu/Item';
import Flag from './components/Flags';
import Menu, { MenuItemClickParams } from '../Menu';

interface LanguageSelectProps {
  className?: Argument;
}

const LanguageSelect: React.FC<LanguageSelectProps> = ({ className }) => {
  const dispatch = useDispatch();
  const locale = useSelector(getSelectedLanguage);
  const { isLoading: isLoadingLanguage } = useSelector(getLanguagesSelectors);
  const { isLoading: isLoadingTranslations } = useSelector(getTranslationsSelectors);
  const languages = useSelector(getAllLanguages);

  const isLoading = isLoadingTranslations || isLoadingLanguage;

  const handleOnClick = React.useCallback(
    (params: MenuItemClickParams) => {
      const newLocale = params.key.toString() as LanguageEnum;
      if (newLocale !== locale) {
        dispatch(actions.setSelectedLanguage(newLocale));
      }
    },
    [locale, dispatch],
  );

  return (
    <div
      className="py-3 kiosk:py-10 z-50"
      data-testid="language-select"
    >
      {isLoading && (
        <span
          className={classNames(
            'px-2 py-2 rounded-full kiosk:p-6 bg-input-bg flex justify-center ring-2 ring-border-bg w-20 kiosk:w-auto',
            className,
          )}
        >
          <Spinner />
        </span>
      )}
      {!isLoading && (
        <Dropdown
          overlay={(
            <Menu onClick={handleOnClick}>
              {languages.map((language) => (
                <MenuItem
                  key={language}
                  className={classNames('uppercase text-center', {
                    'font-bold bg-gray-50': locale === language,
                  })}
                >
                  {language}
                </MenuItem>
              ))}
            </Menu>
          )}
        >
          <span
            className={classNames(
              'py-2 px-2 rounded-full kiosk:p-6 bg-input-bg flex items-center justify-center text-gray-90 hover:ring-2 hover:ring-border-bg active:bg-opacity-40 w-20 kiosk:w-auto',
              className,
            )}
          >
            <span
              className="mr-1.5 uppercase font-bold text-sm kiosk:text-xl"
              data-testid="selected-language"
            >
              {locale}
            </span>
            <Flag locale={locale} />
          </span>
        </Dropdown>
      )}
    </div>
  );
};

export default LanguageSelect;
