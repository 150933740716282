import React from 'react';
import { useSelector } from 'hooks';
import { useCheckQRCodeTokenMutation } from 'modules/auth/service';
import { getKioskId } from 'modules/kiosk/selectors';
import { CheckQRCodeTokenRequest } from 'modules/auth/types/LoginRequest';

const VENDOR_ID = 7851;
const PRODUCT_ID = 16;

const handleInputReport = (
  data: DataView,
  buffer: Uint8Array,
  login: (params: CheckQRCodeTokenRequest) => void,
  kioskId: string,
) => {
  const qrCode = new Uint8Array(data.buffer);
  // The format of the qrCode is the following:
  // Byte 0 : report ID
  // Byte 1 : Barcode Length
  // Byte 2 - 57 : Decoded data
  // Byte 58 - 61 : Reserved
  // Byte 62 : Newland Symbology Identifier
  // Byte 63 : Check if all data is received
  // Get the decoded data
  const isLastPart = qrCode[62] === 0;
  const decodedData = qrCode.slice(1, 57);

  const mergedBuffer = new Uint8Array(buffer.length + decodedData.length);
  mergedBuffer.set(buffer);
  mergedBuffer.set(decodedData, buffer.length);

  if (isLastPart) {
    const decodedDataString = new TextDecoder().decode(mergedBuffer);

    // Remove blank characters
    const qrCodeToken = decodedDataString.split('\r')[0];
    if (qrCodeToken?.length > 50) {
      login({ qrCodeToken, kioskId });
    }

    return new Uint8Array();
  }
  return mergedBuffer;
};

const handleDevice = async (
  qrCodeScanner: HIDDevice,
  setQrCodeScanner: React.Dispatch<React.SetStateAction<HIDDevice>>,
) => {
  if (!qrCodeScanner) {
    const device = (await navigator.hid.getDevices())
      .find((hidDevice) => hidDevice.productId === PRODUCT_ID && hidDevice.vendorId === VENDOR_ID);
    if (device) { setQrCodeScanner(device); }
  }
};

/**
 * This custom hook works only on Kiosk with Edge browser, Edge policy "WebHidAllowDevicesForUrls" enabled and in HTTPS.
 *
 * @see https://intranet2.arhs-developments.com/display/BMWMOB/2.2.3.31.6.+QR+code+scanner
 */
const useQrCodeScanner = () => {
  const kioskId = useSelector(getKioskId);
  const [login] = useCheckQRCodeTokenMutation();
  const [qrCodeScanner, setQrCodeScanner] = React.useState<HIDDevice>();

  React.useEffect(() => {
    if (kioskId) {
      handleDevice(qrCodeScanner, setQrCodeScanner)
        .catch((error) => console.error(error));

      if (qrCodeScanner) {
        if (!qrCodeScanner.opened) {
          qrCodeScanner.open().catch((error) => console.error(error));
        }
        let buffer = new Uint8Array();

        qrCodeScanner.oninputreport = ({ data }) => {
          buffer = handleInputReport(data, buffer, login, kioskId);
        };
      }
    }
  }, [kioskId, login, qrCodeScanner]);
};
export default useQrCodeScanner;
