import { SelfServiceType } from 'modules/selfServices/types/SelfService';
import { ConnectionTypeLogin } from 'modules/dealers/types/ContextResponse';
import { LoginData } from 'modules/auth/types/InitialState';

import { Policy } from './Policies';

export enum ChannelEnum {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export interface LoginRequest {
  registration: string;
  policies?: Array<Policy>;
}

export interface GetMFAConnectionRequest {
  registration: string;
  type?: SelfServiceType;
  brandId?: string;
  dealerId?: string;
  phoneNumber?: string;
}

export type LoginParams = LoginData & {
  type?: SelfServiceType;
  connectionType: ConnectionTypeLogin;
};

export interface SendMFACodeRequest {
  connectionId: string;
  selfServiceId: string;
  channel?: ChannelEnum;
}

export interface LoginMFARequest {
  connectionId: string;
  selfServiceId: string;
  code: string;
  policies?: Array<Policy>;
}

export interface SendAdminCodeRequest {
  kioskId: string;
  pinCode: string;
  qrCodeToken: string;

}

export interface CheckQRCodeTokenRequest {
  qrCodeToken: string;
  kioskId: string;
}
