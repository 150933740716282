export type Extension = `.${string}`;
export type MediaMimeType = `${string}/${string}`;

export interface MediaSupport {
  extensions?: Array<Extension>,
  types?: Array<MediaMimeType>
}

export const SUPPORTED_MEDIAS:Record<string, MediaSupport> = {
  REPAIR_ORDER: {
    extensions: ['.jpeg', '.jpg', '.png', '.mp4', '.mov'],
    types: [
      'image/bmp',
      'image/jpeg',
      'image/png',
      'video/mp4',
      'video/quicktime',
    ],
  },
  MOBILITY_DOCUMENT: {
    extensions: ['.jpeg', '.jpg', '.png'],
    types: [
      'image/bmp',
      'image/jpeg',
      'image/png',
    ],
  },
};
