import {
  createSlice, PayloadAction,
} from '@reduxjs/toolkit';

import { getDefaultLanguage } from 'utils/i18nUtils';
import translationsApi from 'modules/translations/service';
import type { InitialState } from 'modules/translations/types/InitialState';

import Language from '../../types/LanguageEnum';

const translationsSlice = createSlice({
  name: 'translations',
  initialState: {
    selectedLanguage: getDefaultLanguage(),
    fallbackLanguage: getDefaultLanguage(),
    messages: {},
  } as InitialState,
  reducers: {
    setSelectedLanguage: (
      state,
      { payload: selectedLanguage }: PayloadAction<Language>,
    ) => ({
      ...state,
      selectedLanguage,
      fallbackLanguage: state.selectedLanguage,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      translationsApi.endpoints.getTranslationsByLanguage.matchFulfilled,
      (state, { payload }) => ({
        ...state,
        messages: payload,
      }),
    );
    builder.addMatcher(
      translationsApi.endpoints.getTranslationsByLanguage.matchRejected,
      (state) => ({
        ...state,
        selectedLanguage: state.fallbackLanguage,
      }),
    );
  },
});

export default translationsSlice;
