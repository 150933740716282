import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'hooks';
import { getExtras } from 'utils/formUtils';
import type { Extra } from 'types/ExtrasForm';
import type { RepairOrder } from 'modules/selfServices/types/SelfService';
import { getCurrencyIsoCode, getSelectedSelfService, getValueAddedTax } from 'modules/selfServices/selectors';

import Item from './Item';
import ExtraStatus from '../../../types/ExtraStatus';

interface ListProps {
  title: React.ReactNode;
  items: Extra[] | RepairOrder[];
  onRemove?: (adviceId: string) => () => void,
}

const List: React.FC<ListProps> = ({ items, title, onRemove }) => {
  const vat = useSelector(getValueAddedTax);
  const currency = useSelector(getCurrencyIsoCode);
  const selfService = useSelector(getSelectedSelfService);

  const grouped = React.useMemo(() => items.reduce<Record<string, ListProps['items']>>(
    (acc, item) => {
      let workOrderTag = '';
      if ('workOrderTag' in item) {
        workOrderTag = item.workOrderTag ?? '';
      }

      return {
        ...acc,
        [workOrderTag]: [...(acc[workOrderTag] ?? []), item] as ListProps['items'],
      };
    },
    {},
  ), [items]);

  const isRemovable = (adviceId: string) => {
    const advices = getExtras(selfService);
    const storedExtra = advices?.find((advice) => advice.adviceId === adviceId);
    return storedExtra.status !== ExtraStatus.ACCEPTED; // Only extra answered during the flow can be removed.
  };

  return (
    <div className="mb-s">
      <h3 className="text-s leading-xl border-b border-secondary pb-2xs">
        {title}
        <span className="ml-1 text-xs font-normal text-low">
          <FormattedMessage
            id="basket.nbItems"
            defaultMessage="({nbItems, plural, one {# item} other {# items}})"
            values={{ nbItems: items.length }}
          />
        </span>
      </h3>
      {Object.entries(grouped).map(([workOrderTag, data]) => (
        <div key={workOrderTag} className="flex flex-col gap-4 mt-6 first-of-type:mt-2">
          {workOrderTag && (
            <div className="bg-input-bg font-bold kiosk:text-3xl p-2 rounded-md -mb-2">
              {workOrderTag}
            </div>
          )}
          {data.map((item, index) => {
            const itemProps = 'adviceId' in item
              ? { key: item.adviceId, onRemove: isRemovable(item.adviceId) ? onRemove?.(item.adviceId) : undefined }
              : { key: index };

            return (
              <Item
                {...item}
                {...itemProps}
                vat={vat}
                currency={currency}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default List;
