import Bowser from 'bowser';
import { HTMLAttributeAnchorTarget } from 'react';

export const getFilenameAndExtension = (filename: string) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const result = /(.*)(\..*)/g.exec(filename);
  if (result) {
    const [_, name, extension] = result;
    return { name, extension };
  }
  return undefined;
};

export const getFilename = (filename: string) => getFilenameAndExtension(filename)?.name;
export const getExtension = (filename: string) => getFilenameAndExtension(filename)?.extension;

export const isPdf = (url = '') => url.endsWith('.pdf');

export const blobFromBase64 = (base64: string, type: string): Blob => {
  const byteArray = Uint8Array.from(
    atob(base64)
      .split('')
      .map((char) => char.charCodeAt(0)),
  );

  if (type === 'application/pdf') {
    const OSName = Bowser.getParser(window.navigator.userAgent).getOSName();
    const browserName = Bowser.getParser(window.navigator.userAgent).getBrowserName();
    const platformType = Bowser.getParser(window.navigator.userAgent).getPlatformType();
    const version = Bowser.getParser(window.navigator.userAgent).getBrowserVersion()?.split('.')?.[0];

    if (browserName !== 'Firefox' && platformType === 'mobile' && OSName === 'iOS' && parseInt(version, 10) > 12) {
      // There is currently (10/2022) a bug in iOS Safari that prevents the download of PDFs
      // So we have don't provide the file type to the Blob constructor
      // Except for Firefox, which does nothing without it (so BMWVID-14033 is still present in Firefox)
      return new Blob([byteArray]);
    }
  }

  return new Blob([byteArray], { type });
};

export const downloadFromBlob = (blob: Blob, fileName: string) => {
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;

  tempLink.setAttribute('download', fileName);

  document.body.appendChild(tempLink);
  tempLink.click();

  // Fixes "webkit blob resource error 1"
  setTimeout(() => {
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }, 0);
};

export const downloadBase64 = (file: string, type: string, fileName: string) => {
  const blob = blobFromBase64(file, type);
  downloadFromBlob(blob, fileName);
};

export const downloadURL = (url: string, fileName: string, target: HTMLAttributeAnchorTarget = '_blank') => {
  const downloadLink = document.createElement('a');

  downloadLink.style.display = 'none';

  downloadLink.setAttribute('href', url);
  downloadLink.setAttribute('download', fileName ?? '');

  const OSName = Bowser.getParser(window.navigator.userAgent).getOSName();
  const browserName = Bowser.getParser(window.navigator.userAgent).getBrowserName();
  // 30/06/2023 :
  // Safari on macOS will open a blank tab when it target is set to _blank.
  // iOS browsers (except Firefox) open a new tab when the target is set to _blank.
  // - Firefox on IOS doesn't download the files no matter the target, it opens a new tab with
  //   the file content with target _blank
  // - ⚠️ If you emulate iOS device in Chrome (macOS), it will behave differently than on a real device
  if (OSName !== 'iOS' && (OSName !== 'macOS' || browserName !== 'Safari')) {
    downloadLink.setAttribute('target', target);
  }

  document.body.appendChild(downloadLink);
  downloadLink.click();

  document.body.removeChild(downloadLink);
};
