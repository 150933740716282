import React from 'react';

import Modal from './Modal';

const Popup: React.FC<React.PropsWithChildren<{ duration?: number, onClose?: (value: boolean) => void, title?: React.ReactElement }>> = ({
  duration = 4000, onClose, title, children,
}) => {
  const timeoutId = React.useRef<NodeJS.Timeout>(null);

  const handleOnClose = React.useCallback(() => onClose?.(false), [onClose]);

  React.useEffect(() => {
    timeoutId.current = setTimeout(handleOnClose, duration);
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [duration, handleOnClose]);

  return (
    <Modal
      open
      title={title}
      hideCloseButton
      onClose={handleOnClose}
      className="w-full max-w-lg kiosk:max-w-4xl"
    >
      {children}
    </Modal>
  );
};

export default Popup;
